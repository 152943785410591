<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";

import {
  customerMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,

      numberOperatorValues: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
      ],

      textOperatorValues: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_distinct'), "id" : "!="},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
      ],

      listOperatorValues: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_include'), "id" : "in"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_exclude'), "id" : "not_in"},
      ],

      booleanValues: [
        {
          id: true,
          label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_change_yes'),
        },
        {
          id: false,
          label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_change_no'),
        },
      ],
      
      selectedField: null,
      aux_node: {
        operator: '',
        changeOperator:''
      },
      operators:[
        {label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_change_any_value'), id: 'any_change'},
        {label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_change_value'), id: 'change_to'},
        //{label: "Cambió de", id: 'change_from'},
      ],
      dateOperatorsValues: [
        {
          id: "<",
          label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_day_lower'),
        },
        {
          id: ">",
          label: this.$t('journeys.journey_workflow_components_wait_property_wait_condition_day_greater'),
        },
        {
          id: "=",
          label:this.$t('journeys.journey_workflow_components_wait_property_wait_condition_day_equal'),
        },
        
      ],
    };
  },
  components: { Multiselect},
  mounted() {},
    methods: {
      ...customerMethods,

      onRemoveCondition(){
				this.$emit('removeCondition',this.index);
			},
      onCustomerPropertySelected(node, isUpdate = false){
        if(node){
          if(!isUpdate){
            this.node.field = node.customer_field.column_name;
            this.aux_node.operator = null;
            this.aux_node.changeOperator = null;
            this.node.operator = '';
            this.node.term = ''
          }
        }else{
          node.operator = null;
          this.aux_node.operator = null;
          node.term = null
        }
      },
      
      onTextOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.changeOperator = operator.id;
        }else{
          this.aux_node.changeOperator = this.textOperatorValues.filter(o=>o.id== operator).length > 0? this.textOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      },
      onNumberOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.changeOperator = operator.id;
        }else{
          this.aux_node.changeOperator = this.numberOperatorValues.filter(o=>o.id== operator).length > 0? this.numberOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      },
      onOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.operator = operator.id;
          this.aux_node.changeOperator = null;
        }else{
          this.aux_node.operator = this.operators.filter(o=>o.id == operator).length > 0? this.operators.filter(o=>o.id== operator)[0] : null
        }
      },
      onBooleanOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.operator = "change_to";
          this.node.term = operator.id
        }else{
          this.aux_node.term = this.booleanValues.filter(o=>o.id == operator).length > 0? this.booleanValues.filter(o=>o.id== operator)[0] : null
        }
      },
      onListOperatorSelected(operator, isUpdate = false){
        if(!isUpdate){
          this.node.changeOperator = operator.id;
        }else{
          this.aux_node.changeOperator = this.listOperatorValues.filter(o=>o.id== operator).length > 0? this.listOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      }
    },
  props: {
    node: Object,
    index: Number,
    properties_crm: Array,
  },
	emits:["removeCondition"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        
        if(newVal && newVal.field){
          if(newVal.customer_field){
            this.onCustomerPropertySelected(newVal,true);
            this.onOperatorSelected(newVal.operator, true);
            if(newVal.customer_field.type == 'date'){
              this.onDateOperatorSelected(newVal.changeOperator, true);
            }else if(newVal.customer_field.type == 'number'){
              this.onNumberOperatorSelected(newVal.changeOperator, true);
            }else if(newVal.customer_field.type == 'text'){
              this.onTextOperatorSelected(newVal.changeOperator, true);
            }else if(newVal.customer_field.type == 'list'){
              this.onListOperatorSelected(newVal.changeOperator, true);
            }else if(newVal.customer_field.type == 'boolean'){
              this.onBooleanOperatorSelected(newVal.term, true);
            }


          }
        }
      }
    }
  }
}

 </script>

<template>
    
	<div class="row mt-2">
				<div class="col-sm-3 me-0">
          <multiselect
              :options="properties_crm" 
              v-model="node.customer_field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              @input="onCustomerPropertySelected(node)"
              :placeholder="$t('journeys.journey_workflow_components_wait_property_wait_condition_property_placeholder')"
          />
        </div>
        <div class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="operators" 
              v-model="aux_node.operator"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              class="form control form-control-sm w-25"
              v-if="node.customer_field?.type != 'boolean'"
              placeholder=''
              @input="onOperatorSelected"
          />
          
          <div class="col-sm-8" v-if="node.operator != 'any_change'">
            <div v-if="node.customer_field && node.customer_field?.type == 'number'" class="me-4">
              <div class="d-inline-flex">
                  <div>
                    <div class="d-inline-flex align-items-baseline">
                      <multiselect
                          :options="numberOperatorValues" 
                          v-model="aux_node.changeOperator"
                          track-by="id"
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :multiple="false"
                          class=" pt-1 me-3 w-100"
                          @input="onNumberOperatorSelected"
                          placeholder=''/>
                        <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.term" ></b-form-input> <p class="small"></p>
                    </div>
                  </div>
              </div>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'list'" class="w-100 me-4 d-inline-flex">
              <multiselect
                :options="listOperatorValues" 
                v-model="aux_node.changeOperator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class=" pt-1 me-3"
                placeholder=''
                @input="onListOperatorSelected"/>

              <multiselect
                :options="node.customer_field.values" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                placeholder=''
                class="form control pt-1"/>
          </div>
          <div v-if="node.customer_field && node.customer_field.type == 'text'" class=" me-4 d-inline-flex w-100">
            
              <multiselect
                  :options="textOperatorValues" 
                  v-model="aux_node.changeOperator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class=" pt-1 me-3 w-100"
                  @input="onTextOperatorSelected"
                  placeholder=''/>
                  <b-form-input type="text" class="form-control form-control w-100 me-2 mt-1" min="1" v-model="node.term"  ></b-form-input>
                    
          </div>
          <div v-if="node.customer_field && node.customer_field.type == 'boolean'" class="w-50 me-4">
              <multiselect
              :options="booleanValues" 
              v-model="aux_node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control form-control-sm w-100"
              placeholder=''
              @input="onBooleanOperatorSelected"/>
            </div>
            
          <div v-if="node.customer_field && node.customer_field.type == 'date'" class="w-100 d-inline-flex align-items-baseline pt-1 ps-1" >
            <multiselect
              :options="dateOperatorsValues" 
              v-model="aux_node.changeOperator"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control w-100 me-3"
              @input="onDateOperatorSelected"
              placeholder=''/>
              <b-form-input type="date" class="form-control me-2" v-model="node.term" ></b-form-input>
          </div>
          </div>
        </div>
        <div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>