<script>
import { Picker } from 'emoji-mart-vue-2'
import DynamicContent from "./dynamic-vars.vue"
import Multiselect from "vue-multiselect";
import Integrations  from "@/components/projects/integrations";
import _debounce from 'lodash.debounce';
import Drawer from "vue-simple-drawer";
import AITextGenerator  from "@/components/journeys/ai-text-generator";
import TestChannel from '@/components/broadcasts/test-channel.vue';

import {
  commonMethods,
	integrationMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Picker,
    DynamicContent,
    Multiselect,
    Integrations,
    Drawer,
    AITextGenerator,
    TestChannel
  },
	data() {
		return {
			submitted:false,
      showVariableModal: false,
      message:{
        payload:{
          title:'',
          body: '',
          image:'',
        },
        data:{},
        from: null
      },
      showTextEmojis:false,
      showTitleEmojis:false,
      categories:[],
      affinity_Types: [],
      items:[],
      value:'',
      showVarContent:false,
      urlContent: '',
      textContent:'',
      titleContent: '',
      webPushConfigs: [],
      from : null,
      showIntegrationsAvailable:false,
      integrations:[],
      minimized:false,
      data:[
        {
          key:'',
          value:''
        }
      ],
      openRightDrawer:false,
      openRightTestDrawer: false,
      field:'',
      limit:50,
      testMessage: null,
      isLocal: window.config.local ? true : false,
      preview: 'desktop',
      desktopPreviews: [
        {
          id: 'windows_chrome',
          label: 'Windows - Google Chrome'
        },
        {
          id: 'windows_firefox',
          label: 'Windows - Firefox'
        },
        {
          id: 'mac_chrome',
          label: 'macOS - Google Chrome'
        },
        {
          id: 'mac_safari',
          label: 'macOS - Safari'
        },
        {
          id: 'mac_firefox',
          label: 'macOS - Firefox'
        },
      ],
      mobilePreviews: [
        {
          id: 'android_chrome',
          label: "Android - Chrome"
        },
      ]
    };
	},
  computed: {
    
  },
  created(){
    this.debouncePreview = _debounce(this.loadPreview.bind(this), 1000);
  },
  mounted() {
    
    window.addEventListener('click', this.onclickOutsideEmojisContainer)
    this.loadPushNotificationsIntegrations(true);
    
    //this.debouncePreview();
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onclickOutsideEmojisContainer)
  },
	methods: {
    ...integrationMethods,
    ...commonMethods,
    
    loadPushNotificationsIntegrations(fromNew = false){
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}&where[type]=web_push`
      }
      this.getIntegrations(params).then((integrations)=>{
        this.webPushConfigs = integrations.data;
        this.webPushConfigs.forEach((i)=>{
          i.label = `${i.name}`
        })
        if(fromNew){
          this.from = this.webPushConfigs.length > 0 ? this.webPushConfigs[0] : null;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("integrations.get_integrations_error"),title:  this.$t("integrations.title") });
      }).finally(()=>{

      })
    },

    onclickOutsideEmojisContainer(evt){
      const textEmojiDiv = document.getElementById("textEmojiDiv");
      const titleEmojiDiv = document.getElementById("titleEmojiDiv");

      let targetEl = evt.target; 
        do {
          if(targetEl == textEmojiDiv || targetEl == titleEmojiDiv) {
            return;
          }
          // Go up the DOM
          targetEl = targetEl.parentNode;
        } while (targetEl);

        if(this.showTextEmojis)
          this.showTextEmojis = false;
        
          if(this.showTitleEmojis)
            this.showTitleEmojis = false;
    },
    handleTextEmojiClick(detail){
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(detail.native);
      div.appendChild(textNode);

      this.textContent = div.textContent;
      this.debouncePreview();
      this.showTextEmojis = false;
    },
    

    getWebPushNotificationsConfig(){
      this.message.payload.title = this.titleContent;
      this.message.payload.body = this.textContent;

      let data = {};

      for (let i = 0; i < this.data.length; i++) {
        const obj = this.data[i];
        if(obj.key && obj.key!= '' && obj.value && obj.value!= '')
          data[obj.key] = this.data[i].value;
      }

      this.message.data = data;
      this.message.from = this.from?._id;
      if(this.urlContent){
        this.message.data['url'] = this.urlContent;
      }
      return this.message; 
    },
    
    setWebPushNotificationsConfig(config){
      
      this.message = config || this.message;
      this.textContent = this.message.payload.body;
      this.titleContent = this.message.payload.title;
      
      this.from = this.webPushConfigs.filter(c=>c._id == this.message.from).length > 0 ? this.webPushConfigs.filter(c=>c._id == this.message.from)[0] : this.webPushConfigs.length > 0 ? this.webPushConfigs[0] : null 
      this.$refs.editableDiv.innerHTML = this.textContent;
      this.$refs.titleEditableDiv.innerHTML = this.titleContent;
      
      this.data = Object.keys(this.message.data || {}).map((clave) => {
        return { key: clave, value: this.message.data[clave] };
      });
      
      this.urlContent = this.data.filter(d=>d.key=='url').length > 0 ? this.data.filter(d=>d.key=='url')[0].value : ''  ;
      this.$refs.editableURLDiv.innerHTML = this.urlContent;
      
      if(this.data.length == 0){
        this.data.push({
          key:'',
          value: ''
        })
      }else{
        this.data = this.data.filter(d=>d.key!='url');

        if(this.data.length ==0){
        this.data = [
          {
            key:'',
            value:''
          }
        ]
      }
      }
    },

    onVariableClicked(textToInsert){
      const editableDiv = this.$refs.editableDiv;
      
      // Get the current cursor position
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      const currentPosition = range.startOffset;
    if (!editableDiv.contains(range.startContainer)){
        return ;
      }
        
      // Insert the {{var}} text at the cursor position
      document.execCommand('insertText', false, textToInsert);

      // Restore the cursor position to the end of the inserted text
      range.setStart(editableDiv.firstChild, currentPosition + textToInsert.length);
      range.setEnd(editableDiv.firstChild, currentPosition + textToInsert.length);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    handleTextInput(event) {
      this.textContent = event.target.textContent;
      this.debouncePreview();
    },
    handleTextURLInput(event){
      this.urlContent = event.target.textContent;
    },
    onTextVariableSelected(variable){
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(`{{${variable.column_name}}}`);
      div.appendChild(textNode);

      this.textContent = div.textContent;
      this.debouncePreview();
    },
    onIntegrationsClicked(){
      this.$router.push({
          path: "/integrations",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    },
    onAddIntegrationClicked(){
      this.showIntegrationsAvailable = true
    },
    onConfirmNewIntegration(){
        this.showIntegrationsAvailable = false;
        this.loadSMSIntegrations(true);
    },
    handlTitleInput(event){
      this.titleContent = event.target.textContent;
      this.debouncePreview();
    },
    handleTitleEmojiClick(detail){
      const div = this.$refs.titleEditableDiv;
      const textNode = document.createTextNode(detail.native);
      div.appendChild(textNode);

      this.titleContent = div.textContent;
      this.debouncePreview();
      this.showTitleEmojis = false;
    },
    onTitleVariableSelected(variable){
      
      const div = this.$refs.titleEditableDiv;
      const textNode = document.createTextNode(`{{${variable.column_name}}}`);
      div.appendChild(textNode);

      this.textContent = div.textContent;
      this.debouncePreview();
    
    },
    onUploadImageClicked(){
      document.getElementById('imagePicker').click();
    },
    onImagePicker(){
      
      if(document.getElementById('imagePicker').files.length ==0)
				return;

			var file = document.getElementById('imagePicker').files[0];

      if(file){
        this.uploadMedia(file)
				.then((res) => {
          this.message.payload.image = res.data.url
        }).catch(() => {
            this.$notify({ type: 'error', text: this.$t("personalizations.upload_error"),title: "Media"});
					})
			}
    },
    onMinMaxClicked(){
      this.minimized=!this.minimized;
    },
    onNewPersonalizedData(){
      this.data.push({
        key:'',
        value:''
      })
    },
    validatePushNotificationConfig(){
      this.submitted = true;
      let isOk = false;

      if(this.from && this.titleContent && this.titleContent !='' && this.textContent && this.textContent!='')
        isOk = true;

      return isOk;
    },
    onRemoveDataClicked(index){
      this.data.splice(index,1);
    },
    loadPreview(){


    },
    /*loadPreview(){

      let iFrame = null;
      if(this.preview == 'android'){
        var previewDiv = document.getElementById("preview");
        iFrame = previewDiv.getElementsByTagName("iframe")[0];
      }else{
        var iosPrewview = document.getElementById("iosPrewview");
        iFrame = iosPrewview.getElementsByTagName("iframe")[0];
      }
      
      const src = this.preview == 'android' ? this.message.payload.image ? 'devices/android_expanded.html' : 'devices/android.html' : this.message.payload.image ? 'devices/ios_expanded.html' : 'devices/ios.html' ;
      iFrame.src = `${src}?title=${this.titleContent}&message=${this.textContent}&image=${this.message.payload.image}`
    },*/
    onImageInputChanged(){
      this.debouncePreview();
    },
    rightDrawer() {
      this.openRightDrawer = !this.openRightDrawer;
    },
    rightTestDrawer(){
      this.openRightTestDrawer = !this.openRightTestDrawer;
    },
    onSuggestedTextSelected(text){
      if(this.field == 'title'){
        this.titleContent = text;
        const div = this.$refs.titleEditableDiv;
        div.textContent = text;
      }else{
        this.textContent = text;
        const div = this.$refs.editableDiv;
        div.textContent = text;
      }
      this.openRightDrawer = false;

      this.debouncePreview();
    },
    showTestChannel(){
      this.testMessage = this.getWebPushNotificationsConfig();
      this.openRightTestDrawer = true;
    }
  },

	props: {
		variant: Object
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="mb-3">
            <label>{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from')}}</label>
            <Multiselect
              :options="webPushConfigs"
              v-model="from"
              selectLabel="''"
              deselectLabel=""
              placeholder=""
              :multiple="false"
              :allowEmpty="false"
              track-by="_id" 
              label="label"
              open-direction="bottom"
              v-if="webPushConfigs.length > 0">
            </Multiselect>
            <div class="card bg-info bg-gradient text-white mb-0">
              <div class="card-body p-2">
                <p class="card-text" v-if="webPushConfigs.length > 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from_integration')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onIntegrationsClicked">{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from_integration2')}}</i> </p>
                <p class="card-text" v-if="webPushConfigs.length == 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from_empty')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onAddIntegrationClicked">{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from_empty2')}}</i> {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_from_empty3')}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label class="mb-0">{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_title')}}</label>
            <div class="d-flex-inline input-group">
              <div class="form-control d-flex-inline" contentEditable="true" ref="titleEditableDiv" id="titleEditableDiv"  @input="handlTitleInput">
              </div>
              <button class="btn btn btn-outline-primary btn-md" @mousedown.prevent @mouseup.prevent @click.stop="showTitleEmojis = true; showTextEmojis=false"> <i class="mdi mdi-emoticon-happy-outline"></i></button>
              <button class="btn btn btn-outline-primary btn-md" @click.stop="openRightDrawer = true; field = 'title'; limit = 100"> <i class="mdi mdi-wizard-hat"></i></button>
            </div>
            <div class="invalid-feedback" v-if="submitted && (!titleContent || titleContent == '')" style="display: block !important">
              {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_title_required')}}
            </div>
            <div class="emoji-menu" v-if="showTitleEmojis" id="titleEmojiDiv">
              <picker @select="handleTitleEmojiClick" :showPreview="false" :sheetSize="32"/>
            </div>
            <div class="d-flex-inline input-group">
              <DynamicContent @variableSelected="onTitleVariableSelected"></DynamicContent>
              <label class="text-length">{{ `${titleContent.length}/100` }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label for="phone">{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_message')}}</label>
            <div class="d-flex-inline input-group" style="height: 80px;">
              <div class="form-control d-flex-inline" contentEditable="true" ref="editableDiv" id="editableDiv"  @input="handleTextInput">
              </div>
              <button style="height: 40px;" class="btn btn btn-outline-primary btn-md" @mousedown.prevent @mouseup.prevent @click.stop="showTextEmojis = true"> <i class="mdi mdi-emoticon-happy-outline"></i></button>
              <button style="height: 40px;" class="btn btn btn-outline-primary btn-md" @click="openRightDrawer = true; field = 'message'; limit = 255"> <i class="mdi mdi-wizard-hat"></i></button>
            </div>
            <div class="invalid-feedback" v-if="submitted && (!textContent || textContent == '')" style="display: block !important">
              {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_message_required')}}
            </div>
            <div class="emoji-menu" v-if="showTextEmojis" id="textEmojiDiv">
              <picker @select="handleTextEmojiClick" :showPreview="false" :sheetSize="32"/>
            </div>
            <div class="d-flex-inline input-group">
              <DynamicContent @variableSelected="onTextVariableSelected"></DynamicContent>
              <label class="text-length">{{ `${textContent.length}/255` }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label>{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_image')}}</label>
              <div class="input-group">
                    <b-form-input type="text" class="form-control " v-model="message.payload.image" :placeholder="$t('journeys.journey_workflow_components_channel_variants_app_push_config_image_placeholder')" @input="onImageInputChanged" />
                    <button class="btn btn-primary" type="button" id="inputGroupFileAddon03" @click="onUploadImageClicked()"> <i class="bx bx-upload"/> </button>
                    <input
                      id="imagePicker"
                      @change="onImagePicker()"
                      accept="image/*"
                      class="form-control "
                      style="display: none;"
                      type="file"/>
                  </div>
                </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label>URL</label>
            <div class="d-flex-inline input-group" style="height: 40px;">
              <div class="form-control d-flex-inline" contentEditable="true" ref="editableURLDiv" id="editableURLDiv"  @input="handleTextURLInput">
              </div>
            </div>
          </div>
        </div>
        <!--<div>
            <label v-on:click="onMinMaxClicked"><i class="bx" :class="minimized? 'bx bx-chevron-down' : 'bx bx-chevron-up'"></i> {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_advanced')}}</label>
            <div v-if="!minimized">
              {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_advanced_data')}}
              <div class="row ps-2 pt-2 mb-3 align-items-baseline" v-for="(h, index) in data" :key="index">
                <div class="col-4 ps-0 pe-2">
                  <input class=" form-control form-control-sm" v-model="h.key" :placeholder="$t('journeys.journey_workflow_components_channel_variants_app_push_config_advanced_data_key')">
                </div>
                <div class="col-4 pe-0 ps-1 me-0 d-inline-flex align-items-baseline">
                  <input class="form-control form-control-sm" v-model="h.value" :placeholder="$t('journeys.journey_workflow_components_channel_variants_app_push_config_advanced_data_value')">
                  <i class="bx bx-trash interact ms-2" v-on:click="onRemoveDataClicked(index)"  v-if="data.length>1"></i>
                </div>
                <div class="col">
                  <button class="btn btn-sm btn-link" v-if="index==data?.length-1" @click="onNewPersonalizedData">+ {{$t('journeys.journey_workflow_components_channel_variants_app_push_config_advanced_new_data')}}</button>
                </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col d-flex flex-column" v-if="isLocal">
        <label class="card-title">Preview</label>
        <span class="small">Esta vista previa proporciona una idea general de cómo se mostrará tu mensaje.</span> 
        <div class="btn-group btn-group-sm pl-2 w-70 align-self-center mt-2" role="group" style="width: 50%">
            <button  type="button" :class="preview == 'desktop' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="preview='ios'; debouncePreview()"> <i class="bx bxl-apple" /></button>
            <button  type="button" :class="preview == 'mobile' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="preview='android'; debouncePreview()"><i class="bx bxl-android"/></button>

        </div>
        <div id="preview" style="margin: 0 auto; position: relative;" :style="{display: preview=='android' ? 'block' : 'none' }"></div>
        <div id="iosPrewview" :style="{display: preview=='ios' ? 'block' : 'none' }" style="height: 490px;  margin: auto; margin-top: 10px !important;">
          <!--<iframe src="devices/ios.html" style="width: 545px; height: 1181px; transform: scale(0.4); transform-origin: top;" /> -->
          <iframe style="width: 375px; height: 812px; transform: scale(0.6); transform-origin: top;" /> 
        </div>
      </div>   
      <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" :title="$t('integrations.new')" hide-footer>
        <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" :types="['web_push']" />
      </b-modal>
      <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="mdi mdi-wizard-hat font-size-16 align-middle me-1"></i>
                  {{$t('broadcasts.generate_text')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <AITextGenerator :channel="'Web Push Notification'" @onTextSelected="onSuggestedTextSelected" :field="field" :limit="limit" ></AITextGenerator>
              </div>
          </div>
      </Drawer>
      <Drawer
        @close="rightTestDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightTestDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  {{$t('broadcasts.test_message_web_push')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <TestChannel :channel="'web_push'" :message="testMessage" @onTestSent="openRightTestDrawer = false"></TestChannel>
              </div>
          </div>
      </Drawer>
    </div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}

.emoji-menu{
  position: absolute;
  right: calc(var(--bs-gutter-x) * 0.5);
  z-index: 999;
  overflow: hidden;
}

.email-container{
  width: 300px;
  height: 150px;
  border-style: solid;
  border-radius: 8px;
  border-color: lightgray;
  border-width: 0.5px;
}

.text-length{
  position: absolute;
  right: 12px;
  font-size: 10px;
  margin-top: 2px;
}

</style>

<style>
#preview iframe{
  position: absolute;
  top: 92px !important;
  left: 20.5px !important;
  width: 385px !important;
  height: 700px !important;
  border: 0px !important;
}
</style>