<script>

import {integrationTypes} from "../../services/integrations";
import DropZone from "@/components/widgets/dropZone";
import FeedConfig from "./feed-config.vue";
import {
	integrationMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: { DropZone, FeedConfig
	},
  data() {
		return {
			integrationsAvailable: Object.assign([], integrationTypes),
      integrations:[],
      showNewIntegrationModal: false,
      selectedIntegration:null,
      submitted: false,
      domains:[],
      domain:'',
      channelType: 'web',
      showGoShopsResultIntegrationModal: false,
      goShopsResultIntegration : {}
		};
	},
	methods: {
		...integrationMethods,

    onDropFileZoneSelected(obj){

      if(obj.field.format == 'application/json'){
        var reader = new FileReader();
        reader.onload = (event)=>{
          var fileObj = JSON.parse(event.target.result);
          obj.field.value = fileObj;

          if(this.selectedIntegration){
            const index = this.selectedIntegration.fields.findIndex(f=> f.name == obj.field.name);
            this.$set(this.selectedIntegration.fields, index, obj.field);
          }
        };
        reader.readAsText(obj.file);
      }
    },
		validateIntegrationFields(){
      
      for (let i = 0; i< this.selectedIntegration.fields.length; i++){
        if( this.selectedIntegration.fields[i].type == 'checkbox' && this.selectedIntegration.fields[i].value == null){
          this.selectedIntegration.fields[i].value = false
        }
        
        if(this.selectedIntegration.fields[i].value == null || this.selectedIntegration.fields[i].value === '' ){
          return false;
        }
      }
      return true;
    },
    onAddIntegrationClicked(integration){
      integration.fields.forEach((f)=>{
        f.value = null;
      })
      this.submitted = false;
      const cloneIntegration = Object.assign({}, integration);
      this.selectedIntegration = cloneIntegration;
      this.showNewIntegrationModal = true;
    },
    parseDataFromFields(){
      const data = {};
      this.selectedIntegration.fields.forEach((f)=>{
        data[f.key] = f.value;
      })

      if(this.selectedIntegration.group == 'go_shops' && this.selectedIntegration.type == 'go_shops_web'){
        data.domains = this.domains;
      }
      return data;
    },  
    onConfirmAddNewIntegrationClicked(){
      this.submitted = true;

      if(!this.validateIntegrationFields())
        return;
      
        if(this.selectedIntegration.type=='feed_url'){
          if(!this.$refs.feedConfigs.validateFeedConfigSettings())
            return;
        }

        const loader = this.$loading.show();

        const inte = {
          name: this.selectedIntegration.name,
          img: this.selectedIntegration.icon,
          group: this.selectedIntegration.group,
          type: this.selectedIntegration.type,
          data: this.parseDataFromFields(),
          project: localStorage.getItem('current_project')
        }
        if( this.selectedIntegration.group =='go_shops'){

          const authChannel = {
            name: inte.data['name'],
            project: localStorage.getItem('current_project'),
            channelType: this.channelType,
            hasAuth: true,
            sessionExpirationTime: inte.data.sessionExpirationTime
          }

          if(this.selectedIntegration.type =='go_shops_web')
            authChannel.channelDomain = this.domains

          this.createGoShopsIntegration(authChannel).then((res)=>{
            inte.data.clientId = res.data.clientId;
            inte.data.authId = res.data._id;
            if(inte.type == 'go_shops_web')
              inte.data.domains = this.domains

            this.createIntegration(inte).then(()=>{
                this.showNewIntegrationModal = false;
                this.goShopsResultIntegration = res.data;
                this.showGoShopsResultIntegrationModal = true;
                this.submitted = false;
              }).catch(()=>{
                
              }).finally(()=>{
                
              })

          }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('integrations.create_integration_success'),title:  this.$t('integrations.title') });
          }).finally(()=>{
            loader.hide()
          })
        }else{
          if(this.selectedIntegration.type=='feed_url' || this.selectedIntegration.group=='feed'){
            inte.data = { ...this.$refs.feedConfigs.getConfig(), ...inte.data } ; 
          }
          
          this.createIntegration(inte).then(()=>{
            this.showNewIntegrationModal = false;
            this.$emit('confirmAddIntegration');
            this.submitted = false;
          }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('integrations.create_integration_error'),title:  this.$t('integrations.title') });
          }).finally(()=>{
            loader.hide()
          })
        }
        
    },
    onCheckGoshopsIntegrationPicked(type){
      switch(type) {
        case 'web':
          this.selectedIntegration.type = 'go_shops_web';
          this.channelType = "web";
          break;
        case 'mobile': 
          this.selectedIntegration.type = 'go_shops_mobile';
          this.channelType = "mobile";
          break;
        case 'server':
          this.selectedIntegration.type = 'go_shops_server';
          this.channelType = "server";
          break;
      }
    },
    onAddDomainClicked(){
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      if(this.domain && this.domain!='' && domainRegex.test(this.domain)){
        this.domains.push(this.domain);
        this.domain = ''
      }
    },
    onRemoveDomain(index){
      this.domains.splice(index,1);
    },
    onAcceptAddGoShopsIntegrationClicked(){
      this.showGoShopsResultIntegrationModal = false;
      this.$emit('confirmAddIntegration');
    },
    onCopyValueClicked(value){
      if(value == 'clientId')
        navigator.clipboard.writeText(this.goShopsResultIntegration.clientId);
      else if (value == 'clientSecret'){
        navigator.clipboard.writeText(this.goShopsResultIntegration.clientSecret);
      }
    }
  },
	mounted() {
		//this.loadIntegrations();
  },
	props: {
		project : {
			type: Object
		},
    integrationsAdded: {
      type: Array
    },
    group : {
			type: String
		},
    types : {
			type: Array
		},
	},
	emits: ['confirmAddIntegration'],
  watch :{
    group: {
      immediate:true,
      handler(newVal) {
        if(newVal){
          this.integrationsAvailable = this.integrationsAvailable.filter(i=> i.group == newVal);
        }
      }
    },
    types: {
      immediate:true,
      handler(newVal) {
        if(newVal){
          this.integrationsAvailable = this.integrationsAvailable.filter(i=> newVal.includes(i.type));
        }
      }
    }
  }
};
</script>

<template>
	<div class="row" id="jobgrid-list">
      <div class="col-xl-2 col-md-6" v-for="inte in integrationsAvailable" :key="inte.name">
          <div class="card">
              <div class="card-body">
                  <img :src="inte.icon" alt="" height="50" class="mb-3">
                  <h5 class="fs-17 mb-2">{{inte.name}}</h5>
                  <div class="mt-3 hstack gap-2">
                    <span class="badge rounded-1" :class="{
                      'bg-success': `${inte.group}` === 'feed',
                      'bg-info': `${inte.group}` === 'channel',
                      'bg-primary': `${inte.group}` === 'go_shops',
                      'bg-warning': `${inte.group}` === 'other',
                      'bg-danger': `${inte.group}` === 'crm',
                      }">
                      {{ $t(`integrations.group_${inte.group}`) }}</span>
                  </div>
                  <div class="mt-4 hstack gap-2">
                      <a class="btn btn-primary w-100" v-on:click="onAddIntegrationClicked(inte)" v-if="inte?.group != 'feed' || integrationsAdded?.filter((i)=> i.name == inte?.name).length == 0 ">{{$t('common.add')}}</a>
                      <span class="btn w-100 btn-link" v-if="inte?.group == 'feed' && integrationsAdded?.filter((i)=> i.name == inte?.name).length > 0 ">{{$t('integrations.already_added')}}</span>
                  </div>
              </div>
          </div>
      </div>
      <b-modal  v-model="showNewIntegrationModal" :title="selectedIntegration?.name" @ok.prevent="onConfirmAddNewIntegrationClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        
        <div v-if="selectedIntegration?.type=='feed_url'">
          <FeedConfig ref="feedConfigs" :integtation="selectedIntegration"/>
        </div>
        <div v-else-if="selectedIntegration?.group == 'feed'">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-cog"></i>
                </span>
                <span class="d-none d-sm-inline-block">  <i class="bx bx-cog"></i> General</span>
              </template>
              <div class="row">
                <div v-for="field in selectedIntegration?.fields" :key="field.name" class="mb-3">
                  <label class="mb-0">{{field.name}}</label>
                  <DropZone @drop.prevent="drop" v-if="field.type=='file' && !field.value" ref="dropZone" :accept="field.format || ''" @onFile="onDropFileZoneSelected" :field="field" />
                  <!--<input :type="field.type" class="form-control" v-model="field.value" :accept="'.json'" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type=='file'"/> -->
                  <input :type="field.type" class="form-control" v-model="field.value" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type!='checkbox' && field.type!='file'"/>
                  <div class='form-check form-switch form-switch-lg' v-if="field.type=='checkbox'">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="field.value"
                      />
                    </div>
                  <div v-if="submitted && (!field.value || field.value=='')" class="invalid-feedback">
                    {{$t('integrations.field_requiered')}}
                  </div> 
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-stopwatch"></i>
                </span>
                <span class="d-none d-sm-inline-block">  <i class="bx bx-stopwatch"></i> {{$t('integrations.feed_sync')}}</span>
              </template>
              <FeedConfig ref="feedConfigs" :type="selectedIntegration?.type" :integration="selectedIntegration" class="mt-2"/>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else>
          <div v-for="field in selectedIntegration?.fields" :key="field.name" class="mb-3">
            <label class="mb-0">{{field.name}}</label>
            <DropZone @drop.prevent="drop" v-if="field.type=='file' && !field.value" ref="dropZone" :accept="field.format || ''" @onFile="onDropFileZoneSelected" :field="field" />
            <!--<input :type="field.type" class="form-control" v-model="field.value" :accept="'.json'" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type=='file'"/> -->
            <input :type="field.type" class="form-control" v-model="field.value" :class="{'is-invalid':submitted && (!field.value || field.value==''),}" v-if="field.type!='checkbox' && field.type!='file'"/>
            <div class='form-check form-switch form-switch-lg' v-if="field.type=='checkbox'">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="field.value"
                />
              </div>
            <div v-if="submitted && (!field.value || field.value=='')" class="invalid-feedback">
              {{$t('integrations.field_requiered')}}
            </div> 
          </div>
          <div v-if="selectedIntegration?.group=='go_shops'" class="mb-3">
            <div class="form-check form-check-inline font-size-16">
              <input
                class="form-check-input"
                type="radio"
                name="paymentoptionsRadio"
                id="paymentoptionsRadio1"
                checked=""
                @input="onCheckGoshopsIntegrationPicked('web')"
              />
              <label class="form-check-label font-size-13" for="paymentoptionsRadio1">
                <i class="bx bx-globe font-size-20 align-top"></i> Web
              </label>
            </div>
            <div class="form-check form-check-inline font-size-16">
              <input
                class="form-check-input"
                type="radio"
                name="paymentoptionsRadio"
                id="paymentoptionsRadio2"
                @input="onCheckGoshopsIntegrationPicked('mobile')"
              />
              <label class="form-check-label font-size-13" for="paymentoptionsRadio2">
                <i class="bx bx-mobile font-size-20 align-top"></i> App
              </label>
            </div>
            <div class="form-check form-check-inline font-size-16">
              <input
                class="form-check-input"
                type="radio"
                name="paymentoptionsRadio"
                id="paymentoptionsRadio2"
                @input="onCheckGoshopsIntegrationPicked('server')"
              />
              <label class="form-check-label font-size-13" for="paymentoptionsRadio2">
                <i class="bx bx-server font-size-20 align-top"></i> Server
              </label>
            </div>
          </div>
          <div v-if="selectedIntegration?.group=='go_shops' && selectedIntegration?.type == 'go_shops_web'">
            <label class="mb-0">{{$t('integrations.domains')}}</label>
            <div class="d-flex-inline input-group">
              <b-form-input placeholder="Add domain" class="form-control" type="url" v-model="domain"></b-form-input>
              <button class="btn btn btn-outline-primary btn-md" @click="onAddDomainClicked"> <i class="mdi mdi-plus"></i></button>
            </div>
            <div v-if="domains.length > 0" class="row mt-2">
              <div class="col">
                <table class="table table-sm align-middle table-nowrap table-hover mt-1 pe-2 ps-2">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{$t('integrations.domain')}}</th>
                      <th scope="col" style="width: 20px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(domain,index) in domains" :key="domain">
                        <td>{{domain}}</td>
                        <td class="text-end">
                            <i class="fas fa-trash-alt text-danger me-2 interact" v-on:click="onRemoveDomain(index)"></i>
                        </td>
                    </tr>
                  </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="showGoShopsResultIntegrationModal" :title="goShopsResultIntegration?.name" hide-footer >
        <div>
          <div class="row mb-3">
            <div class="col">
              <label class="mb-0">ClientId</label>
              <b-input-group>
                <b-form-input v-model="goShopsResultIntegration.clientId" type="text" disabled></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" @click="onCopyValueClicked('clientId')"><i class="bx bx-copy"></i></b-button>
                  </b-input-group-append>
              </b-input-group>

            </div>
          </div>
          <div class="row mb-3" v-if="goShopsResultIntegration.clientSecretPartial">
            <div class="col">
              <label class="mb-0">Client Secret</label>
              <b-input-group>
                <b-form-input v-model="goShopsResultIntegration.clientSecretPartial" type="text" disabled></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" @click="onCopyValueClicked('clientSecret')"><i class="bx bx-copy"></i></b-button>
                  </b-input-group-append>
              </b-input-group>
              <div class="card bg-warning text-white-50 mt-2">
                <div class="card-body">
                  <p class="text-white card-text"> <i class="mdi mdi-alert-outline" style="font-size: 20px;"/>  {{$t('integrations.copy_client_secret_text')}} </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-end pt-2 mt-3">
            <b-button variant="primary" class="ms-1" @click="onAcceptAddGoShopsIntegrationClicked">{{$t('common.accept')}}</b-button>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<style>
.interact {
  cursor: pointer;
}


</style>