
import TemplateService from '../../services/templates'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createTemplate ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      TemplateService.createTemplate(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getTemplates ({ commit },params) {

    return new Promise((resolve, reject) => { 
      TemplateService.getTemplates(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getTemplate ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      TemplateService.getTemplate(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteTemplate ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      TemplateService.deleteTemplate(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateTemplate ({ commit }, template) {

    return new Promise((resolve, reject) => { 
      TemplateService.updateTemplate(template).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateTemplateStore({ commit }, template) {

    return new Promise((resolve, reject) => { 
      TemplateService.updateTemplateStore(template).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  getTemplatesStore ({ commit },params) {

    return new Promise((resolve, reject) => { 
      TemplateService.getTemplatesStore(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}