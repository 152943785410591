import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createProject = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/projects`,
    payload,
    config
  )
}

const _getProjects = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/projects?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/projects?limit=50`


  return Request.get(
    url,
    config
  )
}

const _deleteProject = projectId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/projects/${projectId}`, config)
}

const _getProject = projectId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/projects/${projectId}`, config)
}
const _updateProject = (project) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/projects/${project._id}`, project, config)
}

const _getItems = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/items?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/items`


  return Request.get(
    url,
    config
  )
}

const _updateItem = (item) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/items/${item._id}`, item, config)
}

const _getCustomers = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/customers?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/customers`


  return Request.get(
    url,
    config
  )
}

const _getInteractions = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/interactions?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/interactions`


  return Request.get(
    url,
    config
  )
}

const _getDiscoverToken = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= `${window.config.VUE_APP_BACKEND}/api/projects/discover-token?${params.q}`

  return Request.get(
    url,
    config
  )
}

const _getEmbeddings = payload=> {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/projects/embeddings`,
    payload,
    config
  )
}

const _getSearchRedirects = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= `${window.config.VUE_APP_BACKEND}/api/searches/redirect?${params.q}`

  return Request.get(
    url,
    config
  )
}

const _updateRedirect = (redirect) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/searches/redirect/${redirect._id}`, redirect, config)
}

const _deleteRedirect = redirectId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/searches/redirect/${redirectId}`, config)
}

const _createRedirect = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/searches/redirect`,
    payload,
    config
  )
}

export default {
  updateProject: _updateProject,
  deleteProject : _deleteProject, 
  getProject : _getProject, 
  getProjects : _getProjects,
  createProject : _createProject,
  getItems: _getItems,
  updateItem:_updateItem,
  getCustomers : _getCustomers,
  getInteractions : _getInteractions,
  getDiscoverToken: _getDiscoverToken,
  getEmbeddings : _getEmbeddings,

  getSearchRedirects: _getSearchRedirects,
  deleteRedirect: _deleteRedirect,
  updateRedirect: _updateRedirect,
  createRedirect: _createRedirect
}
