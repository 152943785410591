<script>
import {templatesWebContent, recoTemplates, customCodeTemplates, notificationsTemplates, popUpTemplates, apiTemplates, emailTemplates, feedbackTemplates} from "../../services/templates";
import EmptyList from '@/components/widgets/empty_list'

import {
  templateMethods
} from "@/state/helpers";


export default {
	data() {
		return {
			empty_config:{
        "title" : this.$t('personalizations.my_templates_empty'),
        "subtitle" : this.$t('personalizations.my_templates_empty_subtitle'),
        "buttonText" : null,
        icon: "fa-cubes"
      },
      pageLoaded:false,
      templates: this.getTemplates(this.type),
      myTemplates:[],
      companyPicture: "https://uploads-ssl.webflow.com/62a73c358583fc722d325842/62a73d629590967696df6168_logo.svg"
    };
	},
  components:{EmptyList},
  methods: {
    ...templateMethods,

    onTemplateClicked(template){
      this.$emit('selectTemplate', template);
    },

    onMyTemplateClicked(template){
      this.$emit('selectTemplate', template);
    },

    loadMyTemplates(type){
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}&where[type]=${type}`
      }
      this.getTemplates(params).then((res)=>{
        this.myTemplates = res.data;
      }).catch(()=>{

      }).finally(()=>{

      })
    },
	},

	mounted() {
    //this.loadMyTemplates();
	},
	props: {
		channel : String,
    type : String,
	},
	emits: ['selectTemplate'],
  watch: {
    type:{
      immediate: true,
      handler(newVal){
        if(newVal){
          switch(newVal){
            case 'web': this.templates = templatesWebContent; break;
            case 'recommendations': this.templates = recoTemplates; break;
            case 'notifications': this.templates = notificationsTemplates; break;
            case 'pop_up': this.templates = popUpTemplates; break;
            case 'API': this.templates = apiTemplates; break;
            case 'API_SIMPLE': this.templates = apiTemplates; break;
            case 'custom_code': this.templates = customCodeTemplates; break;
            case 'email' : this.templates = emailTemplates; break;
            case 'feedback' : this.templates = feedbackTemplates;
          }

          this.loadMyTemplates(newVal);
        }
      }
    }
  }
};
</script>

<template>
  <div>
      <div class="offcanvas-header">
          <h4 class="offcanvas-title" id="offcanvasExampleLabel">
            <i class="bx bx-book-bookmark font-size-16 align-middle me-1"></i>
          {{$t('personalizations.experience_variant_my_templates')}}
          </h4>
      </div>
      <hr class="mt-1" />
      <div class="offcanvas-body">
        <EmptyList :config="empty_config" v-if="myTemplates.length == 0" class="mt-5" />
        <div class="list-group list-group-flush" v-if="myTemplates.length > 0">
              <a href="javascript:void(0);" class="list-group-item text-muted py-3 px-2" v-for="template in myTemplates" :key="template.id" v-on:click="onMyTemplateClicked(template)">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="font-size-13 text-truncate">
                      {{ template.name}}
                    </h5>
                  </div>
                </div>
              </a>
            </div>
        <!--<b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                
              </span>
              <span class="d-none d-sm-inline-block"> <i class="bx bx-customize"></i> Templates</span>
            </template>

            <div class="list-group list-group-flush">
              <a href="javascript:void(0);" class="list-group-item text-muted py-3 px-2" v-for="template in templates" :key="template.id" v-on:click="onTemplateClicked(template)">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      :src="template.image"
                      alt=""
                      class="avatar-md h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="font-size-13 text-truncate">
                      {{ $t(`personalizations.experience_variant_templates_${template.name}`)}}
                    </h5>
                    <p class="mb-0 text-truncate">{{ template.description ? $t(`personalizations.experience_variant_templates_${template.description}`): '' }}</p>
                  </div>
                </div>
              </a>
            </div>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                
              </span>
              <span class="d-none d-sm-inline-block"> <i class="bx bx-book-bookmark"></i> {{$t('personalizations.experience_variant_my_templates')}}</span>
            </template>

            <EmptyList :config="empty_config" v-if="myTemplates.length == 0" class="mt-5" />
            <div class="list-group list-group-flush" v-if="myTemplates.length > 0">
              <a href="javascript:void(0);" class="list-group-item text-muted py-3 px-2" v-for="template in myTemplates" :key="template.id" v-on:click="onMyTemplateClicked(template)">
                <div class="d-flex align-items-center">
                  <div class="me-3">
                    <img
                      :src="template.screenshot || companyPicture"
                      alt=""
                      class="avatar-md h-auto d-block rounded"
                    />
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="font-size-13 text-truncate">
                      {{ template.name}}
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </b-tab>
        </b-tabs>-->
      </div>
  </div>
</template>