
import PersonalShopperService from '../../services/personalShoppers'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createPersonalShopper ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      PersonalShopperService.createPersonalShopper(payload).then(response => { 
        if (response.data && response.data.data?._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating personal shopper'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getPersonalShoppers({ commit },params) {

    return new Promise((resolve, reject) => { 
      PersonalShopperService.getPersonalShoppers(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  deletePersonalShopper({ commit }, id) {

    return new Promise((resolve, reject) => { 
      PersonalShopperService.deletePersonalShopper(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getPersonalShopper({ commit }, id) {

    return new Promise((resolve, reject) => { 
      PersonalShopperService.getPersonalShopper(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updatePersonalShopper ({ commit }, ps) {

    return new Promise((resolve, reject) => { 
      PersonalShopperService.updatePersonalShopper(ps).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  trainPersonalShopper ({ commit }, psId) {

    return new Promise((resolve, reject) => { 
      PersonalShopperService.trainPersonalShopper(psId).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
}