
import StrategyService from '../../services/strategies'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createCustomStrategy ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      StrategyService.createCustomStrategy(payload).then(response => { 
        if (response.data && response.data.data?._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating group'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCustomStrategies({ commit },params) {

    return new Promise((resolve, reject) => { 
      StrategyService.getCustomStrategies(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  deleteCustomStrategy({ commit }, id) {

    return new Promise((resolve, reject) => { 
      StrategyService.deleteCustomStrategy(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCustomStrategy({ commit }, id) {

    return new Promise((resolve, reject) => { 
      StrategyService.getCustomStrategy(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateCustomStrategy ({ commit }, strategy) {

    return new Promise((resolve, reject) => { 
      StrategyService.updateCustomStrategy(strategy).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}