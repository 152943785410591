import { render, staticRenderFns } from "./test-channel.vue?vue&type=template&id=34df6d26&scoped=true"
import script from "./test-channel.vue?vue&type=script&lang=js"
export * from "./test-channel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34df6d26",
  null
  
)

export default component.exports