
import InteractionService from '../../services/interactions'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createInteraction ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      InteractionService.createInteraction(payload).then(response => { 
        if (response.data && response.data.error == false) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating interaction'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getInteractions ({ commit },params) {

    return new Promise((resolve, reject) => { 
      InteractionService.getInteractions(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  deleteInteraction({ commit }, id) {

    return new Promise((resolve, reject) => { 
      InteractionService.deleteInteraction(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateInteraction ({ commit }, interaction) {

    return new Promise((resolve, reject) => { 
      InteractionService.updateInteraction(interaction).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}