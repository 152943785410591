import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _getLastSyncDate = () => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/last-replication-date`,
      config
    )
  }

  const _getTotalUsersCount = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/users-count?${params}`,
      config
    )
  }

const _getTotalItemsCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/search-analytics/items-count?${params}`,
    config
  )
}

const _getTotalSearchesCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/search-analytics/total-count?${params}`,
    config
  )
}

const _getTotalSearchesWithResultsCount = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/total-count-with-results?${params}`,
      config
    )
  }

  const _getSearchClusters = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/search-clusters?${params}`,
      config
    )
  }

  const _getSearchesByDay = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/searches-by-day?${params}`,
      config
    )
  }

  const _getTotalSearchesWithClickCount = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/total-count-clicked?${params}`,
      config
    )
  }

  const _getTotalSearchesAddedToCartCount = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/total-count-added-to-cart?${params}`,
      config
    )
  }

  const _getSearchesConversionsByDay = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/searches-conversions-by-day?${params}`,
      config
    )
  }

  const _getSearchesList = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/searches-list?${params}`,
      config
    )
  }

  const _getResultsBySearch = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/search-analytics/results-by-search?${params}`,
      config
    )
  }

  const _getSearchIndicators = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/indicators?${params}`,
      config
    )
  }

  const _getSearchCustomerCount = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/customer-count?${params}`,
      config
    )
  }

  const _getSearchCustomerCTR = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/customer-count-ctr?${params}`,
      config
    )
  }

  const _getSearchCustomerCR = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/customer-count-cr?${params}`,
      config
    )
  }

  const _getSearchTopProducts = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/top-searched-products?${params}`,
      config
    )
  }

  const _getSearchTopQueries = params => {

    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/analytics/search/top-searched-queries?${params}`,
      config
    )
  }

export default {
    getTotalUsersCount: _getTotalUsersCount,
    getLastSyncDate: _getLastSyncDate,
    getTotalItemsCount: _getTotalItemsCount,
    getTotalSearchesWithResultsCount: _getTotalSearchesWithResultsCount,
    getTotalSearchesCount: _getTotalSearchesCount,
    getSearchClusters: _getSearchClusters,
    getSearchesByDay: _getSearchesByDay,
    getTotalSearchesWithClickCount: _getTotalSearchesWithClickCount,
    getTotalSearchesAddedToCartCount: _getTotalSearchesAddedToCartCount,
    getSearchesConversionsByDay: _getSearchesConversionsByDay,
    getSearchesList: _getSearchesList, 
    getResultsBySearch: _getResultsBySearch,
    getSearchIndicators: _getSearchIndicators,

    getSearchCustomerCount: _getSearchCustomerCount,
    getSearchCustomerCTR: _getSearchCustomerCTR,
    getSearchCustomerCR: _getSearchCustomerCR,
    getSearchTopProducts: _getSearchTopProducts,
    getSearchTopQueries: _getSearchTopQueries

    
    
}