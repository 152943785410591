<script>

import Draggable from 'vuedraggable'
import ConditionRule from "./condition-rule.vue"
import EmptyList from '@/components/widgets/empty_list'


import {
	audienceMethods,
  propertyMethods,
  projectMethods,
  customerMethods,
  interactionMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
    Draggable,
    ConditionRule,
    EmptyList
	},
	data() {
		return {
			submitted:false,
      config: {
        values: []
      },
      affinity_Types: [],
			audiences:[],
      categories:[],
      properties_products:[],
      properties_crm: [],
      rfm_segments:[],
      customer_fields:[],
      custom_interactions:[],
      name: '',
      empty_config:{
        "title" : this.$t("personalizations.experience_targeting_empty"),
        "subtitle" : this.$t("journeys.journey_workflow_components_condition_complex_condition_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes"
      },
      drag: false,
      indexSelected:0,
      confirmDelete: false,
      confirmRename: false,
      actionIndex: -1,
      pathName: ''
    };
	},
	methods: {
		...audienceMethods,
    ...propertyMethods,
    ...projectMethods,
    ...customerMethods,
    ...interactionMethods,

    getConditionComplexConfig(){
      //this.config.value.name = this.name;
      return this.config;
    },

    setConditionComplexConfig(config){
      this.config = config || this.config;
      //this.name = this.config.value.name || '';
    },

    async addFixedCustomerProperties(){
      
      const fixed = await this.getCustomerFixedProperties();
      this.properties_crm = [...fixed, ...this.properties_crm];
    },

    addFixedProperties(){
      this.properties_products.push({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
			this.properties_products.push({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });

      this.addFixedCustomerProperties();
    },

    loadCategories(){
      const params = {
				q : `project=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},

    loadAudiences(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getAudiences(params).then((audiences)=>{
        this.audiences = audiences.data || [];
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.properties_crm = properties.filter(p=> p.entity == 'customer');
        this.affinity_Types = this.properties_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

    loadRFM(){
      
    
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      
      this.getRFMSegments(params).then((rfm_segments)=>{
        this.rfm_segments = rfm_segments;
      }).catch(()=>{

      }).finally(()=>{
        
      })
    },
    onRemoveCondition(index){
      this.config?.values[this.indexSelected]?.children.splice(index, 1);
    },

    onCreatePathClicked(){
      this.config.values.push({
        name: `Path ${this.config.values.length+ 1}`,
        type: "and",
        children:[]
      })
    },
    onCreateConditionClicked(){
      this.config.values[this.indexSelected]?.children?.push({
            field: null,
            operator: null,
            term: null
          })
    },
    onRemovePath(index){
      this.actionIndex = index;
      this.confirmDelete = true;
    },
    onRenamePathClicked(index){
      this.actionIndex = index;
      this.pathName = this.config.values[index].name;
      this.confirmRename = true;
    },
    onConfirmRemoveClicked(){
      this.config?.values.splice(this.actionIndex, 1);
      if(this.indexSelected == this.actionIndex)
        this.indexSelected = 0;
      this.confirmDelete = false;
    },
    onConfirmRenameClicked(){
      if(this.pathName)
        this.config.values[this.indexSelected].name = this.pathName;
      this.confirmRename = false;
    },
    loadCustomInteractions(){
			const params = {
        q : `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res)=>{
        if(res.data){
          this.custom_interactions = res.data;  
        }
      })
		},
	},

	mounted() {
		this.loadAudiences();
    this.loadCategories();
    this.loadProperties();
    this.loadRFM();
    this.loadCustomInteractions();
	},
	props: {
		
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 text-end">
        <div v-on:click="onCreatePathClicked" class="interact d-inline-flex">
          <i class="bx bx-plus font-size-18" ></i> {{$t('journeys.journey_workflow_components_condition_complex_path')}}
        </div>
        
      </div>
      <div v-if="config.values.length> 0">
        <Draggable v-model="config.values" class="d-flex" group="people" @start="drag=true" @end="drag=false">
          <div class="ps-0 mb-3 interact d-inline-flex" v-for="(element,index) in config.values" :key="element.id" :class="indexSelected == index ? 'selected' : ''" v-on:click="indexSelected = index">
            <i class="mdi mdi-drag-vertical text-end interact me-2"></i>
            <label class="pe-2 mb-0 interact" :class="index==indexSelected ? 'font-weight-bold' : ''">{{element.name}}</label>
            <b-dropdown
              class="card-drop"
              variant="white"
              right
              toggle-class="p-0"
              menu-class="dropdown-menu-end">
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical text-end interact"></i>
              </template>

              <b-dropdown-item @click="onRenamePathClicked(index)">
                <i class="fas fa-edit text-success me-1"></i>
                {{$t('common.edit')}}
              </b-dropdown-item>

              <b-dropdown-item @click="onRemovePath(index)" v-if="config.values.length > 1">
                <i class="fas fa-trash-alt text-danger me-1"></i>
                {{$t('common.delete')}}
              </b-dropdown-item>
          </b-dropdown> 
          </div>
        </Draggable>
      </div>
      <div>
        <div v-if="config.values[indexSelected]?.children.length > 0">
          <div class="btn-group-horizontal pt-1 pb-2" role="group">
          <button class="btn btn-sm me-2" :class="config.values[indexSelected].type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="config.values[indexSelected].type = 'and'">
            {{ $t("audiences.audience_conditions_and") }}
          </button>
            <button class="btn btn-sm" :class="config.values[indexSelected].type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="config.values[indexSelected].type = 'or'">
              {{ $t("audiences.audience_conditions_or") }}
            </button>
        </div>
        <div v-for="(item, index) in config.values[indexSelected]?.children" :key="index" class="pb-0 col">
          <ConditionRule :node="item" :index="index" @removeCondition="onRemoveCondition" :affinity_Types="affinity_Types" :audiences= "audiences" :categories="categories" class="mb-2" :rfm_segments="rfm_segments" :properties_crm="properties_crm" :customer_fields="customer_fields" :custom_interactions="custom_interactions" ></ConditionRule>
        </div>
        <button type="button" v-on:click="onCreateConditionClicked" class="btn btn-sm btn-primary me-2 mt-3 mb-3">{{ $t('audiences.audience_conditions_add_condition')  }}</button>
        </div>
        <EmptyList :config="empty_config" v-if="config.values[indexSelected]?.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-3"/>
        
      </div>
    </div>
    <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <p>{{$t('journeys.journey_workflow_components_condition_complex_remove')}}</p>
    </b-modal>

    <b-modal v-model="confirmRename" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRenameClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <div class="row">
          <div class="col">
            <label>{{$t('journeys.journey_workflow_components_condition_complex_name')}}</label>
            <input class="form-control" v-model="pathName"/>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}

.selected{
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #5e40bf;
  border-radius: 0px;
}

.font-weight-bold{
  font-weight: bolder;
}
</style>