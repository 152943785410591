import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createPersonalShopper = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/ps-configs`,
    payload,
    config
  )
}

const _getPersonalShoppers = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/ps-configs?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/ps-configs`


  return Request.get(
    url,
    config
  )
}

const _deletePersonalShopper = psId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/ps-configs/${psId}`, config)
}

const _getPersonalShopper = psId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/ps-configs/${psId}`, config)
}


const _updatePersonalShopper = (ps) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/ps-configs/${ps._id}`, ps, config)
}

const _trainPersonalShopper = psId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${window.config.VUE_APP_BACKEND}/api/ps-configs/${psId}/execute`, {}, config)
}

export default {
  updatePersonalShopper: _updatePersonalShopper,
  deletePersonalShopper : _deletePersonalShopper, 
  getPersonalShoppers : _getPersonalShoppers,
  createPersonalShopper : _createPersonalShopper,
  getPersonalShopper: _getPersonalShopper,
  trainPersonalShopper: _trainPersonalShopper
}
