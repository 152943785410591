<script>
import appConfig from "@/app.config";

import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl')
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
  },
};
</script>

<template>
  <div id="app">
    <notifications position="bottom right" classes="my-notification"/>
    <RouterView />
  </div>
</template>

<style lang="scss">

.my-notification {
  
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  border: 1px solid #D4E8FD;

  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;    
  } 
  

  &.success {
    background: #d6f3e9;
    border-left-color: #c2eddd;
    color: #1f7556 !important;
  }

  &.warn {
    background: #fcf0db;
    border-left-color: #fbe9c9;
    color: #916c2e !important;
  }

  &.error {
    background: #fde1e1;
    border-left-color: #fcd2d2;
    color: #924040 !important;
    
  }
}
</style>
