import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _getEventsGeneralStats = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/dashboard-indicators?${params}`,
      config
    )
}

const _getEventsGeneralStatsLastSyncDate = () => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/last-replication-date`,
      config
    )
}

const _getEventsList = params => {
    const token = getUserToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.get(
      `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/events-list?${params}`,
      config
    )
}

const _getTotalAmountSold = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/total-sold?${params}`,
    config
  )
}

const _getTotalAmountSoldByDay = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/total-sold-by-day?${params}`,
    config
  )
}

const _getEventsByDay = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/events-by-day?${params}`,
    config
  )
}

const _getAllEventsByDay = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/liveshopping-analytics/all-events-by-day?${params}`,
    config
  )
}

export default {
    getTotalAmountSold: _getTotalAmountSold,
    getEventsList: _getEventsList,
    getEventsGeneralStats: _getEventsGeneralStats,
    getEventsGeneralStatsLastSyncDate: _getEventsGeneralStatsLastSyncDate,
    getTotalAmountSoldByDay: _getTotalAmountSoldByDay,
    getEventsByDay: _getEventsByDay,
    getAllEventsByDay: _getAllEventsByDay
}