
import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createGoal = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/custom-goals`,
    payload,
    config
  )
}

const _getGoals = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/custom-goals?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/custom-goals`


  return Request.get(
    url,
    config
  )
}

const _deleteGoal = goalId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/custom-goals/${goalId}`, config)
}

const _updateGoal = (goal) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/custom-goals/${goal._id}`, goal, config)
}

export default {
  updateGoal: _updateGoal,
  deleteGoal : _deleteGoal, 
  getGoals : _getGoals,
  createGoal : _createGoal,
}
