import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createAvailability = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/availabilities`,
    payload,
    config
  )
}

const _getAvailabilities = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/availabilities?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/availabilities`


  return Request.get(
    url,
    config
  )
}

const _deleteAvailability = availabilityId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/availabilities/${availabilityId}`, config)
}

const _getAvailability = availabilityId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/availabilities/${availabilityId}`, config)
}

const _updateAvailability = (availability) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/availabilities/${availability._id}`, availability, config)
}


const _getTimeSlotsByAvailability = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/availabilities/time-intervals?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/availabilities`


  return Request.get(
    url,
    config
  )
}

const _validateTimeSlotAvailability = payload => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= `${window.config.VUE_APP_BACKEND}/api/availabilities/validate-availability`;


  return Request.post(
    url,
    payload,
    config
  )
}

export default {
  updateAvailability: _updateAvailability,
  deleteAvailability : _deleteAvailability, 
  getAvailabilities : _getAvailabilities, 
  getAvailability : _getAvailability,
  createAvailability : _createAvailability,
  getTimeSlotsByAvailability: _getTimeSlotsByAvailability,
  validateTimeSlotAvailability: _validateTimeSlotAvailability
}
