
import AvailabilityService from '../../services/availability'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createAvailability ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      AvailabilityService.createAvailability(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating availability'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAvailabilities ({ commit },params) {

    return new Promise((resolve, reject) => { 
      AvailabilityService.getAvailabilities(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAvailability ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      AvailabilityService.getAvailability(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteAvailability ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      AvailabilityService.deleteAvailability(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateAvailability ({ commit }, availability) {

    return new Promise((resolve, reject) => { 
      AvailabilityService.updateAvailability(availability).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getTimeSlotsByAvailability ({ commit },params) {

    return new Promise((resolve, reject) => { 
      AvailabilityService.getTimeSlotsByAvailability(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  validateTimeSlotAvailability ({ commit },payload) {
    return new Promise((resolve, reject) => { 
      AvailabilityService.validateTimeSlotAvailability(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  }
}