<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import {


} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
        submitted:false,
        products:[],
        aux_times: {
          operator: '>'
        },
        operators:[
          {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
          {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],
        operatorsPrice:[
          {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
          {"label" :this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],
        booleanValues: [
        {
          id: true,
          label: this.$t("common.yes"),
        },
        {
          id: false,
          label: this.$t("common.no"),
        },
      ],
      listOperatorValues: [
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_include"), "id" : "in"},
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_exclude"), "id" : "not_in"},
      ],
      aux_node:{
        changeOperator: null
      },
      textOperatorValues: [
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_contains"), "id" : "like"},
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_no_contains"), "id" : "not_like"},
      ],
      numberOperatorValues: [
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_greated_than"), "id" : ">"},
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_lower_than"), "id" : "<"},
        {"label" : this.$t("journeys.journey_workflow_components_wait_property_wait_condition_equal"), "id" : "="},
      ],
      dateOperatorsValues: [
        {
          id: "<",
          label: this.$t("journeys.journey_workflow_components_wait_property_wait_condition_day_lower"),
        },
        {
          id: ">",
          label: this.$t("journeys.journey_workflow_components_wait_property_wait_condition_day_greater"),
        },
        {
          id: "=",
          label: this.$t("journeys.journey_workflow_components_wait_property_wait_condition_day_equal"),
        },
        
      ],
      };
  },
  components: { Multiselect},
	name: "ProductExpressionBuilder",
  mounted() {
  },
    methods: {
        onProductFiedSelected(){
          //item.term = null;
          //item.operator = this.operatorFromItem(item);
			},

      operatorFromItem(item){
        if(item && item.field){
          switch (item.field.type){
            case 'list':
              return 'in';
            case 'products_list':
              return 'in';
            case 'text':
              return '=';
            case 'date':
              return '>';
            case 'product_price':
              return '>';
            case 'boolean':
              return '=';
            case 'product_category':
              return 'in';
            default:
              return '' 
          }
        }
        return '';
      },
			isNodeValid(){
				return this.node.field && this.node.operator && this.node.term;
			},
			onRemoveCondition(index){
				this.query.children.splice(index, 1);
			},
      onAddCondition(){
        this.query.children.push({
          field: null,
          operator:null,
          term: null
        })
      },
      onTimesOperatorSelected(time, isUpdate = false){
        if(!isUpdate){
          this.times.operator = time.id;
          this.aux_times.operator = time;
        }
        else{
          this.aux_times.operator = this.operators.filter(o=> o.id == time).length > 0 ? this.operators.filter(o=> o.id == time)[0] : null
        }
      },

      onOperatorSelected(item, isUpdate = false){
        if(!isUpdate){
          //item.operator = this.aux.operator;
        }else{
          //this.aux.operator = this.operators.filter(o=> o.id == item).length > 0 ? this.operators.filter(o=> o.id == item)[0] : null ;
        }
      },
      onListOperatorSelected(operator, isUpdate = false){
        if(!isUpdate){
          //this.query.operator = operator.id;
        }else{
          this.aux_node.changeOperator = this.listOperatorValues.filter(o=>o.id== operator).length > 0? this.listOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      },
      onTextOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          //this.query.operator = operator.id;
        }else{
          this.aux_node.changeOperator = this.textOperatorValues.filter(o=>o.id== operator).length > 0? this.textOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      },
      onNumberOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
         // this.query.operator = operator.id;
        }else{
          this.aux_node.changeOperator = this.numberOperatorValues.filter(o=>o.id== operator).length > 0? this.numberOperatorValues.filter(o=>o.id== operator)[0] : null
        }
      },
    },
  props: {
    query: Object,
    properties_products: Array,
    categories: Array
  },
	emits:["removeCondition"],
  
  watch: {
    times:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onProductFiedSelected(newVal.field, true);
          
        }
      }
    }
  }
}

 </script>

<template>
  <div class="row mb-2">
    <div class="col-sm-10">
      <div class="btn-group-horizontal pt-2" role="group">
          <button class="btn btn-sm me-1" :class="query.type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'and'">
              {{ $t("audiences.audience_conditions_and") }}
          </button>
          <button class="btn btn-sm" :class="query.type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'or'">
            {{ $t("audiences.audience_conditions_or") }}
          </button>
      </div>
    </div>
    <div class="col-sm-2 pt-2 text-end interact" v-on:click="onAddCondition">
      <i class="bx bx-plus font-size-18"></i>{{$t("audiences.audience_condition")}}
    </div>
    
		<div v-for="(item, index) in query.children" :key="index" class="row mt-2">
      <div class="col-sm-3">
            <multiselect
              :options="properties_products"
              v-model="item.field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              size="sm"
              @input="onProductFiedSelected(item)"
          />
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'boolean'">
            <multiselect
              :options="booleanValues" 
              v-model="item.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              :allowEmpty="false"
              class="form control form-control-sm w-50"/>
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'list'">
            <multiselect
                :options="listOperatorValues" 
                v-model="item.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="w-25"
                @input="onListOperatorSelected"/>

            <multiselect
                :options="item.field.values" 
                v-model="item.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                class="w-50"
                :multiple="true"
            />
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'text'">
            <multiselect
                :options="textOperatorValues" 
                v-model="item.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="w-25"
                @input="onTextOperatorSelected"/>

            <b-form-input class="form-control w-50 me-2" v-model="item.term">
            </b-form-input>
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'number'">
            <multiselect
              :options="numberOperatorValues" 
              v-model="item.operator"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="w-25"
              @input="onNumberOperatorSelected"/>
            <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="query.term" ></b-form-input>
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'product_category'">
              <multiselect
                :options="listOperatorValues" 
                v-model="item.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="w-25"
                @input="onListOperatorSelected"/>

              <multiselect
                :options="categories" 
                v-model="item.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                class="w-50"
                :multiple="true"
            />
          </div>
          <div class="col-sm-8 row" v-if="item.field && item.field.type== 'product_price'">
            <div class="d-inline-flex align-items-baseline">
              <div class="w-50 me-2">
                <div>
                  <multiselect
                    :options="operatorsPrice || []" 
                    v-model="item.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control w-100 ms-0"
                    @input="onOperatorSelected(item)"
                    />
                </div>
              </div>
              $ 
              <b-form-input
                type="number"
                v-model="item.term"
                class="w-25 ">
              </b-form-input>
            </div>
            
          </div>
          <div v-if="item.field && item.field.type == 'date'" class="col-sm-8 row" >
            <multiselect
              :options="dateOperatorsValues" 
              v-model="item.operator"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control w-25 me-3"
              />
              <b-form-input type="date" class="form-control w-50" v-model="item.term" ></b-form-input>
          </div>
        <div class="col-sm-1 mx-auto interact pt-2 text-end" v-if="item.field" v-on:click="onRemoveCondition(index)">
          <i class="bx bx-trash font-size-18"></i>
        </div>
    </div>
  </div>
</template>

<style>
.interact {
  cursor: pointer;
}
</style>