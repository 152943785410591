
import PropertyService from '../../services/properties'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createProperty ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      PropertyService.createProperty(payload).then(response => { 
        if (response.data && response.data.attribute?._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating group'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getProperties ({ commit },params) {

    return new Promise((resolve, reject) => { 
      PropertyService.getProperties(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  deleteProperty({ commit }, id) {

    return new Promise((resolve, reject) => { 
      PropertyService.deleteProperty(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateProperty ({ commit }, property) {

    return new Promise((resolve, reject) => { 
      PropertyService.updateProperty(property).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}