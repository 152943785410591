<script>

import {
	broadcastMethods
} from "@/state/helpers";


export default {
  data() {
		return {
      field: ''
    }
  },
  props:{
    channel : String,
    message: Object
  },
  emits:['onTestSent'],
  components:{
    
  },
  mounted(){
    
  },
  
  methods:{
    ...broadcastMethods,

    channelText(){
      if(this.channel == 'email'){
        return 'Email'
      }else if (this.channel == 'app_push'){
        return 'FCM Token'
      }else if (this.channel == 'whatsapp' || this.channel == 'sms' ){
        return this.$t('broadcasts.test_message_phone')
      }
      return ''
    },
    onTestMessageClicked(){
      const loader = this.$loading.show();
      
      const payload = {
        type: this.channel,
        variant: {templateValue: this.message},
      }

      if(this.channel == 'app_push' || this.channel == 'web_push'){
        payload.customer = { _notificationSettings: [
          {token: this.field}
        ]}
      }else if(this.channel == 'email'){
        payload.customer = { 
          email: this.field
        }
      }else if(this.channel == 'sms'){
        payload.customer = {
          phone : this.field
        }
      }
      
      this.testMessage(payload).then(()=>{
        this.$emit('onTestSent')
        this.$notify({ type: 'success', text: this.$t("broadcasts.test_message_success"),title:  this.$t("broadcasts.test_message") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("broadcasts.test_message_error"),title:  this.$t("broadcasts.test_message") });
      }).finally(()=>{
        loader.hide();
      })
    },
    
  },
  watch:{
    
  }
}
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>{{ channelText() }}</label>
        <b-form-input v-model="field"></b-form-input>
      </div>
    </div>
    <button class="btn btn-primary mt-3" style="margin: 0 auto;display: block;" @click="onTestMessageClicked">{{$t('broadcasts.test_message_send')}}</button> 
  </div>
  
</template>

<style scoped>

</style>
