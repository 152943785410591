import ScenarioService from '../../services/scenarios'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createScenario ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      ScenarioService.createScenario(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating scenario'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getScenarios ({ commit },params) {

    return new Promise((resolve, reject) => { 
      ScenarioService.getScenarios(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getScenario ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ScenarioService.getScenario(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteScenario ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      ScenarioService.deleteScenario(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateScenario({ commit }, scenario) {

    return new Promise((resolve, reject) => { 
      ScenarioService.updateScenario(scenario).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}