
import UserService from '../../services/users'
import CommonService from '../../services/common'

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
    },
}


export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
      return !!state.currentUser
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createUser ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      UserService.createUser(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating User'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getUsers ({ commit },params) {

    return new Promise((resolve, reject) => { 
      UserService.getUsers(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getUser ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      UserService.getUser(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteUser ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      UserService.deleteUser(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateUser ({ commit }, user) {

    return new Promise((resolve, reject) => { 
      UserService.updateUser(user).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  uploadPicture({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
  })

  },
  // eslint-disable-next-line no-unused-vars
  updateUserProfile ({ commit }, user) {

    return new Promise((resolve, reject) => { 
      UserService.updateUserProfile(user).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCompany ({ commit }, id) {
    return new Promise((resolve, reject) => { 
        UserService.getCompany(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateCompany ({ commit }, company) {
    return new Promise((resolve, reject) => { 
        UserService.updateCompany(company).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}