const predefinedInteractions = 
  [
    {
      name: 'View Item',
      key: 'view',
      default:true,
      parameters:[
        {id: 'item', type: 'text'},
        {id: 'date', type: 'date'},
      ]
    },
    {
      name: 'Add to cart',
      key: 'cart',
      default:true,
      parameters:[
        {id: 'item', type: 'text'},
        {id: 'date', type: 'date'},
        {id: 'price', type: 'number'},
        {id: 'quantity', type: 'number'},
      ]
    },
    {
      name: 'Add to favorite',
      key: 'like',
      default:true,
      parameters:[
        {id: 'item', type: 'text'},
        {id: 'date', type: 'date'},
        {id: 'quantity', type: 'number'},
      ]
    },
    {
      name: 'Purchase',
      key: 'purchase',
      default:true,
      revenue: 'amount',
      parameters:[
        {id: 'item', type: 'text'},
        {id: 'date', type: 'date'},
        {id: 'transactionId', type: 'text'},
        {id: 'quantity', type: 'number'},
        {id: 'price', type: 'number'},
        {id: 'amount', type: 'number'},
      ]
    },
  ]

  export {predefinedInteractions}

import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createInteraction = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/custom-interactions`,
    payload,
    config
  )
}

const _getInteractions = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/custom-interactions?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/custom-interactions`


  return Request.get(
    url,
    config
  )
}

const _deleteInteraction = interactionId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/custom-interactions/${interactionId}`, config)
}

const _updateInteraction = (interaction) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/custom-interactions/${interaction._id}`, interaction, config)
}

export default {
  updateInteraction: _updateInteraction,
  deleteInteraction : _deleteInteraction, 
  getInteractions : _getInteractions,
  createInteraction : _createInteraction,
}
