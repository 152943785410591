import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createVideo = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/videos`,
    payload,
    config
  )
}

const _getVideos = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/videos?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/videos`


  return Request.get(
    url,
    config
  )
}

const _deleteVideo = videoId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/videos/${videoId}`, config)
}

const _getVideo = videoId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/videos/${videoId}?populate[0][path]=campaign&populate[0][select]=_id name&populate[1][path]=videos`, config)
}
const _updateVideo = (video) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/videos/${video._id}`, video, config)
}


export default {
  updateVideo: _updateVideo,
  deleteVideo : _deleteVideo, 
  getVideo : _getVideo, 
  getVideos : _getVideos,
  createVideo : _createVideo,
}
