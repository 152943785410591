import SearchStatsService from '../../services/searchStats'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {},

    // eslint-disable-next-line no-unused-vars
    getLastSyncDate({ commit }) {
    return new Promise((resolve, reject) => { 
        SearchStatsService.getLastSyncDate().then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

    // eslint-disable-next-line no-unused-vars
    getTotalItemsCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalItemsCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalUsersCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalUsersCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    

    // eslint-disable-next-line no-unused-vars
    getTotalSearchesCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalSearchesCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalSearchesWithResultsCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalSearchesWithResultsCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getSearchClusters({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchClusters(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getSearchesByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchesByDay(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getTotalSearchesWithClickCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalSearchesWithClickCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getTotalSearchesAddedToCartCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getTotalSearchesAddedToCartCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getSearchesConversionsByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchesConversionsByDay(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getSearchesList({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchesList(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getResultsBySearch({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getResultsBySearch(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchIndicators({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchIndicators(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchCustomerCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchCustomerCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchCustomerCTR({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchCustomerCTR(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchCustomerCR({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchCustomerCR(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchTopProducts({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchTopProducts(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchTopQueries({ commit }, params) {
        return new Promise((resolve, reject) => { 
            SearchStatsService.getSearchTopQueries(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
}