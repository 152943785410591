import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createGroup = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/groups`,
    payload,
    config
  )
}

const _getGroups = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/groups?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/groups`


  return Request.get(
    url,
    config
  )
}

const _deleteGroup = groupId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/groups/${groupId}`, config)
}

const _getGroup = groupId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/groups/${groupId}`, config)
}
const _updateGroup = (group) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/groups/${group._id}`, group, config)
}

export default {
  updateGroup: _updateGroup,
  deleteGroup : _deleteGroup, 
  getGroup : _getGroup, 
  getGroups : _getGroups,
  createGroup : _createGroup,
}
