
import LiveShoppingService from '../../services/liveshoppingStats'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {},

    // eslint-disable-next-line no-unused-vars
    getEventsGeneralStats({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getEventsGeneralStats(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getEventsGeneralStatsLastSyncDate({ commit }) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getEventsGeneralStatsLastSyncDate().then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getEventsList({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getEventsList(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalAmountSold({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getTotalAmountSold(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalAmountSoldByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getTotalAmountSoldByDay(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getEventsByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getEventsByDay(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getAllEventsByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            LiveShoppingService.getAllEventsByDay(params).then(response=> {
                resolve(response.data)
            })
            .catch(error=>{ reject(error) })
        });
    },
}