<script>

import WaitConditionRule from "@/components/journeys/configs/wait-condition-rule"
import EmptyList from '@/components/widgets/empty_list'

import {
	propertyMethods,
  customerMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
    WaitConditionRule,
    EmptyList
  },
	data() {
		return {
			submitted:false,
      config: {
        type: 'wait_attribute',
        value: {
          type: "or",
          children: []
        }
      },
      name:'',
      aux_config:{
        id: "wait_attribute",
        label : this.$t("journeys.journey_workflow_components_wait_property_change")
      },
      empty_config:{
        "title" : this.$t("journeys.journey_workflow_components_wait_property_empty"),
        "subtitle" : this.$t("journeys.journey_workflow_components_wait_property_empty_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes"
      },
      properties_crm: [],
    };
	},
	methods: {
    ...customerMethods,
    ...propertyMethods,

		getWaitConditionConfig(){
      this.config.name = this.name;
      return this.config;
    },

    setWaitConditionConfig(config){
      this.config = config || this.config
      this.name = this.config.name;
    },
    onRemoveCondition(index){
      this.config?.value?.children?.splice(index, 1);
    },

    onCreateConditionClicked(){
      this.config.value.children.push({
        field: null,
        operator: null,
        term: null
      })
    },
    async addFixedCustomerProperties(){
      
      const fixed = await this.getCustomerFixedProperties();
      this.properties_crm = [...fixed, ...this.properties_crm];
    },
    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_crm = properties.filter(p=> p.entity == 'customer');
        
        this.addFixedCustomerProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
	},

	mounted() {
		this.loadProperties();
	},
	props: {
		
	},
	emits: [],
  watch:{
    config: {
      inmmediate: true,
      handler(newVal){
        // eslint-disable-next-line no-empty
        if(newVal){
          
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="row mb-4">
      <div class="col-3 mb-3">
        <label>{{ $t('journeys.journey_workflow_components_wait_property_name')  }}</label>
        <input class="form-control" v-model="name"/>
      </div> <br>
    </div>
    <div class="row">
      <div v-if="config.value.children.length>0" class="col-12">
        <label class="mb-0">{{ $t('journeys.journey_workflow_components_wait_property_wait')  }}</label>
        <p class="small">{{ $t('journeys.journey_workflow_components_wait_property_wait_subtitle')  }}</p>
        <div v-for="(item, index) in config.value.children" :key="index" class="pb-0 col">
          <WaitConditionRule :node="item" :index="index" @removeCondition="onRemoveCondition" :properties_crm="properties_crm" ></WaitConditionRule>
        </div>
        <button type="button" v-on:click="onCreateConditionClicked" class="btn btn-sm btn-primary me-2 mt-3 mb-3">{{ $t('audiences.audience_conditions_add_condition')  }}</button>
      </div>
      <EmptyList :config="empty_config" v-if="config.value.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-3"/>
    </div>
    <div class="row" v-if="aux_config.id == 'wait_interaction'">
      <div v-if="config.value.children.length>0" class="col-12">
        <label>{{ $t('journeys.journey_workflow_components_wait_property_wait_condition')  }}</label>
        <div class="row mb-2">
          <div class="col-sm-10">
            <div class="btn-group-horizontal pt-2" role="group">
                <button class="btn btn-sm me-1" :class="query.type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'and'">
                    {{ $t("audiences.audience_conditions_and") }}
                </button>
                <button class="btn btn-sm" :class="query.type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'or'">
                  {{ $t("audiences.audience_conditions_or") }}
                </button>
            </div>
          </div>
          <div class="col-sm-2 pt-2 flex-end interact" v-on:click="onAddCondition">
            <i class="bx bx-plus font-size-18"></i>{{$t("audiences.audience_condition")}}
          </div>
        </div>
        <div v-for="(item, index) in config.value.children" :key="index" class="pb-0 col">
          <WaitConditionInteractionRule :node="item" :index="index" @removeCondition="onRemoveCondition" ></WaitConditionInteractionRule>
        </div>
        <button type="button" v-on:click="onCreateConditionClicked" class="btn btn-sm btn-primary me-2 mt-3 mb-3">{{ $t('audiences.audience_conditions_add_condition')  }}</button>
      </div>
      <EmptyList :config="empty_config_interaction" v-if="config.value.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-3"/>
    </div>
      
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>