<script>

import ConditionRule from "./condition-rule.vue"
import EmptyList from '@/components/widgets/empty_list'

import {
	audienceMethods,
  propertyMethods,
  projectMethods,
  customerMethods,
  interactionMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		ConditionRule,
    EmptyList
	},
	data() {
		return {
			submitted:false,
      config: {
        value: {
          type: "and",
          children: []
        }
      },
      affinity_Types: [],
			audiences:[],
      categories:[],
      properties_products:[],
      properties_crm: [],
      rfm_segments:[],
      customer_fields:[],
      custom_interactions:[],
      name: '',
      empty_config:{
        "title" : this.$t("personalizations.experience_targeting_empty"),
        "subtitle" : this.$t("journeys.journey_workflow_components_condition_simple_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes"
      },
      
		};
	},
	methods: {
		...audienceMethods,
    ...propertyMethods,
    ...projectMethods,
    ...customerMethods,
    ...interactionMethods,

    getConditionSimpleConfig(){
      this.config.value.name = this.name;
      return this.config;
    },

    setConditionSimpleConfig(config){
      this.config = config || this.config;
      this.name = this.config.value.name || '';
    },

    async addFixedCustomerProperties(){
      
      const fixed = await this.getCustomerFixedProperties();
      this.properties_crm = [...fixed, ...this.properties_crm];
    },

    addFixedProperties(){
      this.properties_products.push({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
			this.properties_products.push({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });

      this.addFixedCustomerProperties();
    },

    loadCategories(){
      const params = {
				q : `project=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},

    loadAudiences(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getAudiences(params).then((audiences)=>{
        this.audiences = audiences.data || [];
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.properties_crm = properties.filter(p=> p.entity == 'customer');
        this.affinity_Types = this.properties_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    loadCustomInteractions(){
			const params = {
        q : `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res)=>{
        if(res.data){
          this.custom_interactions = res.data;  
        }
      })
		},
    loadRFM(){
      
    
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      
      this.getRFMSegments(params).then((rfm_segments)=>{
        this.rfm_segments = rfm_segments;
      }).catch(()=>{

      }).finally(()=>{
        
      })
    },
    onRemoveCondition(index){
      this.config?.value?.children?.splice(index, 1);
    },

    onCreateConditionClicked(){
      this.config.value.children.push({
            field: null,
            operator: null,
            term: null
          })
    }
	},

	mounted() {
		this.loadAudiences();
    this.loadCategories();
    this.loadProperties();
    this.loadRFM();
    this.loadCustomInteractions();
	},
	props: {
		
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col-3 mb-3">
        <label>{{ $t("journeys.journey_workflow_components_condition_simple_name") }}</label>
        <input class="form-control" v-model="name"/>
      </div> <br>
      <div v-if="config.value.children.length> 0" class="col-12">
        <label class="mb-0">{{ $t("journeys.journey_workflow_components_condition_simple_condition") }}</label>
        <div class="btn-group-horizontal pt-1 pb-2" role="group">
          <button class="btn btn-sm me-2" :class="config.value.type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="config.value.type= 'and'">
            {{ $t("audiences.audience_conditions_and") }}
          </button>
            <button class="btn btn-sm" :class="config.value.type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="config.value.type= 'or'">
              {{ $t("audiences.audience_conditions_or") }}
            </button>
        </div>
        <div v-for="(item, index) in config.value.children" :key="index" class="pb-0 col">
          <ConditionRule :node="item" :index="index" @removeCondition="onRemoveCondition" :affinity_Types="affinity_Types" :audiences= "audiences" :categories="categories" class="mb-2" :rfm_segments="rfm_segments" :properties_crm="properties_crm" :customer_fields="customer_fields" :custom_interactions="custom_interactions" ></ConditionRule>
        </div>
        <button type="button" v-on:click="onCreateConditionClicked" class="btn btn-sm btn-primary me-2 mt-3 mb-3">{{ $t('audiences.audience_conditions_add_condition')  }}</button>
      </div>
      <EmptyList :config="empty_config" v-if="config.value.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-3"/>
      
    </div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>