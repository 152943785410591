<script>
import WaitConditionInteractionRule from "@/components/journeys/configs/wait-condition-interaction-rule"
import EmptyList from '@/components/widgets/empty_list'

import {
	propertyMethods,
  customerMethods,
  projectMethods,
  interactionMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
    EmptyList,
    WaitConditionInteractionRule
  },
	data() {
		return {
			submitted:false,
      config: {
        value: {
          type: "or",
          children: []
        },
        options:[
        {
          label: this.$t("audiences.audience_conditions_fields_interaction_view"),
          value: "interaction_view",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t("audiences.audience_conditions_fields_interaction_cart"),
          value: "interaction_cart",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t("audiences.audience_conditions_fields_interaction_rate"),
          value: "interaction_rate",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t("audiences.audience_conditions_fields_interaction_like"),
          value: "interaction_like",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t("audiences.audience_conditions_fields_interaction_purchase"),
          value: "interaction_purchase",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        }
      ]
      },
      name:'',
      empty_config_interaction:{
        "title" : this.$t("journeys.journey_workflow_components_wait_interaction_empty"),
        "subtitle" : this.$t("journeys.journey_workflow_components_wait_interaction_empty_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes"
      },
      aux_node:{},
      properties_products:[],
      categories:[],
      custom_interactions:[]
    };
	},
	methods: {
    ...customerMethods,
    ...propertyMethods,
    ...projectMethods,
    ...interactionMethods,

    loadCustomInteractions(){
			const params = {
        q : `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res)=>{
        if(res.data && res.data.length>0){
          this.custom_interactions = res.data
        }
      })
		},

		getWaitConditionConfig(){
      this.config.name = this.name
      return this.config;
    },

    setWaitConditionConfig(config){
      this.config = config || this.config
      this.name = this.config.name;
    },
    onConditionTypeSelected(type, isUpdate=false){
      if(!isUpdate){
        this.config.type = type.id;
      }
    },
    onRemoveCondition(index){
      this.config?.value?.children?.splice(index, 1);
    },

    onCreateConditionClicked(){
      this.config.value.children.push({
        field: null,
        operator: null,
        term:{
              type:"and",
              children: [
                {
                  field: null,
                  operator: null,
                  term: null,
                }
              ]
          }
      })
    },
    addFixedProperties(){
      this.properties_products.push({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
			this.properties_products.push({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });
    },

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.entity == 'product');
        
        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    loadCategories(){
      const params = {
				q : `project=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},
  },

	mounted() {
		this.loadProperties();
    this.loadCustomInteractions();
	},
	props: {
		
	},
	emits: [],
  watch:{
    config: {
      inmmediate: true,
      handler(newVal){
        // eslint-disable-next-line no-empty
        if(newVal){
          
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="row mb-4">
      <div class="col-3 mb-3">
        <label>{{ $t("journeys.journey_workflow_components_wait_interaction_name") }}</label>
        <input class="form-control" v-model="name"/>
      </div> <br>
    </div>
    <div class="row">
      <div v-if="config.value.children.length>0" class="col-12">
        <label class="mb-0">{{ $t("journeys.journey_workflow_components_wait_interaction_wait") }}</label>
        <p class="small">{{ $t("journeys.journey_workflow_components_wait_interaction_wait_subtitle") }}</p>
        <div class="row mb-2">
          <div v-for="(item, index) in config.value.children" :key="index" class="pb-2 col-12">
              <WaitConditionInteractionRule :node="item" @removeCondition="onRemoveCondition" :properties_products="properties_products" :categories="categories" :custom_interactions="custom_interactions" /> 
          </div>
        </div>
        <button type="button" v-on:click="onCreateConditionClicked" class="btn btn-sm btn-primary me-2 mt-3 mb-3">{{ $t('audiences.audience_conditions_add_condition')  }}</button>
      </div>
    </div>
    <EmptyList :config="empty_config_interaction" v-if="config.value.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-3"/>
  </div>
  
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>