import JourneyService from '../../services/journeys'
import i18n from "../../i18n";
export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {

  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createJourney ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      JourneyService.createJourney(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating campaign'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getJourneys ({ commit },params) {

    return new Promise((resolve, reject) => { 
      JourneyService.getJourneys(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getJourney ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      JourneyService.getJourney(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteJourney ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      JourneyService.deleteJourney(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateJourney ({ commit }, journey) {

    return new Promise((resolve, reject) => { 
      JourneyService.updateJourney(journey).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getIsBlockValid ({ commit }, block) {

    switch(block.type){
      case 'trigger': 
        if(block.config?.type?.id == 'interaction'){
          return block.config?.value?.interaction || false;
        }else{
          return block.config?.type || false;
        }
      case 'delay_time':
        return  block.config?.value?.value && block.config?.value?.time;
      case 'flow_control_condition_simple':
        return block.config?.value?.children?.length > 0;
      case 'notification_email':
      case 'notification_sms':
      case 'notification_app_push':
        return block.config?.variants?.length > 0;
      case 'notification_web_push':
        return block.config?.variants?.length > 0;
      case 'flow_control_ab_testing':
        return block.config?.variants?.length > 0;
      case 'delay_condition':
      case 'delay_condition_interaction':
        return block.config?.value?.children?.length > 0;
      case 'flow_control_condition_complex':
        return block.config?.values?.length > 0;
      case 'actions_api':
        return block.config?.value?.url; 
      case 'actions_script':
          return block.config?.value?.script != '';    
      case 'flow_control_reachability':
        return block.config?.values.length > 0 && block.config?.values[0].channel ;    
    }

    return false
    
  },

  // eslint-disable-next-line no-unused-vars
  getBlockText ({ commit }, block) {

    switch(block.type){
      case 'trigger': 
        return block.config?.value?.interaction?.label || block.config?.type?.label || ''
      case 'delay_time':
        return  block.config?.value?.value ? `${block.config?.value?.value} ${block.config?.value?.time}` : ''
      case 'flow_control_condition_simple':
        return block.config?.value?.name ? block.config?.value?.name : block.config?.value?.children?.length > 0 ? block.config?.value?.children?.length == 1 ? `${block.config?.value?.children?.length} ${i18n.t("journeys.journey_workflow_components_block_text_condition")}` : `${block.config?.value?.children?.length} ${i18n.t("journeys.journey_workflow_components_block_text_conditions")}` : ''
      case 'notification_email':
      case 'notification_sms':
      case 'notification_app_push':
        return block.config?.variants ? block.config?.variants?.length == 1 ?  `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variant")}` : `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variants")}` : ''
      case 'notification_web_push':
          return block.config?.variants ? block.config?.variants?.length == 1 ?  `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variant")}` : `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variants")}` : ''
      case 'flow_control_ab_testing':
        return block.config?.variants ? block.config?.variants?.length == 1 ?  `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variant")}` : `${block.config?.variants?.length} ${i18n.t("journeys.journey_workflow_components_block_text_variants")}` : ''
      case 'delay_condition':
      case 'delay_condition_interaction':
        return  block.config?.name ? block.config?.name : block.config?.value?.children?.length > 0 ? block.config?.value?.children?.length == 1 ? `${block.config?.value?.children?.length} ${i18n.t("journeys.journey_workflow_components_block_text_condition")}` : `${block.config?.value?.children?.length} ${i18n.t("journeys.journey_workflow_components_block_text_conditions")}` : ''
      case 'flow_control_condition_complex':
          return block.config?.values?.length > 0 ? block.config?.values?.length == 1 ? `${block.config?.values?.length} ${i18n.t("journeys.journey_workflow_components_block_text_path")}` : `${block.config?.values?.length} ${i18n.t("journeys.journey_workflow_components_block_text_paths")}` : ''
      case 'actions_api':
        return block.config?.value?.name ? block.config?.value?.name : `API ${block.config?.value?.method || ''} `  ;
      case 'actions_script':
        return block.config?.value?.name ? block.config?.value?.name : 'Script'  ;
        case 'flow_control_reachability':
          return block.config?.values?.length > 0 ? block.config?.values?.length == 1 ? `${block.config?.values?.length} ${i18n.t("journeys.journey_workflow_components_block_text_path")}` : `${block.config?.values?.length} ${i18n.t("journeys.journey_workflow_components_block_text_paths")}` : ''

    }

    return ''
  },
  // eslint-disable-next-line no-unused-vars
  getJourneysVars ({ commit }, params) {
    const vars = [];
    
    vars.push({
      label : i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data"),
      id:"customer_data",
      options:[
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_name"), column_name : "gs_customer_name"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_email"), column_name : "gs_customer_email"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_phone"), column_name : "gs_customer_phone"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_last_purchase_date"), column_name : "gs_customer_last_purchase_date"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_last_purchase_amount"), column_name : "gs_customer_last_purchase_amount"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_last_view_date"), column_name : "gs_customer_last_visit_date"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_customer_Data_last_favorite_date"), column_name : "gs_customer_last_like_date"},
      ]
    })
    
    vars.push({
      label : i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart"),
      id:"customer_cart",
      options:[
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_items_count"), column_name : "gs_customer_cart_items_count"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_total_amount"), column_name : "gs_customer_cart_items_amount"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_last_product_name"), column_name : "gs_customer_cart_product_name"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_last_product_price"), column_name : "gs_customer_cart_product_price"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_last_product_image"), column_name : "gs_customer_cart_product_image"}
      ]
    })

    vars.push({
      label : i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase"),
      id:"customer_purchase",
      options:[
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_date"), column_name : "gs_customer_last_purchase_product_date"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_amount"), column_name : "gs_customer_last_purchase_amount"},

        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_product_name"), column_name : "gs_customer_last_purchase_name"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_product_price"), column_name : "gs_customer_last_purchase_price"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_product_image"), column_name : "gs_customer_last_purchase_image"}
      ]
    })

    vars.push({
      label : i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite"),
      id:"customer_like",
      options:[ 
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite_date"), column_name : "gs_customer_last_like_product_date"},
        
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite_product_name"), column_name : "gs_customer_last_like_name"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite_product_price"), column_name : "gs_customer_last_like_price"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite_product_image"), column_name : "gs_customer_last_like_image"}
      ]
    })

    vars.push({
      label : i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view"),
      id:"customer_view",
      options:[
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view_date"), column_name : "gs_customer_last_view_product_date"},
        
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view_product_name"), column_name : "gs_customer_last_view_name"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view_product_price"), column_name : "gs_customer_last_view_price"},
        {label: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view_product_image"), column_name : "gs_customer_last_view_image"}
      ]
    })

    return vars;
  },

}