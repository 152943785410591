import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _getCustomers = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/customers?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/customers`


  return Request.get(
    url,
    config
  )
}

const _getCustomersCountries = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/customers/countries?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/customers/countries`


  return Request.get(
    url,
    config
  )
}

const _getRFMSegments = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/customers/segments-rfm?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/customers/segments-rfm`


  return Request.get(
    url,
    config
  )
}


export default {
  getCustomers : _getCustomers,
  getCustomersCountries: _getCustomersCountries,
  getRFMSegments: _getRFMSegments
}