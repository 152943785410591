
import AudienceService from '../../services/audiences'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createAudience ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
        AudienceService.createAudience(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating campaign'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAudiences ({ commit },params) {

    return new Promise((resolve, reject) => { 
        AudienceService.getAudiences(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAudience ({ commit }, id) {

    return new Promise((resolve, reject) => { 
        AudienceService.getAudience(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteAudience ({ commit }, id) {
		return new Promise((resolve, reject) => { 
      AudienceService.deleteAudience(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateAudience ({ commit }, audience) {
		return new Promise((resolve, reject) => { 
      AudienceService.updateAudience(audience).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getContactLists ({ commit },params) {

    return new Promise((resolve, reject) => { 
        AudienceService.getContactLists(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  createContactList ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
        AudienceService.createContactList(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating contact list'})
        }
      })
      .catch(error => { reject(error) })
    });
  },
  // eslint-disable-next-line no-unused-vars
  getContactListTemplate ({ commit }) {

    return new Promise((resolve, reject) => { 
        AudienceService.getContactListTemplate().then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteContactList ({ commit }, id) {
		return new Promise((resolve, reject) => { 
      AudienceService.deleteContactList(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  updateContactList ({ commit }, list) {
		return new Promise((resolve, reject) => { 
      AudienceService.updateContactList(list).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getContactList ({ commit }, id) {

    return new Promise((resolve, reject) => { 
        AudienceService.getContactList(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  getAudienceExploreStatus ({ commit }, id) {

    return new Promise((resolve, reject) => { 
        AudienceService.getAudienceExploreStatus(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  getAudienceExplore ({ commit }, body) {

    return new Promise((resolve, reject) => { 
        AudienceService.getAudienceExplore(body).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
}