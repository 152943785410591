import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createEvent = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/events`,
    payload,
    config
  )
}

const _getEvents = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/events?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/events`


  return Request.get(
    url,
    config
  )
}

const _deleteEvent = eventId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/events/${eventId}`, config)
}

const _getEvent = eventId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/events/${eventId}`, config)
}
const _updateEvent = (event) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/events/${event._id}`, event, config)
}

const _getHostsAvailable = (eventId) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/events/hosts-available?event=${eventId}`,config)
}

const _createShowEvent = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/events/show`,
    payload,
    config
  )
}

export default {
  updateEvent: _updateEvent,
  deleteEvent : _deleteEvent, 
  getEvent : _getEvent, 
  getEvents : _getEvents,
  createEvent : _createEvent,
  createShowEvent : _createShowEvent,
  getHostsAvailable: _getHostsAvailable
}
