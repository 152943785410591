import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createCustomStrategy = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/custom-strategies`,
    payload,
    config
  )
}

const _getCustomStrategies = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/custom-strategies?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/custom-strategies`


  return Request.get(
    url,
    config
  )
}

const _deleteCustomStrategy = strategyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/custom-strategies/${strategyId}`, config)
}

const _getCustomStrategy = strategyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/custom-strategies/${strategyId}`, config)
}


const _updateCustomStrategy = (strategy) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/custom-strategies/${strategy._id}`, strategy, config)
}

export default {
  updateCustomStrategy: _updateCustomStrategy,
  deleteCustomStrategy : _deleteCustomStrategy, 
  getCustomStrategies : _getCustomStrategies,
  createCustomStrategy : _createCustomStrategy,
  getCustomStrategy: _getCustomStrategy
}
