<script>

import SimpleBar  from "simplebar-vue";
import _debounce from 'lodash.debounce';

import {
	journeyMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
    SimpleBar
	},
	data() {
		return {
			submitted:false,
      variables:[]
		};
	},
  computed: {
     
  },
  created(){
    this.debounceVars = _debounce(this.searchVars.bind(this), 1000);
  },
  async mounted() {
      this.loadJourneyVariables()
  },

  beforeDestroy() {
    
  },
	methods: {
    ...journeyMethods,

    async loadJourneyVariables(){
      this.variables = await this.getJourneysVars()
    },
    searchVars(query){
      // eslint-disable-next-line no-console
      console.log(query);
    },

    onVariableSelected(variable){
      this.$emit('variableSelected', variable);
      this.$refs.varsDropDown.hide()
    }
  },

	props: {
		
	},
	emits: ['variableSelected'],
  
};
</script>

<template>
  <div>
    <b-dropdown variant="black" menu-class="dropdown-menu-lg pt-0" dropright ref="varsDropDown">
      <template v-slot:button-content>
        <div class="btn btn-sm btn-link">+ {{$t('journeys.journey_workflow_components_channel_variants_dynamic_vars')}}</div>
      </template>
      <div class="p-2 py-0">
        <div class="row align-items-center">
          <div class="col">
            <div class="search-box me-2 mb-2 d-inline-block w-100" >
                <div class="position-relative">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        :placeholder="$t('common.search')"
                        @input="debounceVars($event.target.value)"
                    />
                    <i class="bx bx-search-alt search-icon2"></i>
                </div>
            </div>
          </div>
          
        </div>
      </div>
      <b-dropdown-divider class="mt-0 mb-0"></b-dropdown-divider>
      <SimpleBar style="max-height: 230px;">
        <div class="pb-2" v-for="(v,index) in variables" :key="v.label">
          <div v-b-toggle="'accordion-' + index" class="var-header">{{ v.label }} <i class="mdi mdi-chevron-down" style="position: absolute; right: 10px;"/></div>
          <b-collapse :id="`accordion-${index}`" accordion="my-accordion" role="tabpanel">
            <ul>
              <li class="dropdown-item py-1 ps-0 interact" v-for=" o in v.options" :key="o.column_name" v-on:click="onVariableSelected(o)"> {{ o.label }}</li>
            </ul>
          </b-collapse>
        </div>
      </SimpleBar>
    </b-dropdown>
  </div>
</template>

<style>
.interact{
  cursor: pointer;
}
.search-icon2 {
    font-size: 12px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 29px !important;
}

.var-header{
  padding: 3px;
  background-color: #00000008;
  padding-left: 10px;
}

.dropdown-toggle{
  padding-top: 0px !important;
}
</style>