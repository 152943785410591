<script>

import Multiselect from "vue-multiselect";

import {
	broadcastMethods
} from "@/state/helpers";


export default {
  data() {
		return {
      languages: [
        {id: 'Spanish', label: this.$t('common.language_spanish')},
        {id: 'English', label: this.$t('common.language_english')}
      ],
      lang: {id: 'Spanish', label: this.$t('common.language_spanish')},
      useEmojis: true,
      showOptions:false,
      selectedIndex: -1,
      options: [],
      useCase:''
    }
  },
  props:{
    channel : String,
    field: String,
    limit: Number
  },
  emits:['onTextSelected'],
  components:{
    Multiselect
  },
  mounted(){
    
  },
  methods:{
    ...broadcastMethods,

    onGenerateTextClicked(){
      const loader = this.$loading.show();

      const payload = {
        "useCase": this.useCase,
        "limit": this.limit || 50,
        "useEmojis": this.useEmojis ? "Yes" : "No",
        "channel": this.channel,
        "lang": this.lang.id,
        "format": this.field,
        "options": 5
      }
      this.generateMessage(payload).then((res)=>{
        
        this.options = Array.isArray(res.options?.options) ? res.options.options.map(o => o.title) : [];
        this.showOptions = true;
        this.selectedIndex = -1;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("broadcasts.suggestions_error"),title:  this.$t("broadcasts.suggestions") });
      }).finally(()=>{
        loader.hide();
      })
    },
    onOptionSelected(index){
      this.selectedIndex = index
    },
    onConfirmSuggestion(){
      this.$emit('onTextSelected', this.options[this.selectedIndex]);
      this.showOptions = false;
    }
  },
  watch:{
    
  }
}
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>{{$t('broadcasts.generate_language')}}</label>
        <Multiselect
          :options="languages"
          v-model="lang"
          selectLabel="''"
          deselectLabel=""
          :multiple="false"
          :allowEmpty="false"
          track-by="id" 
          label="label"
          open-direction="bottom"
          :show-labels="false">
        </Multiselect>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label>{{$t('broadcasts.generate_use_case')}}</label>
        <b-form-input v-model="useCase"></b-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <b-form-checkbox
            v-model="useEmojis"
            class="mb-3"
            plain>
            {{$t('broadcasts.generate_use_emojis')}}  
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mt-3" style="margin: 0 auto;display: block;" @click="onGenerateTextClicked">{{$t('broadcasts.generate')}}</button> 
    <b-modal
      v-model="showOptions"
      :title="$t('broadcasts.suggestions')"
      hide-footer>
      <div>
        <div class="border p-3 small interact" v-for="(option, index) in options" :key="index" v-on:click="onOptionSelected(index)" :class="selectedIndex == index ? 'bg-primary bg-gradient text-white' : ''" style="border-radius: 10px;" >
          {{option}}
        </div>
        <div class="text-end">
					<b-button variant="primary" class="w-md mt-3 mb-0" @click="onConfirmSuggestion">{{$t('common.confirm')}}</b-button>
				</div>
      </div>
    </b-modal>
  </div>
  
</template>

<style scoped>

</style>
