<script>

import Draggable from 'vuedraggable'
import Multiselect from "vue-multiselect";

import {
	
} from "@/state/helpers";


export default {
	components: {
    Draggable,
    Multiselect
	},
	data() {
		return {
			submitted:false,
      config: {
        values: [
          {
            name: `Path 1`,
            channel: null
          }
        ]
      },
      name: '',
      empty_config:{
        "title" : this.$t("personalizations.experience_targeting_empty"),
        "subtitle" : this.$t("journeys.journey_workflow_components_condition_complex_condition_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        icon: "fa-cubes"
      },
      drag: false,
      indexSelected:0,
      confirmDelete: false,
      confirmRename: false,
      actionIndex: -1,
      pathName: '',
      channels: [
        {id: "email", label: this.$t('journeys.journey_workflow_components_email')},
        {id: "app_push", label: this.$t('journeys.journey_workflow_components_app_push')},
        {id: "sms", label: this.$t('journeys.journey_workflow_components_sms')},
        {id: "web_push", label: this.$t('journeys.journey_workflow_components_web_push')},
        {id: "whatsapp", label: this.$t('journeys.journey_workflow_components_whatsapp')},
      ]
    };
	},
	methods: {
		getReachabilityConfig(){
      return this.config;
    },

    setReachabilityConfig(config){
      this.config = config || this.config;
    },

    onCreatePathClicked(){
      this.config.values.push({
        name: `Path ${this.config.values.length+ 1}`,
        channel: null
      })
    },
    onRemovePath(index){
      this.actionIndex = index;
      this.confirmDelete = true;
    },
    
    onConfirmRemoveClicked(){
      this.config?.values.splice(this.actionIndex, 1);
      if(this.indexSelected == this.actionIndex)
        this.indexSelected = 0;
      this.confirmDelete = false;
    },
    onChannelSelected(value){
      this.config.values[value].name = this.config.values[value].channel.label;
    }
  },

	mounted() {
		
	},
	props: {
		
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 text-end">
        <div v-on:click="onCreatePathClicked" class="interact d-inline-flex">
          <i class="bx bx-plus font-size-18" ></i> {{$t('journeys.journey_workflow_components_condition_complex_path')}}
        </div>
        
      </div>
      <div v-if="config.values.length> 0">
        <Draggable v-model="config.values" class="d-flex" group="people" @start="drag=true" @end="drag=false">
          <div class="ps-0 mb-3 interact d-inline-flex" v-for="(element,index) in config.values" :key="index" :class="indexSelected == index ? 'selected' : ''" v-on:click="indexSelected = index">
            <i class="mdi mdi-drag-vertical text-end interact me-2"></i>
            <label class="pe-2 mb-0 interact" :class="index==indexSelected ? 'font-weight-bold' : ''">{{element.name}}</label>
            <b-dropdown
              class="card-drop"
              variant="white"
              right
              toggle-class="p-0"
              menu-class="dropdown-menu-end"
              v-if="config.values.length > 1">
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical text-end interact"></i>
              </template>

              <b-dropdown-item @click="onRemovePath(index)">
                <i class="fas fa-trash-alt text-danger me-1"></i>
                {{$t('common.delete')}}
              </b-dropdown-item>
          </b-dropdown> 
          </div>
        </Draggable>
      </div>
      <div>
        <div class="d-inline-flex align-items-baseline">
          <p class="mb-0 mt-3">{{ $t('journeys.journey_workflow_components_reachability_subtitle') }}</p>
        </div>
        <div>
            <multiselect
              :options="channels" 
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control form-control-sm w-50"
              v-model="config.values[indexSelected].channel"
              @input="onChannelSelected(indexSelected)"
              />
          
        </div>
      </div>
    </div>
    <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <p>{{$t('journeys.journey_workflow_components_condition_complex_remove')}}</p>
    </b-modal>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}

.selected{
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #5e40bf;
  border-radius: 0px;
}

.font-weight-bold{
  font-weight: bolder;
}

.dropdown-toggle{
  padding-top: 0px !important;
}
</style>