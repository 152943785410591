import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _addBucket = payload => {

	let token = getUserToken();
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			authorization: `Bearer ${token}`
		}
	}
	
	let formData = new FormData();
	formData.append('file', payload);

	return Request.post(
		`${window.config.VUE_APP_BACKEND}/api/media`,
		formData,
		config
	);
}

const _getNews = params => { 
	const token = getUserToken()
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	
	const url = params? `${window.config.VUE_APP_BACKEND}/api/news?sortObj={"date":-1}&${params}` : `${window.config.VUE_APP_BACKEND}/api/news?sortObj={"date":-1}` 
	return Request.get(
		url,
		config
	)
  }

export default {
	addBucket : _addBucket,
	getNews: _getNews
}
