import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createProperty = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/items/custom-attributes`,
    payload,
    config
  )
}

const _getProperties = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/items/custom-attributes?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/items/custom-attributes`


  return Request.get(
    url,
    config
  )
}

const _deleteProperty = propertyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/items/custom-attributes/${propertyId}`, config)
}

const _updateProperty = (property) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/items/custom-attributes/${property._id}`, property, config)
}

export default {
  updateProperty: _updateProperty,
  deleteProperty : _deleteProperty, 
  getProperties : _getProperties,
  createProperty : _createProperty,
}
