<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>

//import WebBuilder from "@/components/personalizations/webBuilder";
import { required } from "vuelidate/lib/validators";
import EmptyList from "@/components/widgets/empty_list"
import emailConfig from "@/components/journeys/configs/email-config"
import smsConfig from "@/components/journeys/configs/sms-config"
import appPushConfig from "@/components/journeys/configs/app-push-config"
import webPushConfig from "@/components/journeys/configs/web-push-config"

import {
  
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      showVariantTemplate: false,
      showNewTemplate: false,
      html:'',
      variant:{},
      isUpdate:false,
      empty_config:{
        "title" : this.$t('journeys.journey_workflow_components_channel_variants_empty'),
        "subtitle" :this.$t('journeys.journey_workflow_components_channel_variants_empty_subtitle'),
        "buttonText" : this.$t('personalizations.new_variant'),
        icon: "fa-cubes"
      },
      config:{
        variants:[] 
      },
    };
  },
  components: { EmptyList, emailConfig, smsConfig, appPushConfig, webPushConfig},
  validations: {
    template: {
        name: { required},
    }
  },
  mounted() {
    
  },
    methods: {
      
      onChangeStatusClicked(index){

        const status = this.config.variants[index].status == 1 ? 0 : 1;

        const variant = this.config.variants[index];
        variant.status = status;
        
        this.$set(this.config.variants, index, variant);
      },
      
      onNewVariantClicked(){
        this.isUpdate = false;
        this.variant = {},
        this.variant.name = `Variant ${this.config.variants.length + 1}`,
        this.variant.ratio = 0;
        this.variant.status = 1;

        this.showVariantTemplate = true;
        
      },

      onConfirmVariantClicked(){
        
        this.variant.config = this.variant.config || {};
        
        if(this.channel == 'email'){
          if(!this.$refs.emailConfigComponent.validateEmailConfig())
            return;
          this.variant.config.email = this.$refs.emailConfigComponent.getEmailConfig()
        }else if(this.channel == 'app_push'){
          if(!this.$refs.appPushConfigComponent.validatePushNotificationConfig())
            return;
          this.variant.config.app_push = this.$refs.appPushConfigComponent.getAppPushNotificationsConfig()
        }
        else if(this.channel == 'web_push'){
          if(!this.$refs.webPushConfigComponent.validatePushNotificationConfig())
            return;
          this.variant.config.web_push = this.$refs.webPushConfigComponent.getWebPushNotificationsConfig()
        }
        else{
          if(!this.$refs.smsConfigComponent.validateSMSConfig())
            return;
          this.variant.config.sms = this.$refs.smsConfigComponent.getSMSConfig()
        }
        
        if(!this.isUpdate){
          this.config.variants.push(this.variant);
        }
        

        this.showVariantTemplate = false;
      },

      onEditVariantClicked(variant){
        this.isUpdate = true;
        this.variant = variant;
        
        if(!this.variant.config)
          this.variant.config = {};
        this.showVariantTemplate = true; 
        
        const loader = this.$loading.show();
        setTimeout(() => {
          if(this.channel == 'email'){
            this.$refs.emailConfigComponent.setEmailConfig(this.variant.config?.email);
          }else if(this.channel == 'app_push'){
            this.$refs.appPushConfigComponent.setAppPushNotificationsConfig(this.variant.config.app_push);
          }
          else if(this.channel == 'web_push'){
            this.$refs.webPushConfigComponent.setWebPushNotificationsConfig(this.variant.config.web_push);
          }else{
            this.$refs.smsConfigComponent.setSMSConfig(this.variant.config?.sms);
          }
          loader.hide();
        }, 1000);

      },

      onRemoveVariantClicked(variant){
        this.config.variants.splice(variant,1);
      },

      onCloneVariantClicked(variant){
        let v = Object.assign({}, variant);
        v.name = `Variant ${this.config.variants.length + 1}`;
        v.ratio = 0;
        delete v._id;

        this.config.variants.push(v);
      
      },
      setVariantsConfig(config){
        this.config = config || this.config
      },

      getVariantsConfig(){
        return this.config;
      },
      onVariantRationChanged(index){
        
        const variant = this.config.variants[index];
        variant.ratio = this.config.variants[index].ratio;
        
        this.$set(this.config.variants, index, variant);
      
        if(this.config.variants.length == 2) {
          const auxIndex = index == 1 ? 0 : 1 
          this.config.variants[auxIndex].ratio = 100 - this.config.variants[index].ratio;

          const variant = this.config.variants[auxIndex];
          variant.ratio = 100 - this.config.variants[index].ratio;
          
          this.$set(this.config.variants, auxIndex, variant);
        }
    },
    onCancelVariantClicked(){
      this.showVariantTemplate = false
    },
    onTestMessageClicked(){
      if(!this.variant.config)
        this.variant.config = {};

        this.variant.config = this.variant.config || {};
        
        if(this.channel == 'email'){
          if(!this.$refs.emailConfigComponent.validateEmailConfig())
            return;
            this.$refs.emailConfigComponent.showTestChannel();
        }else if(this.channel == 'app_push'){
          if(!this.$refs.appPushConfigComponent.validatePushNotificationConfig())
            return;
            this.$refs.appPushConfigComponent.showTestChannel();
        }else if(this.channel == 'web_push'){
          if(!this.$refs.webPushConfigComponent.validatePushNotificationConfig())
            return;
            this.$refs.webPushConfigComponent.showTestChannel();
        }
        else{
          if(!this.$refs.smsConfigComponent.validateSMSConfig())
            return;
            this.$refs.smsConfigComponent.showTestChannel();
        }
      }
      
  },
  props: {
    variants: Array,
    channel: String,
    type: String,
    affinity_Types: Array,
    personalizationId: String,
    experienceId: String,
    categories: Array,  
  },
	emits:[],
}

 </script>

<template>
  <div class="row">
    <label> {{ $t('journeys.journey_workflow_components_channel_variants_title')}}</label>
    <div class="text-end col">
      <button type="button" v-on:click="onNewVariantClicked" class="btn btn-sm btn-primary mt-3 mb-3" v-if="config.variants.length > 0">+ {{$t('personalizations.new_variant')}}</button>
    </div>
    <div class="row">
      <EmptyList :config="empty_config" v-if="config.variants.length == 0" @onButtonClicked="onNewVariantClicked" class="mt-3"/>
      <table class="table table align-end" v-if="config.variants.length > 0">
        <thead class="table-light">
          <tr>
            <th>{{$t('personalizations.name')}}</th>
            <th></th>
            <th>
              <div class="float-end"> {{$t('personalizations.variant_allocation')}} </div>
            </th>
            <th>
              <div class="float-end">{{$t("personalizations.status")}}</div>
            </th>
            <th ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variant,index) in config.variants" :key="variant.name">
            <td class="align-middle fw-bold text-decoration-underline interact" v-on:click="onEditVariantClicked(variant)">
              {{ variant.name }}
            </td>
            <td>
                <b-form-input v-model="variant.ratio" type="range" :min="0" :max="100" step="1" class="w-100" @input="onVariantRationChanged(index)" />
            </td>
            <td>
              <div class="d-inline-flex align-items-center float-end">
                %  <b-form-input v-model="variant.ratio" type="number" :min="0" :max="100" step="1" class="form-control form-control-sm" @input="onVariantRationChanged(index)"/>
              </div>
            </td>
            <td>
              <div class="float-end">
                <span class="badge font-size-12"
                          :class="{
                          'bg-success': `${variant.status}` == 1,
                          'bg-warning': `${variant.status}` == 0}">
                            {{ variant.status == 1 ? $t('personalizations.variant_status_active') : $t('personalizations.variant_status_incative') }}
                        </span>
              </div> 
            </td>
            <td class="text-end align-middle">
              <i class="fas fa-play text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 0"/>
              <i class="fas fa-pause text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 1"/>
              
              <b-dropdown
                class="card-drop"
                variant="white"
                right
                toggle-class="p-0"
                menu-class="dropdown-menu-end">
                <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>

                <b-dropdown-item @click="onCloneVariantClicked(variant)">
                  <i class="fas fa-clone text-info me-1"></i>
                  {{$t('common.duplicate')}}
                </b-dropdown-item>

                <b-dropdown-item @click="onEditVariantClicked(variant)">
                  <i class="fas fa-edit text-success me-1"></i>
                  {{$t('common.edit')}}
                </b-dropdown-item>

                <b-dropdown-item @click="onRemoveVariantClicked(index)">
                  <i class="fas fa-trash-alt text-danger me-1"></i>
                  {{$t('common.delete')}}
                </b-dropdown-item>
              </b-dropdown>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    
    <b-modal
					v-model="showVariantTemplate"
					id="modal-xl23"
					size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable
          class="variant-email">
          <template #modal-title>
            <input class="form-control" v-model="variant.name" style="font-weight: bold;"/>
          </template>
          <template #modal-footer>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="secondary" @click="onCancelVariantClicked()">
              {{ $t('common.cancel') }}
            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button variant="outline-primary" @click="onTestMessageClicked()">
              {{$t('broadcasts.test_message')}}
            </b-button>
            <b-button variant="primary" @click="onConfirmVariantClicked()">
              {{ $t('common.confirm') }}
            </b-button>
          </template>

          <emailConfig v-if="channel=='email'" :email="variant.config" ref="emailConfigComponent" :variant="variant" ></emailConfig>
          <smsConfig  v-if="channel=='sms'" ref="smsConfigComponent" :variant="variant" ></smsConfig>
          <appPushConfig v-if="channel=='app_push'" ref="appPushConfigComponent" :variant="variant"/>
          <webPushConfig v-if="channel=='web_push'" ref="webPushConfigComponent" :variant="variant"/>
          
      </b-modal>
  </div>
</template>
<style>
.interact{
    cursor: pointer;
}

.vue-simple-drawer{ 
    min-width: 500px !important;
}

.save-template-button{
  position: absolute;
  right: 35px;
  top: 20px;
}

.modal-xl{
  --bs-modal-width: 95% !important;
} 

#modal-xl23 .modal-content{
  overflow: visible !important;
}

#modal-xl23 .modal-body{
  overflow: visible !important;
  overflow-y: scroll !important;
}
</style>

<style scoped>
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow: visible !important;
}

.modal-content{
  overflow: visible !important;
}

</style>
