import Request from './baseRequest'
import i18n from "../i18n";

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createJourney = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/journeys`,
    payload,
    config
  )
}

const _getJourneys = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/journeys?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/journeys`


  return Request.get(
    url,
    config
  )
}

const _deleteJourney = journeyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/journeys/${journeyId}`, config)
}

const _getJourney = journeyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/journeys/${journeyId}`, config)
}

const _updateJourney = (journey) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/journeys/${journey._id}`, journey, config)
}


export default {
  updateJourney: _updateJourney,
  deleteJourney : _deleteJourney, 
  getJourney : _getJourney, 
  getJourneys : _getJourneys,
  createJourney : _createJourney
}


const blocks = [
  {
    group : i18n.t('journeys.journey_workflow_components_groups_notifications'),
    blocks: [
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_email'),
          icon: 'mdi-email-outline'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_email'),
          description: 'Send an email',
          type: 'notification_email',
          icon: 'mdi-email-outline',
          color: '#50A5F1',
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_app_push'),
          icon: 'mdi-cellphone-message'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_app_push'),
          description: 'Send a push notification',
          type: 'notification_app_push',
          icon: 'mdi-cellphone-message',
          color: '#50A5F1'
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_sms'),
          icon: 'mdi-message-outline'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_sms'),
          description: 'Send a sms',
          type: 'notification_sms',
          icon: 'mdi-message-outline',
          color: '#50A5F1'
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_web_push'),
          icon: 'mdi-monitor-star'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_web_push'),
          description: 'Send a web push notification',
          type: 'notification_web_push',
          icon: 'mdi-monitor-star',
          color: '#50A5F1'
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_whatsapp'),
          icon: 'mdi-whatsapp'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_whatsapp'),
          description: 'Send a whatsapp',
          type: 'notification_whatsapp',
          icon: 'mdi-whatsapp',
          color: '#50A5F1'
        },
      },
    ]
  },
  {
    group : i18n.t('journeys.journey_workflow_components_groups_dalays'),
    blocks: [
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_time'),
          icon: 'mdi-calendar-clock'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_time'),
          description: 'Esperar por tiempo',
          type: 'delay_time',
          icon: 'mdi-calendar-clock',
          color: '#F46A6A'
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_property'),
          icon: 'mdi-database-clock-outline'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_property'),
          description: 'Esperar por propiedad',
          type: 'delay_condition',
          icon: 'mdi-database-clock-outline',
          color: '#F46A6A'
        },
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_interaction'),
          icon: 'mdi-routes-clock'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_wait_for_interaction'),
          description: 'Esperar por interacción',
          type: 'delay_condition_interaction',
          icon: 'mdi-routes-clock',
          color: '#F46A6A'
        },
      }
    ]
  },
  {
    group : i18n.t('journeys.journey_workflow_components_groups_flow_control'),
    blocks: [
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_condition_simple'),
          icon: 'mdi-call-split'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_condition_simple'),
          description: 'Condición simple',
          type: 'flow_control_condition_simple',
          icon: 'mdi-call-split',
          color: '#f1b44c'
        }
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_condition_complex'),
          icon: 'mdi-set-split'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_condition_complex'),
          description: 'Condición simple',
          type: 'flow_control_condition_complex',
          icon: 'mdi-set-split',
          color: '#f1b44c'
        }
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_ab_testing'),
          icon: 'mdi-test-tube'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_ab_testing'),
          description: 'Realizar A/B testing',
          type: 'flow_control_ab_testing',
          icon: 'mdi-test-tube',
          color: '#f1b44c'
        }
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_reachability'),
          icon: 'mdi-contactless-payment'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_reachability'),
          description: 'Reachability',
          type: 'flow_control_reachability',
          icon: 'mdi-contactless-payment',
          color: '#f1b44c'
        }
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_finish'),
          icon: 'mdi-exit-to-app'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_finish'),
          description: 'Terminar',
          type: 'flow_control_end',
          icon: 'mdi-exit-to-app'          
        }
      },

      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_cancel'),
          icon: 'mdi-cancel'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_cancel'),
          description: 'Cancelar',
          type: 'flow_control_cancel',
          icon: 'mdi-cancel'          
        }
      }
    ]
  },
  {
    group : i18n.t('journeys.journey_workflow_components_groups_actions'),
    blocks: [
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_api'),
          icon: 'mdi-code-braces'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_api'),
          description: 'Invocar API',
          type: 'actions_api',
          icon: 'mdi-code-braces',
          color: "#74788d"
        }
      },
      {
        preview: {
          title: i18n.t('journeys.journey_workflow_components_script'),
          icon: 'mdi-code-tags'
        },
        node: {
          title: i18n.t('journeys.journey_workflow_components_script'),
          description: 'Script',
          type: 'actions_script',
          icon: 'mdi-code-tags',
          color: "#74788d"
        }
      }
    ]
  }
]


export {blocks}