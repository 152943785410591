<script>

import {
	
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
	
    
	},
	data() {
		return {
			submitted:false,
      config:{
        variants: []
      },
      aux_config:{
        variants: [
          {
            name : 'Variant 1',
            ratio: 0
          },
          {
            name : 'Variant 2',
            ratio: 0
          }
        ]
      },
    };
	},
  mounted() {
    
  },
  computed:{
    totalValue() {
      return this.config.variants.reduce((sum, slider) => sum + parseFloat(slider.ratio), 0);
    }
  },
  beforeDestroy() {
    
  },
	methods: {
    onNewVariantClicked(){
      this.config.variants.push({
        name: `Variant ${this.config.variants.length + 1}`,
        ratio:0
      })
    },
    getABTestingConfig(){
      return this.config; 
    },
    
    setABTestingConfig(config){
      this.config = config || this.aux_config;
    },
    onRemoveVariant(index){
      this.config.variants.splice(index,1);
    },
    onVariantRationChanged(index){
      const variant = this.config.variants[index];
        variant.ratio = this.config.variants[index].ratio;
        
        this.$set(this.config.variants, index, variant);
      
        if(this.config.variants.length == 2) {
          const auxIndex = index == 1 ? 0 : 1 
          this.config.variants[auxIndex].ratio = 100 - this.config.variants[index].ratio;

          const variant = this.config.variants[auxIndex];
          variant.ratio = 100 - this.config.variants[index].ratio;
          
          this.$set(this.config.variants, auxIndex, variant);
        }
    }
    
  },

	props: {
		
	},
	emits: [],
  
  
};
</script>

<template>
  <div>
    <div class="text-end col flex-row-reverse">
      <div class="card bg-warning text-white-50 mb-0 d-inline-block p-2 me-3" v-if="totalValue > 100"><i class="mdi mdi-alert-circle-outline"></i> {{ `${totalValue }% ${$t('journeys.journey_workflow_components_ab_testing_invalid')} ${-(100 - totalValue)}%` || '' }}
      </div>
      <button type="button" v-on:click="onNewVariantClicked" class="btn btn-sm btn-primary mt-3 mb-3" v-if="config.variants.length > 0">+ {{$t('personalizations.new_variant')}}</button>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table align-end">
          <thead class="table-light">
            <tr>
              <th>{{$t('personalizations.name')}}</th>
              <th>
                <div class="float-end"> {{$t('personalizations.variant_allocation')}} </div>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(variant, index) in config.variants" :key="variant.id">
              <td class="text-start fw-bold">
                <b-form-input v-model="variant.name" class="w-50" ></b-form-input>
              </td>
              <td>
                <b-form-input v-model="variant.ratio" type="range" :min="0" :max="100" step="1" class="w-100" @input="onVariantRationChanged(index)" />
              </td>
              <td>
                <div class="d-inline-flex align-items-center float-end">
                  %  <b-form-input v-model="variant.ratio" type="number" :min="0" :max="100" step="1" class="form-control form-control-sm" @input="onVariantRationChanged(index)"/>
                </div>
              </td>
              <td class="text-center">
                <i class="fas fa-trash-alt text-danger me-1 interact" v-if="config.variants.length > 2" v-on:click="onRemoveVariant(index)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>




</style>