<script>
/**
 * Profile component
 */
export default {
  components: {
    
  },
  data() {
    return {};
  },
  mounted() {},
  emits: ['onButtonClicked', 'onSecondButtonClicked'],
  props: {
    config: {
      type: Object,
    },
  },
};
</script>
<template>
  <div class="pf-c-empty-state">
    <div class="pf-c-empty-state__content w-100">
      <i class="fas pf-c-empty-state__icon" :class="config.icon" aria-hidden="true"></i>

      <h5 class=" h5">
        {{config?.title}}
      </h5>
      <div class="pf-c-empty-state__body">
        <span>{{config?.subtitle}}</span>
        <div v-if="config?.academy">
            <a class="small text-primary mb-0" :href="config?.academy" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
        </div>
    </div>
    <button class="btn btn-sm btn-link mt-2" type="button" @click="$emit('onButtonClicked')" v-if="config?.linkButtonText">{{config?.linkButtonText}}</button>
    <button class="btn btn-sm btn-primary mt-2" type="button" @click="$emit('onButtonClicked')" v-if="config?.buttonText">{{config?.buttonText}}</button>
    <button class="btn btn-sm btn-outline-primary mt-2 ms-2" type="button" @click="$emit('onSecondButtonClicked')" v-if="config?.secondButtonText">{{config?.secondButtonText}}</button>
    </div>
</div>
</template>

<style scoped>
@font-face { 
  font-family:"Font Awesome 5 Free";
  font-style:normal;
  font-weight:900;
  src:url("https://unpkg.com/@patternfly/patternfly@4.224.2/assets/fonts/webfonts/fa-solid-900.woff2") format("woff2"), url("https://unpkg.com/@patternfly/patternfly@4.224.2/assets/fonts/webfonts/fa-solid-900.woff") format("woff");
} 
@font-face { 
  font-family:"Font Awesome 5 Free";
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(http://localhost:8080/fonts/fa-regular-400.261d666b.eot);
  src:url(http://localhost:8080/fonts/fa-regular-400.261d666b.eot?#iefix) format("embedded-opentype"), url(http://localhost:8080/fonts/fa-regular-400.c20b5b73.woff2) format("woff2"), url(http://localhost:8080/fonts/fa-regular-400.f89ea91e.woff) format("woff"), url(http://localhost:8080/fonts/fa-regular-400.db78b935.ttf) format("truetype"), url(http://localhost:8080/img/fa-regular-400.89ffa3ab.svg#fontawesome) format("svg");
} 
@font-face { 
  font-family:"Font Awesome 5 Free";
  font-style:normal;
  font-weight:900;
  font-display:block;
  src:url(http://localhost:8080/fonts/fa-solid-900.a0369ea5.eot);
  src:url(http://localhost:8080/fonts/fa-solid-900.a0369ea5.eot?#iefix) format("embedded-opentype"), url(http://localhost:8080/fonts/fa-solid-900.b15db15f.woff2) format("woff2"), url(http://localhost:8080/fonts/fa-solid-900.bea989e8.woff) format("woff"), url(http://localhost:8080/fonts/fa-solid-900.1ab236ed.ttf) format("truetype"), url(http://localhost:8080/img/fa-solid-900.ec763292.svg#fontawesome) format("svg");
} 
body { 
/* CSS Variables that may have been missed get put on body */ 
    --bs-card-spacer-y:  1.25rem;  
    --bs-card-spacer-x:  1.25rem;  
    --bs-card-border-width:  0;  
    --bs-card-border-color:  #f6f6f6;  
    --bs-card-border-radius:  0.25rem;  
    --bs-card-bg:  #fff;  
    --bs-card-spacer-y:   1.25rem;  
    --bs-card-spacer-x:   1.25rem;  
    --bs-card-border-width:   0;  
    --bs-card-border-color:   #f6f6f6;  
    --bs-card-border-radius:   0.25rem;  
    --bs-card-bg:   #fff;  
    --bs-gutter-x:  24px;  
    --bs-gutter-y:  0;  
    --bs-gutter-x:  12px;  
    --bs-gutter-y:  0;  
    --pf-global--spacer--xl: 2rem; 
    --pf-c-empty-state--PaddingTop:  var(--pf-global--spacer--xl);  
    --pf-c-empty-state--PaddingRight:  var(--pf-global--spacer--xl);  
    --pf-c-empty-state--PaddingBottom:  var(--pf-global--spacer--xl);  
    --pf-c-empty-state--PaddingLeft:  var(--pf-global--spacer--xl);  
    --pf-c-empty-state__content--MaxWidth:  none;  
    --pf-global--spacer--lg: 1.5rem; 
    --pf-c-empty-state__icon--MarginBottom:  var(--pf-global--spacer--lg);  
    --pf-global--icon--FontSize--xl: 3.375rem; 
    --pf-c-empty-state__icon--FontSize:  var(--pf-global--icon--FontSize--xl);  
    --pf-global--icon--Color--light: #aaabac; 
    --pf-c-empty-state__icon--Color:  var(--pf-global--icon--Color--light);  
    --pf-global--FontSize--xl: 1.25rem; 
    --pf-c-empty-state__content--c-title--m-lg--FontSize:  var(--pf-global--FontSize--xl);  
    --pf-global--spacer--md: 1rem; 
    --pf-c-empty-state__body--MarginTop:  var(--pf-global--spacer--md);  
    --pf-global--Color--200: #aaabac; 
    --pf-c-empty-state__body--Color:  var(--pf-global--Color--200);  
    --pf-c-empty-state__primary--MarginTop:  var(--pf-global--spacer--xl);  
    --pf-global--spacer--sm: 0.5rem; 
    --pf-c-empty-state__primary--secondary--MarginTop:  var(--pf-global--spacer--sm);  
    --pf-c-empty-state__secondary--MarginTop:  var(--pf-global--spacer--xl);  
    --pf-global--spacer--xs: 0.25rem; 
    --pf-c-empty-state__secondary--MarginBottom:  calc(var(--pf-global--spacer--xs) * -1);  
    --pf-c-empty-state__secondary--child--MarginRight:  calc(var(--pf-global--spacer--xs) / 2);  
    --pf-c-empty-state__secondary--child--MarginBottom:  var(--pf-global--spacer--xs);  
    --pf-c-empty-state__secondary--child--MarginLeft:  calc(var(--pf-global--spacer--xs) / 2);  
    --pf-global--FontSize--sm: 0.875rem; 
    --pf-c-empty-state--m-xs__body--FontSize:  var(--pf-global--FontSize--sm);  
    --pf-c-empty-state--m-xs--button--FontSize:  var(--pf-global--FontSize--sm);  
    --pf-c-empty-state--m-xs--PaddingTop:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs--PaddingRight:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs--PaddingBottom:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs--PaddingLeft:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs__icon--MarginBottom:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs__body--MarginTop:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs__primary--MarginTop:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xs__secondary--MarginTop:  var(--pf-global--spacer--md);  
    --pf-c-empty-state--m-xl__body--FontSize:  var(--pf-global--FontSize--xl);  
    --pf-c-empty-state--m-xl__body--MarginTop:  var(--pf-global--spacer--lg);  
    --pf-c-empty-state--m-xl__icon--MarginBottom:  var(--pf-global--spacer--xl);  
    --pf-c-empty-state--m-xl--c-button__secondary--MarginTop:  var(--pf-global--spacer--md);  
    --pf-global--FontFamily--heading--sans-serif: "RedHatDisplay", "Overpass", overpass, helvetica, arial, sans-serif; 
    --pf-c-title--FontFamily:  var(--pf-global--FontFamily--heading--sans-serif);  
    --pf-global--LineHeight--sm: 1.3; 
    --pf-c-title--m-4xl--LineHeight:  var(--pf-global--LineHeight--sm);  
    --pf-global--FontSize--4xl: 2.25rem; 
    --pf-c-title--m-4xl--FontSize:  var(--pf-global--FontSize--4xl);  
    --pf-global--FontWeight--normal: 400; 
    --pf-c-title--m-4xl--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-c-title--m-3xl--LineHeight:  var(--pf-global--LineHeight--sm);  
    --pf-global--FontSize--3xl: 1.75rem; 
    --pf-c-title--m-3xl--FontSize:  var(--pf-global--FontSize--3xl);  
    --pf-c-title--m-3xl--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-c-title--m-2xl--LineHeight:  var(--pf-global--LineHeight--sm);  
    --pf-global--FontSize--2xl: 1.5rem; 
    --pf-c-title--m-2xl--FontSize:  var(--pf-global--FontSize--2xl);  
    --pf-c-title--m-2xl--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-global--LineHeight--md: 1.5; 
    --pf-c-title--m-xl--LineHeight:  var(--pf-global--LineHeight--md);  
    --pf-c-title--m-xl--FontSize:  var(--pf-global--FontSize--xl);  
    --pf-c-title--m-xl--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-c-title--m-lg--LineHeight:  var(--pf-global--LineHeight--md);  
    --pf-global--FontSize--lg: 1.125rem; 
    --pf-c-title--m-lg--FontSize:  var(--pf-global--FontSize--lg);  
    --pf-c-title--m-lg--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-c-title--m-md--LineHeight:  var(--pf-global--LineHeight--md);  
    --pf-global--FontSize--md: 1rem; 
    --pf-c-title--m-md--FontSize:  var(--pf-global--FontSize--md);  
    --pf-c-title--m-md--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-global--spacer--form-element: 0.375rem; 
    --pf-c-button--PaddingTop:  var(--pf-global--spacer--form-element);  
    --pf-c-button--PaddingRight:  var(--pf-global--spacer--md);  
    --pf-c-button--PaddingBottom:  var(--pf-global--spacer--form-element);  
    --pf-c-button--PaddingLeft:  var(--pf-global--spacer--md);  
    --pf-c-button--LineHeight:  var(--pf-global--LineHeight--md);  
    --pf-c-button--FontWeight:  var(--pf-global--FontWeight--normal);  
    --pf-c-button--FontSize:  var(--pf-global--FontSize--md);  
    --pf-global--BorderRadius--sm: 3px; 
    --pf-c-button--BorderRadius:  var(--pf-global--BorderRadius--sm);  
    --pf-c-button--after--BorderRadius:  var(--pf-global--BorderRadius--sm);  
    --pf-c-button--after--BorderColor:  transparent;  
    --pf-global--BorderWidth--sm: 1px; 
    --pf-c-button--after--BorderWidth:  var(--pf-global--BorderWidth--sm);  
    --pf-global--BorderWidth--md: 2px; 
    --pf-c-button--hover--after--BorderWidth:  var(--pf-global--BorderWidth--md);  
    --pf-c-button--focus--after--BorderWidth:  var(--pf-global--BorderWidth--md);  
    --pf-c-button--active--after--BorderWidth:  var(--pf-global--BorderWidth--md);  
    --pf-global--disabled-color--100: #57585a; 
    --pf-c-button--disabled--Color:  var(--pf-global--disabled-color--100);  
    --pf-global--disabled-color--200: #444548; 
    --pf-c-button--disabled--BackgroundColor:  var(--pf-global--disabled-color--200);  
    --pf-global--primary-color--100: #1fa7f8; 
    --pf-c-button--m-primary--BackgroundColor:  var(--pf-global--primary-color--100);  
    --pf-global--Color--light-100: #e0e0e0; 
    --pf-c-button--m-primary--Color:  var(--pf-global--Color--light-100);  
    --pf-global--primary-color--200: #004080; 
    --pf-c-button--m-primary--hover--BackgroundColor:  var(--pf-global--primary-color--200);  
    --pf-c-button--m-primary--hover--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-primary--focus--BackgroundColor:  var(--pf-global--primary-color--200);  
    --pf-c-button--m-primary--focus--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-primary--active--BackgroundColor:  var(--pf-global--primary-color--200);  
    --pf-c-button--m-primary--active--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-secondary--after--BorderColor:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--Color:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--hover--after--BorderColor:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--hover--Color:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--focus--after--BorderColor:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--focus--Color:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--active--after--BorderColor:  var(--pf-global--primary-color--100);  
    --pf-c-button--m-secondary--active--Color:  var(--pf-global--primary-color--100);  
    --pf-global--danger-color--100: #fe5142; 
    --pf-c-button--m-secondary--m-danger--Color:  var(--pf-global--danger-color--100);  
    --pf-c-button--m-secondary--m-danger--after--BorderColor:  var(--pf-global--danger-color--100);  
    --pf-global--danger-color--200: #ff7468; 
    --pf-c-button--m-secondary--m-danger--hover--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-secondary--m-danger--hover--after--BorderColor:  var(--pf-global--danger-color--100);  
    --pf-c-button--m-secondary--m-danger--focus--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-secondary--m-danger--focus--after--BorderColor:  var(--pf-global--danger-color--100);  
    --pf-c-button--m-secondary--m-danger--active--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-secondary--m-danger--active--after--BorderColor:  var(--pf-global--danger-color--100);  
    --pf-global--Color--100: #e0e0e0; 
    --pf-c-button--m-tertiary--after--BorderColor:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--hover--after--BorderColor:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--hover--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--focus--after--BorderColor:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--focus--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--active--after--BorderColor:  var(--pf-global--Color--100);  
    --pf-c-button--m-tertiary--active--Color:  var(--pf-global--Color--100);  
    --pf-global--warning-color--100: #f0ab00; 
    --pf-c-button--m-warning--BackgroundColor:  var(--pf-global--warning-color--100);  
    --pf-global--Color--dark-100: #e0e0e0; 
    --pf-c-button--m-warning--Color:  var(--pf-global--Color--dark-100);  
    --pf-global--palette--gold-500: #c58c00; 
    --pf-c-button--m-warning--hover--BackgroundColor:  var(--pf-global--palette--gold-500);  
    --pf-c-button--m-warning--hover--Color:  var(--pf-global--Color--dark-100);  
    --pf-c-button--m-warning--focus--BackgroundColor:  var(--pf-global--palette--gold-500);  
    --pf-c-button--m-warning--focus--Color:  var(--pf-global--Color--dark-100);  
    --pf-c-button--m-warning--active--BackgroundColor:  var(--pf-global--palette--gold-500);  
    --pf-c-button--m-warning--active--Color:  var(--pf-global--Color--dark-100);  
    --pf-c-button--m-danger--BackgroundColor:  var(--pf-global--danger-color--100);  
    --pf-c-button--m-danger--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-danger--hover--BackgroundColor:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-danger--hover--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-danger--focus--BackgroundColor:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-danger--focus--Color:  var(--pf-global--Color--light-100);  
    --pf-c-button--m-danger--active--BackgroundColor:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-danger--active--Color:  var(--pf-global--Color--light-100);  
    --pf-global--link--Color: #1fa7f8; 
    --pf-c-button--m-link--Color:  var(--pf-global--link--Color);  
    --pf-global--link--Color--hover: #73bcf7; 
    --pf-c-button--m-link--hover--Color:  var(--pf-global--link--Color--hover);  
    --pf-c-button--m-link--focus--Color:  var(--pf-global--link--Color--hover);  
    --pf-c-button--m-link--active--Color:  var(--pf-global--link--Color--hover);  
    --pf-global--link--TextDecoration--hover: underline; 
    --pf-c-button--m-link--m-inline--hover--TextDecoration:  var(--pf-global--link--TextDecoration--hover);  
    --pf-c-button--m-link--m-inline--hover--Color:  var(--pf-global--link--Color--hover);  
    --pf-c-button--m-link--m-inline__progress--Left:  var(--pf-global--spacer--xs);  
    --pf-c-button--m-link--m-inline--m-in-progress--PaddingLeft:  calc(var(--pf-c-button--m-link--m-inline__progress--Left) + 1rem + var(--pf-global--spacer--sm));  
    --pf-c-button--m-link--m-danger--Color:  var(--pf-global--danger-color--100);  
    --pf-c-button--m-link--m-danger--hover--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-link--m-danger--focus--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-link--m-danger--active--Color:  var(--pf-global--danger-color--200);  
    --pf-c-button--m-plain--Color:  var(--pf-global--Color--200);  
    --pf-c-button--m-plain--hover--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-plain--focus--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-plain--active--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-plain--disabled--Color:  var(--pf-global--disabled-color--200);  
    --pf-global--BackgroundColor--100: #1b1d21; 
    --pf-c-button--m-control--BackgroundColor:  var(--pf-global--BackgroundColor--100);  
    --pf-c-button--m-control--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-control--after--BorderWidth:  var(--pf-global--BorderWidth--sm);  
    --pf-global--BorderColor--300: #57585a; 
    --pf-c-button--m-control--after--BorderTopColor:  var(--pf-global--BorderColor--300);  
    --pf-c-button--m-control--after--BorderRightColor:  var(--pf-global--BorderColor--300);  
    --pf-global--BorderColor--200: #444548; 
    --pf-c-button--m-control--after--BorderBottomColor:  var(--pf-global--BorderColor--200);  
    --pf-c-button--m-control--after--BorderLeftColor:  var(--pf-global--BorderColor--300);  
    --pf-global--disabled-color--300: #aaabac; 
    --pf-c-button--m-control--disabled--BackgroundColor:  var(--pf-global--disabled-color--300);  
    --pf-c-button--m-control--hover--BackgroundColor:  var(--pf-global--BackgroundColor--100);  
    --pf-c-button--m-control--hover--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-control--hover--after--BorderBottomWidth:  var(--pf-global--BorderWidth--md);  
    --pf-global--active-color--100: #1fa7f8; 
    --pf-c-button--m-control--hover--after--BorderBottomColor:  var(--pf-global--active-color--100);  
    --pf-c-button--m-control--active--BackgroundColor:  var(--pf-global--BackgroundColor--100);  
    --pf-c-button--m-control--active--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-control--active--after--BorderBottomWidth:  var(--pf-global--BorderWidth--md);  
    --pf-c-button--m-control--active--after--BorderBottomColor:  var(--pf-global--active-color--100);  
    --pf-c-button--m-control--focus--BackgroundColor:  var(--pf-global--BackgroundColor--100);  
    --pf-c-button--m-control--focus--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-control--focus--after--BorderBottomWidth:  var(--pf-global--BorderWidth--md);  
    --pf-c-button--m-control--focus--after--BorderBottomColor:  var(--pf-global--active-color--100);  
    --pf-c-button--m-control--m-expanded--BackgroundColor:  var(--pf-global--BackgroundColor--100);  
    --pf-c-button--m-control--m-expanded--Color:  var(--pf-global--Color--100);  
    --pf-c-button--m-control--m-expanded--after--BorderBottomWidth:  var(--pf-global--BorderWidth--md);  
    --pf-c-button--m-control--m-expanded--after--BorderBottomColor:  var(--pf-global--active-color--100);  
    --pf-c-button--m-small--FontSize:  var(--pf-global--FontSize--sm);  
    --pf-c-button--m-display-lg--PaddingTop:  var(--pf-global--spacer--md);  
    --pf-c-button--m-display-lg--PaddingRight:  var(--pf-global--spacer--xl);  
    --pf-c-button--m-display-lg--PaddingBottom:  var(--pf-global--spacer--md);  
    --pf-c-button--m-display-lg--PaddingLeft:  var(--pf-global--spacer--xl);  
    --pf-global--FontWeight--bold: 700; 
    --pf-c-button--m-display-lg--FontWeight:  var(--pf-global--FontWeight--bold);  
    --pf-c-button--m-link--m-display-lg--FontSize:  var(--pf-global--FontSize--lg);  
    --pf-c-button__icon--m-start--MarginRight:  var(--pf-global--spacer--xs);  
    --pf-c-button__icon--m-end--MarginLeft:  var(--pf-global--spacer--xs);  
    --pf-global--icon--FontSize--md: 1.125rem; 
    --pf-c-button__progress--width:  calc(var(--pf-global--icon--FontSize--md) + var(--pf-global--spacer--sm));  
    --pf-c-button__progress--Left:  var(--pf-global--spacer--md);  
    --pf-global--TransitionDuration: 250ms; 
    --pf-c-button--m-progress--TransitionDuration:  var(--pf-global--TransitionDuration);  
    --pf-c-button--m-progress--PaddingRight:  calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2);  
    --pf-c-button--m-progress--PaddingLeft:  calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2);  
    --pf-c-button--m-in-progress--PaddingRight:  var(--pf-global--spacer--md);  
    --pf-c-button--m-in-progress--PaddingLeft:  calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width));  
    --pf-c-button--m-in-progress--m-plain--Color:  var(--pf-global--primary-color--100);  
    --pf-c-button__count--MarginLeft:  var(--pf-global--spacer--sm);  
    --pf-c-button--disabled__c-badge--Color:  var(--pf-global--Color--dark-100);  
    --pf-global--BackgroundColor--200: #0f1214; 
    --pf-c-button--disabled__c-badge--BackgroundColor:  var(--pf-global--BackgroundColor--200);  
    --pf-c-button--m-primary__c-badge--BorderColor:  var(--pf-global--BorderColor--300);  
    --pf-c-button--m-primary__c-badge--BorderWidth:  var(--pf-global--BorderWidth--sm);  
    --pf-c-button--after--BorderWidth:  var(--pf-c-button--hover--after--BorderWidth);  
    --pf-c-button--m-primary--Color:  var(--pf-c-button--m-primary--hover--Color);  
    --pf-c-button--m-primary--BackgroundColor:  var(--pf-c-button--m-primary--hover--BackgroundColor); 
} 

* { 
    -webkit-box-sizing:  border-box; 
    box-sizing:  border-box;
} 

*  { 
    outline: none;
} 

.card-body { 
    -webkit-box-flex: 1; 
    -ms-flex: 1 1 auto; 
    flex: 1 1 auto; 
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x); 
    color: var(--bs-card-color);
} 

.card { 
    display:  flex; 
    display:  -ms-flexbox; 
    display:  -webkit-box; 
    --bs-card-spacer-y:  1.25rem; 
    --bs-card-spacer-x:  1.25rem; 
    --bs-card-border-width:  0; 
    --bs-card-border-color:  #f6f6f6; 
    --bs-card-border-radius:  0.25rem; 
    --bs-card-bg:  #fff; 
    position:  relative; 
    -webkit-box-orient:  vertical; 
    -webkit-box-direction:  normal; 
    -ms-flex-direction:  column; 
    flex-direction:  column; 
    min-width:  0; 
    height:  var(--bs-card-height); 
    word-wrap:  break-word; 
    background-color:  var(--bs-card-bg); 
    background-clip:  border-box; 
    border:  var(--bs-card-border-width) solid var(--bs-card-border-color); 
    border-radius:  var(--bs-card-border-radius);
    margin-bottom: 24px; 
    -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03); 
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
} 

.row > *  { 
    -ms-flex-negative: 0; 
    flex-shrink: 0; 
    width: 100%; 
    max-width: 100%; 
    padding-right: calc(var(--bs-gutter-x) * 0.5); 
    padding-left: calc(var(--bs-gutter-x) * 0.5); 
    margin-top: var(--bs-gutter-y);
} 

.col-12 { 
    -webkit-box-flex: 0; 
    -ms-flex: 0 0 auto; 
    flex: 0 0 auto; 
    width: 100%;
} 

.row > *  { 
    position: relative;
} 

.row { 
    --bs-gutter-x: 24px; 
    --bs-gutter-y: 0; 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap; 
    margin-top: calc(-1 * var(--bs-gutter-y)); 
    margin-right: calc(-0.5 * var(--bs-gutter-x)); 
    margin-left: calc(-0.5 * var(--bs-gutter-x));
} 

.container-fluid { 
    --bs-gutter-x: 12px; 
    --bs-gutter-y: 0; 
    width: 100%; 
    padding-right: calc(var(--bs-gutter-x) * 0.5); 
    padding-left: calc(var(--bs-gutter-x) * 0.5); 
    margin-right: auto; 
    margin-left: auto;
} 

.page-content { 
    padding: calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2);
} 

.main-content { 
    margin-left: 250px; 
    overflow: hidden;
} 

:root { 
    --bs-font-sans-serif: "Poppins", sans-serif; 
    --bs-body-font-family: var(--bs-font-sans-serif); 
    --bs-body-font-size: 0.8125rem; 
    --bs-body-font-weight: 400; 
    --bs-body-line-height: 1.5; 
    --bs-body-color: #495057; 
    --bs-body-bg: #f8f8fb; 
}   

:root { 
    --pf-global--palette--gold-500: #c58c00; 
    --pf-global--BackgroundColor--100: #fff; 
    --pf-global--BackgroundColor--200: #f0f0f0; 
    --pf-global--Color--100: #151515; 
    --pf-global--Color--200: #6a6e73; 
    --pf-global--Color--light-100: #fff; 
    --pf-global--Color--dark-100: #151515; 
    --pf-global--active-color--100: #06c; 
    --pf-global--disabled-color--100: #6a6e73; 
    --pf-global--disabled-color--200: #d2d2d2; 
    --pf-global--disabled-color--300: #f0f0f0; 
    --pf-global--primary-color--100: #06c; 
    --pf-global--primary-color--200: #004080; 
    --pf-global--warning-color--100: #f0ab00; 
    --pf-global--danger-color--100: #c9190b; 
    --pf-global--danger-color--200: #a30000; 
    --pf-global--spacer--xs: 0.25rem; 
    --pf-global--spacer--sm: 0.5rem; 
    --pf-global--spacer--md: 1rem; 
    --pf-global--spacer--lg: 1.5rem; 
    --pf-global--spacer--xl: 2rem; 
    --pf-global--spacer--form-element: 0.375rem; 
    --pf-global--link--Color: #06c; 
    --pf-global--link--Color--hover: #004080; 
    --pf-global--link--TextDecoration--hover: underline; 
    --pf-global--BorderWidth--sm: 1px; 
    --pf-global--BorderWidth--md: 2px; 
    --pf-global--BorderColor--200: #8a8d90; 
    --pf-global--BorderColor--300: #f0f0f0; 
    --pf-global--BorderRadius--sm: 3px; 
    --pf-global--icon--Color--light: #6a6e73; 
    --pf-global--icon--FontSize--md: 1.125rem; 
    --pf-global--icon--FontSize--xl: 3.375rem; 
    --pf-global--FontFamily--sans-serif: "RedHatText", "Overpass", overpass, helvetica, arial, sans-serif; 
    --pf-global--FontFamily--heading--sans-serif: "RedHatDisplay", "Overpass", overpass, helvetica, arial, sans-serif; 
    --pf-global--FontSize--4xl: 2.25rem; 
    --pf-global--FontSize--3xl: 1.75rem; 
    --pf-global--FontSize--2xl: 1.5rem; 
    --pf-global--FontSize--xl: 1.25rem; 
    --pf-global--FontSize--lg: 1.125rem; 
    --pf-global--FontSize--md: 1rem; 
    --pf-global--FontSize--sm: 0.875rem; 
    --pf-global--FontWeight--normal: 400; 
    --pf-global--FontWeight--bold: 700; 
    --pf-global--LineHeight--sm: 1.3; 
    --pf-global--LineHeight--md: 1.5; 
    --pf-global--TransitionDuration: 250ms; 
} 

[class*="pf-c-"] { 
    padding: 0; 
    margin: 0; 
    background-color: transparent;
} 

.pf-c-empty-state { 
    --pf-c-empty-state--PaddingTop: var(--pf-global--spacer--xl); 
    --pf-c-empty-state--PaddingRight: var(--pf-global--spacer--xl); 
    --pf-c-empty-state--PaddingBottom: var(--pf-global--spacer--xl); 
    --pf-c-empty-state--PaddingLeft: var(--pf-global--spacer--xl); 
    --pf-c-empty-state__content--MaxWidth: none; 
    --pf-c-empty-state__icon--MarginBottom: var(--pf-global--spacer--lg); 
    --pf-c-empty-state__icon--FontSize: var(--pf-global--icon--FontSize--xl); 
    --pf-c-empty-state__icon--Color: var(--pf-global--icon--Color--light); 
    --pf-c-empty-state__content--c-title--m-lg--FontSize: var(--pf-global--FontSize--xl); 
    --pf-c-empty-state__body--MarginTop: var(--pf-global--spacer--md); 
    --pf-c-empty-state__body--Color: var(--pf-global--Color--200); 
    --pf-c-empty-state__primary--MarginTop: var(--pf-global--spacer--xl); 
    --pf-c-empty-state__primary--secondary--MarginTop: var(--pf-global--spacer--sm); 
    --pf-c-empty-state__secondary--MarginTop: var(--pf-global--spacer--xl); 
    --pf-c-empty-state__secondary--MarginBottom: calc(var(--pf-global--spacer--xs) * -1); 
    --pf-c-empty-state__secondary--child--MarginRight: calc(var(--pf-global--spacer--xs) / 2); 
    --pf-c-empty-state__secondary--child--MarginBottom: var(--pf-global--spacer--xs); 
    --pf-c-empty-state__secondary--child--MarginLeft: calc(var(--pf-global--spacer--xs) / 2); 
    --pf-c-empty-state--m-xs__body--FontSize: var(--pf-global--FontSize--sm); 
    --pf-c-empty-state--m-xs--button--FontSize: var(--pf-global--FontSize--sm); 
    --pf-c-empty-state--m-xs--PaddingTop: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs--PaddingRight: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs--PaddingBottom: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs--PaddingLeft: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs__icon--MarginBottom: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs__body--MarginTop: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs__primary--MarginTop: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xs__secondary--MarginTop: var(--pf-global--spacer--md); 
    --pf-c-empty-state--m-xl__body--FontSize: var(--pf-global--FontSize--xl); 
    --pf-c-empty-state--m-xl__body--MarginTop: var(--pf-global--spacer--lg); 
    --pf-c-empty-state--m-xl__icon--MarginBottom: var(--pf-global--spacer--xl); 
    --pf-c-empty-state--m-xl--c-button__secondary--MarginTop: var(--pf-global--spacer--md); 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding: var(--pf-c-empty-state--PaddingTop) var(--pf-c-empty-state--PaddingRight) var(--pf-c-empty-state--PaddingBottom) var(--pf-c-empty-state--PaddingLeft); 
    text-align: center;
} 

*,:before,:after { 
    -webkit-box-sizing:  border-box; 
    box-sizing:  border-box;
} 

[class*="pf-c-"],[class*="pf-c-"]:before,[class*="pf-c-"]:after { 
    padding: 0; 
    margin: 0; 
    background-color: transparent;
} 

.pf-c-empty-state__content { 
    max-width: var(--pf-c-empty-state__content--MaxWidth);
} 

.pf-c-empty-state__icon { 
    margin-bottom: var(--pf-c-empty-state__icon--MarginBottom); 
    font-size: 3.375rem; 
    color: var(--pf-c-empty-state__icon--Color);
} 

.pf-c-title { 
    --pf-c-title--FontFamily: var(--pf-global--FontFamily--heading--sans-serif); 
    --pf-c-title--m-4xl--LineHeight: var(--pf-global--LineHeight--sm); 
    --pf-c-title--m-4xl--FontSize: var(--pf-global--FontSize--4xl); 
    --pf-c-title--m-4xl--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-title--m-3xl--LineHeight: var(--pf-global--LineHeight--sm); 
    --pf-c-title--m-3xl--FontSize: var(--pf-global--FontSize--3xl); 
    --pf-c-title--m-3xl--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-title--m-2xl--LineHeight: var(--pf-global--LineHeight--sm); 
    --pf-c-title--m-2xl--FontSize: var(--pf-global--FontSize--2xl); 
    --pf-c-title--m-2xl--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-title--m-xl--LineHeight: var(--pf-global--LineHeight--md); 
    --pf-c-title--m-xl--FontSize: var(--pf-global--FontSize--xl); 
    --pf-c-title--m-xl--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-title--m-lg--LineHeight: var(--pf-global--LineHeight--md); 
    --pf-c-title--m-lg--FontSize: var(--pf-global--FontSize--lg); 
    --pf-c-title--m-lg--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-title--m-md--LineHeight: var(--pf-global--LineHeight--md); 
    --pf-c-title--m-md--FontSize: var(--pf-global--FontSize--md); 
    --pf-c-title--m-md--FontWeight: var(--pf-global--FontWeight--normal); 
    font-family: var(--pf-c-title--FontFamily); 
    word-break: break-word;
} 

.pf-c-title.pf-m-lg { 
    font-size: var(--pf-c-title--m-lg--FontSize); 
    font-weight: var(--pf-c-title--m-lg--FontWeight); 
    line-height: var(--pf-c-title--m-lg--LineHeight);
} 

.pf-c-empty-state__content > .pf-c-title.pf-m-lg  { 
    font-size: var(--pf-c-empty-state__content--c-title--m-lg--FontSize);
} 

.pf-c-empty-state__body { 
    margin-top: var(--pf-c-empty-state__body--MarginTop); 
    color: var(--pf-c-empty-state__body--Color);
    max-width: 70%;
    margin: auto;
} 

.pf-c-button { 
    --pf-c-button--PaddingTop: var(--pf-global--spacer--form-element); 
    --pf-c-button--PaddingRight: var(--pf-global--spacer--md); 
    --pf-c-button--PaddingBottom: var(--pf-global--spacer--form-element); 
    --pf-c-button--PaddingLeft: var(--pf-global--spacer--md); 
    --pf-c-button--LineHeight: var(--pf-global--LineHeight--md); 
    --pf-c-button--FontWeight: var(--pf-global--FontWeight--normal); 
    --pf-c-button--FontSize: var(--pf-global--FontSize--md); 
    --pf-c-button--BorderRadius: var(--pf-global--BorderRadius--sm); 
    --pf-c-button--after--BorderRadius: var(--pf-global--BorderRadius--sm); 
    --pf-c-button--after--BorderColor: transparent; 
    --pf-c-button--after--BorderWidth: var(--pf-global--BorderWidth--sm); 
    --pf-c-button--hover--after--BorderWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--focus--after--BorderWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--active--after--BorderWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--disabled--Color: var(--pf-global--disabled-color--100); 
    --pf-c-button--disabled--BackgroundColor: var(--pf-global--disabled-color--200); 
    --pf-c-button--m-primary--BackgroundColor: var(--pf-global--primary-color--100); 
    --pf-c-button--m-primary--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--primary-color--200); 
    --pf-c-button--m-primary--hover--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--primary-color--200); 
    --pf-c-button--m-primary--focus--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--primary-color--200); 
    --pf-c-button--m-primary--active--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-secondary--after--BorderColor: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--Color: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--hover--after--BorderColor: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--hover--Color: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--focus--after--BorderColor: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--focus--Color: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--active--after--BorderColor: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--active--Color: var(--pf-global--primary-color--100); 
    --pf-c-button--m-secondary--m-danger--Color: var(--pf-global--danger-color--100); 
    --pf-c-button--m-secondary--m-danger--after--BorderColor: var(--pf-global--danger-color--100); 
    --pf-c-button--m-secondary--m-danger--hover--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-secondary--m-danger--hover--after--BorderColor: var(--pf-global--danger-color--100); 
    --pf-c-button--m-secondary--m-danger--focus--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-secondary--m-danger--focus--after--BorderColor: var(--pf-global--danger-color--100); 
    --pf-c-button--m-secondary--m-danger--active--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-secondary--m-danger--active--after--BorderColor: var(--pf-global--danger-color--100); 
    --pf-c-button--m-tertiary--after--BorderColor: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--hover--after--BorderColor: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--hover--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--focus--after--BorderColor: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--focus--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--active--after--BorderColor: var(--pf-global--Color--100); 
    --pf-c-button--m-tertiary--active--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-warning--BackgroundColor: var(--pf-global--warning-color--100); 
    --pf-c-button--m-warning--Color: var(--pf-global--Color--dark-100); 
    --pf-c-button--m-warning--hover--BackgroundColor: var(--pf-global--palette--gold-500); 
    --pf-c-button--m-warning--hover--Color: var(--pf-global--Color--dark-100); 
    --pf-c-button--m-warning--focus--BackgroundColor: var(--pf-global--palette--gold-500); 
    --pf-c-button--m-warning--focus--Color: var(--pf-global--Color--dark-100); 
    --pf-c-button--m-warning--active--BackgroundColor: var(--pf-global--palette--gold-500); 
    --pf-c-button--m-warning--active--Color: var(--pf-global--Color--dark-100); 
    --pf-c-button--m-danger--BackgroundColor: var(--pf-global--danger-color--100); 
    --pf-c-button--m-danger--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-danger--hover--BackgroundColor: var(--pf-global--danger-color--200); 
    --pf-c-button--m-danger--hover--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-danger--focus--BackgroundColor: var(--pf-global--danger-color--200); 
    --pf-c-button--m-danger--focus--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-danger--active--BackgroundColor: var(--pf-global--danger-color--200); 
    --pf-c-button--m-danger--active--Color: var(--pf-global--Color--light-100); 
    --pf-c-button--m-link--Color: var(--pf-global--link--Color); 
    --pf-c-button--m-link--hover--Color: var(--pf-global--link--Color--hover); 
    --pf-c-button--m-link--focus--Color: var(--pf-global--link--Color--hover); 
    --pf-c-button--m-link--active--Color: var(--pf-global--link--Color--hover); 
    --pf-c-button--m-link--m-inline--hover--TextDecoration: var(--pf-global--link--TextDecoration--hover); 
    --pf-c-button--m-link--m-inline--hover--Color: var(--pf-global--link--Color--hover); 
    --pf-c-button--m-link--m-inline__progress--Left: var(--pf-global--spacer--xs); 
    --pf-c-button--m-link--m-inline--m-in-progress--PaddingLeft: calc(var(--pf-c-button--m-link--m-inline__progress--Left) + 1rem + var(--pf-global--spacer--sm)); 
    --pf-c-button--m-link--m-danger--Color: var(--pf-global--danger-color--100); 
    --pf-c-button--m-link--m-danger--hover--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-link--m-danger--focus--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-link--m-danger--active--Color: var(--pf-global--danger-color--200); 
    --pf-c-button--m-plain--Color: var(--pf-global--Color--200); 
    --pf-c-button--m-plain--hover--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-plain--focus--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-plain--active--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-plain--disabled--Color: var(--pf-global--disabled-color--200); 
    --pf-c-button--m-control--BackgroundColor: var(--pf-global--BackgroundColor--100); 
    --pf-c-button--m-control--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-control--after--BorderWidth: var(--pf-global--BorderWidth--sm); 
    --pf-c-button--m-control--after--BorderTopColor: var(--pf-global--BorderColor--300); 
    --pf-c-button--m-control--after--BorderRightColor: var(--pf-global--BorderColor--300); 
    --pf-c-button--m-control--after--BorderBottomColor: var(--pf-global--BorderColor--200); 
    --pf-c-button--m-control--after--BorderLeftColor: var(--pf-global--BorderColor--300); 
    --pf-c-button--m-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300); 
    --pf-c-button--m-control--hover--BackgroundColor: var(--pf-global--BackgroundColor--100); 
    --pf-c-button--m-control--hover--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-control--hover--after--BorderBottomWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--m-control--hover--after--BorderBottomColor: var(--pf-global--active-color--100); 
    --pf-c-button--m-control--active--BackgroundColor: var(--pf-global--BackgroundColor--100); 
    --pf-c-button--m-control--active--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-control--active--after--BorderBottomWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--m-control--active--after--BorderBottomColor: var(--pf-global--active-color--100); 
    --pf-c-button--m-control--focus--BackgroundColor: var(--pf-global--BackgroundColor--100); 
    --pf-c-button--m-control--focus--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-control--focus--after--BorderBottomWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--m-control--focus--after--BorderBottomColor: var(--pf-global--active-color--100); 
    --pf-c-button--m-control--m-expanded--BackgroundColor: var(--pf-global--BackgroundColor--100); 
    --pf-c-button--m-control--m-expanded--Color: var(--pf-global--Color--100); 
    --pf-c-button--m-control--m-expanded--after--BorderBottomWidth: var(--pf-global--BorderWidth--md); 
    --pf-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-global--active-color--100); 
    --pf-c-button--m-small--FontSize: var(--pf-global--FontSize--sm); 
    --pf-c-button--m-display-lg--PaddingTop: var(--pf-global--spacer--md); 
    --pf-c-button--m-display-lg--PaddingRight: var(--pf-global--spacer--xl); 
    --pf-c-button--m-display-lg--PaddingBottom: var(--pf-global--spacer--md); 
    --pf-c-button--m-display-lg--PaddingLeft: var(--pf-global--spacer--xl); 
    --pf-c-button--m-display-lg--FontWeight: var(--pf-global--FontWeight--bold); 
    --pf-c-button--m-link--m-display-lg--FontSize: var(--pf-global--FontSize--lg); 
    --pf-c-button__icon--m-start--MarginRight: var(--pf-global--spacer--xs); 
    --pf-c-button__icon--m-end--MarginLeft: var(--pf-global--spacer--xs); 
    --pf-c-button__progress--width: calc(var(--pf-global--icon--FontSize--md) + var(--pf-global--spacer--sm)); 
    --pf-c-button__progress--Left: var(--pf-global--spacer--md); 
    --pf-c-button--m-progress--TransitionDuration: var(--pf-global--TransitionDuration); 
    --pf-c-button--m-progress--PaddingRight: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2); 
    --pf-c-button--m-progress--PaddingLeft: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2); 
    --pf-c-button--m-in-progress--PaddingRight: var(--pf-global--spacer--md); 
    --pf-c-button--m-in-progress--PaddingLeft: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width)); 
    --pf-c-button--m-in-progress--m-plain--Color: var(--pf-global--primary-color--100); 
    --pf-c-button__count--MarginLeft: var(--pf-global--spacer--sm); 
    --pf-c-button--disabled__c-badge--Color: var(--pf-global--Color--dark-100); 
    --pf-c-button--disabled__c-badge--BackgroundColor: var(--pf-global--BackgroundColor--200); 
    --pf-c-button--m-primary__c-badge--BorderColor: var(--pf-global--BorderColor--300); 
    --pf-c-button--m-primary__c-badge--BorderWidth: var(--pf-global--BorderWidth--sm); 
    position: relative; 
    display: inline-block; 
    padding: var(--pf-c-button--PaddingTop) var(--pf-c-button--PaddingRight) var(--pf-c-button--PaddingBottom) var(--pf-c-button--PaddingLeft); 
    font-size: var(--pf-c-button--FontSize); 
    font-weight: var(--pf-c-button--FontWeight); 
    line-height: var(--pf-c-button--LineHeight); 
    text-align: center; 
    white-space: nowrap; 
    user-select: none; 
    border: 0; 
    border-radius: var(--pf-c-button--BorderRadius);
} 

button:not(:disabled),[type="button"]:not(:disabled) { 
    cursor: pointer;
} 

.pf-c-button.pf-m-primary { 
    color: var(--pf-c-button--m-primary--Color); 
    background-color: var(--pf-c-button--m-primary--BackgroundColor);
} 

.pf-c-empty-state__content > .pf-c-button.pf-m-primary  { 
    margin-top: var(--pf-c-empty-state__primary--MarginTop);
} 

.pf-c-button:after { 
    position: absolute; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    pointer-events: none; 
    content: ""; 
    border: var(--pf-c-button--after--BorderWidth) solid; 
    border-color: var(--pf-c-button--after--BorderColor); 
    border-radius: var(--pf-c-button--after--BorderRadius);
} 

.pf-c-button:hover { 
    --pf-c-button--after--BorderWidth: var(--pf-c-button--hover--after--BorderWidth); 
    text-decoration: none;
} 

.pf-c-button.pf-m-primary:hover { 
    --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--hover--Color); 
    --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--hover--BackgroundColor);
} 



</style>
