import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createCampaign = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/campaigns`,
    payload,
    config
  )
}

const _getCampaigns = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/campaigns?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/campaigns`


  return Request.get(
    url,
    config
  )
}

const _deleteCampaign = campaignId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/campaigns/${campaignId}`, config)
}

const _getCampaign = campaignId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/campaigns/${campaignId}?populate[0][path]=group&populate[0][select]=_id name&populate[1][path]=products&populate[2][path]=project&populate[2][select]=_id name&populate[3][path]=items&populate[4][path]=availability`, config)
}
const _updateCampaign = (campaign) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/campaigns/${campaign._id}`, campaign, config)
}

const _updateProductCampaign = (campaignId,params) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/campaigns/${campaignId}`, params, config)
}

export default {
  updateCampaign: _updateCampaign,
  deleteCampaign : _deleteCampaign, 
  getCampaign : _getCampaign, 
  getCampaigns : _getCampaigns,
  createCampaign : _createCampaign,
  updateProductCampaign: _updateProductCampaign
}
