import CommonService from '../../services/common'
import i18n from "../../i18n";

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  uploadMedia({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
    })
  },

  // eslint-disable-next-line no-unused-vars
  getNews ({ commit },params) {
    return new Promise((resolve, reject) => { 
      CommonService.getNews(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getRecommendationStrategies({ commit }){
    return [
      {
        id: "user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_user_reco'),
      },
      {
        id: "collaborative",
        name: i18n.t('personalizations.experience_variable_strategy_collaborative')
      },
      {
        id: "similarity",
        name: i18n.t('personalizations.experience_variable_strategy_similarity'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing']
      },
      {
        id: "viewed_together",
        name: i18n.t('personalizations.experience_variable_strategy_viewed_together'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "purchased_together",
        name: i18n.t('personalizations.experience_variable_strategy_purchased_together'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "popularity",
        name: i18n.t('personalizations.experience_variable_strategy_popularity')
      },
      {
        id: "recently_viewed",
        name: i18n.t('personalizations.experience_variable_strategy_recently_viewed')
      },
      {
        id: "recently_viewed_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_viewed_similar')
      },
      {
        id: "liked_similar",
        name: i18n.t('personalizations.experience_variable_strategy_liked_similar')
      },
      {
        id: "recently_purchased_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_purchased_similar')
      },
      {
        id: "user_affinity",
        name: i18n.t('personalizations.experience_variable_strategy_user_affinity')
      },
      {
        id: "complete_the_look",
        name: i18n.t('personalizations.experience_variable_strategy_complete_the_look'),
        pages_restricted: ['cart', 'checkout', 'category','home','product_listing','search']
      },
      {
        id: "complementary",
        name: i18n.t('personalizations.experience_variable_strategy_complementary'),
        pages_restricted: ['checkout', 'category','home','product_listing','search']
      },
      {
        id: "top-sellers",
        name: i18n.t('personalizations.experience_variable_strategy_top_sellers'),
      },
      {
        id: "most-viewed",
        name: i18n.t('personalizations.experience_variable_strategy_most_viewed'),
      },
      {
        id: "last_viewed_category",
        name: i18n.t('personalizations.experience_variable_strategy_last_category_viewed'),
      },
      {
        id: "popularity_category",
        name: i18n.t('personalizations.experience_variable_strategy_category_popularity'),
        pages_restricted: ['cart','checkout','home','product_listing','search']
      },
      {
        id: "recently_searched_similar",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_similar'),
      },
      {
        id: "recently_searched_popular",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_popular'),
      },
      {
        id: "recently_searched_user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_user_reco'),
      },
      {
        id: "recently_searched_user_reco",
        name: i18n.t('personalizations.experience_variable_strategy_recently_searched_user_reco'),
      },
    ]
  }
  
}