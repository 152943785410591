
import InvitationService from '../../services/invitations'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createInvitation ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
        InvitationService.createInvitation(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating group'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getInvitations ({ commit },params) {

    return new Promise((resolve, reject) => { 
        InvitationService.getInvitations(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getInvitation ({ commit }, id) {

    return new Promise((resolve, reject) => { 
        InvitationService.getInvitation(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteInvitation ({ commit }, id) {
    return new Promise((resolve, reject) => { 
        InvitationService.deleteInvitation(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateInvitation ({ commit }, invitation) {

    return new Promise((resolve, reject) => { 
        InvitationService.updateInvitation(invitation).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  uploadInvitations ({ commit }, payload) {
    return new Promise((resolve, reject) => { 
        InvitationService.uploadInvitations(payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}