import CustomerService from '../../services/customers'
import i18n from "../../i18n";

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  
  // eslint-disable-next-line no-unused-vars
  getCustomers ({ commit },params) {

    return new Promise((resolve, reject) => { 
      CustomerService.getCustomers(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCustomersCountries ({ commit },params) {

    return new Promise((resolve, reject) => { 
      CustomerService.getCustomersCountries(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

   // eslint-disable-next-line no-unused-vars
   getRFMSegments ({ commit },params) {

    return new Promise((resolve, reject) => { 
      CustomerService.getRFMSegments(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  getCustomerFixedProperties ({ commit },params) {

    const properties = [];
    properties.push({ type : 'text', name: i18n.t("customers.name"), column_name: 'name'})
    properties.push({ type : 'text', name: i18n.t("customers.email"), column_name: 'email'})
    properties.push({ type : 'text', name: i18n.t("customers.phone"), column_name: 'phone'})
    properties.push({ type : 'date', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_date"), column_name: 'gs_customer_last_purchase_date'})
    properties.push({ type : 'number', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_purchase_amount"), column_name: 'gs_customer_last_purchase_amount'})
    properties.push({ type : 'number', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_items_count"), column_name: 'gs_customer_cart_items_count'});
    properties.push({ type : 'number', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_cart_total_amount"), column_name: 'gs_customer_cart_items_amount'});
    properties.push({ type : 'date', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_view_date"), column_name: 'gs_customer_last_visit_date'});
    properties.push({ type : 'date', name: i18n.t("journeys.journey_workflow_components_channel_variants_dynamic_vars_last_favorite_date"), column_name: 'gs_customer_last_like_product_date'});
    
    return properties;
  }
}