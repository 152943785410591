import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _login = payload => {

    return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/auth/login`,
    payload
  )
}

const _logout = () => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    `${window.config.VUE_APP_BACKEND}/api/auth/logout`,
    config
  )
}

const _createUser = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/auth/register`,
    payload,
    config
  )
}

const _getUsers = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/users?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/users`


  return Request.get(
    url,
    config
  )
}

const _deleteUser = userId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/users/${userId}`, config)
}

const _getUser = userId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/users/${userId}`, config)
}
const _updateUser = (user) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/users/${user._id}`, user, config)
}

const _updateUserProfile = (user) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/auth/profile`, user, config);
}

const _getTenants = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/tenants?${params}`,
    config
  )
}

const _createTenant = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/tenants`,
    payload,
    config
  )
}

const _removeTenant = tenantId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/tenants/${tenantId}`, config)
}

const _getCompany = companyId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/companies/${companyId}`, config)
}

const _updateCompany = (company) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/companies/${company._id}`, company, config)
}
const _requestPasswordReset= payload => {

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/auth/request-password-reset`,
    payload
  )
}

const _passwordReset= payload => {

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/auth/password-reset`,
    payload
  )
}


export default {
  login : _login,
  logout : _logout,
  updateUser : _updateUser,
  updateUserProfile : _updateUserProfile,
  deleteUser : _deleteUser, 
  getUser : _getUser, 
  getUsers : _getUsers,
  createUser : _createUser,
  
  getCompany: _getCompany,
  updateCompany: _updateCompany,
  getTenants: _getTenants,
  createTenant:_createTenant,
  removeTenant:_removeTenant,

  requestPasswordReset: _requestPasswordReset,
  passwordReset: _passwordReset
  //getUser : _getUser,
  
  
}
