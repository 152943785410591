<script>

import _debounce from 'lodash.debounce';
import Multiselect from "vue-multiselect";
import Drawer from "vue-simple-drawer";
import Templates from  "@/components/personalizations/templates";

import {
  templateMethods
} from "@/state/helpers";

export default {
	data() {
		return {
			subtypes: [
        {id: 'web', label: this.$t('template_store.subtype_web')},
        {id: 'recommendations', label: this.$t('template_store.subtype_recommendations')},
        {id: 'feedback', label: this.$t('template_store.subtype_feedback')},
        {id: 'notifications', label: this.$t('template_store.subtype_notifications')},
        {id: 'pop_up', label: this.$t('template_store.subtype_pop_up')},
        {id: 'custom_code', label: this.$t('template_store.subtype_custom_code')},
        {id: 'API', label: this.$t('template_store.subtype_API_reco')},
        {id: 'API_SIMPLE', label: this.$t('template_store.subtype_API')},
      ],
      goals: [
        {id: 'engagement', label: this.$t('template_store.goals_engagement')},
        {id: 'increase_revenue', label: this.$t('template_store.goals_increase_revenue')},
        {id: 'conversion_rate', label: this.$t('template_store.goals_conversion_rate')},
        {id: 'aov', label: this.$t('template_store.goals_aov')},
        {id: 'feedback', label: this.$t('template_store.goals_feedback')},
        {id: 'decrease_cart_abandonment', label: this.$t('template_store.goals_decrease_cart_abandonment')},
      ],
      auxSubtype:null,
      auxGoal:null,
      templates:[],
      query:'',
      openRightDrawer: false
    };
	},
  components:{ Multiselect, Drawer, Templates },
  methods: {
    ...templateMethods,

    rightDrawer() {
          this.openRightDrawer = !this.openRightDrawer;
      },
    searchTemplates(){
      this.loadTemplates()
    },
    onTemplateClicked(template){
      this.$emit('selectStoreTemplate',template)
    },
    onMyTemplateSelected(template){
      this.openRightDrawer = false;
      this.$emit('selectStoreTemplate',template)
    },
    onMyTemplatesClicked(){
      this.openRightDrawer = true;
    },
    loadTemplates(){
      this.templates = [];
      const loader = this.$loading.show();
      const params = {
        q: `where[type]=${this.type}&limit=100&where[lang]=es`
      }

      if(this.auxSubtype){
        params.q+= `&where[subtype]=${this.auxSubtype.id}`
      }

      if(this.auxGoal){
        params.q+= `&where[goal]=${this.auxGoal.id}`
      }

      if(this.query){
        params.q+= `&where[name][$regex]=.*${this.query}.*&where[name][$options]=i`
      }
      
      this.getTemplatesStore(params).then((res)=>{
        this.templates = res.data;
        if(!this.showFromScratch)
          this.templates = this.templates.filter(t=>t.scratch==false);
        else
          this.templates.sort((a,b)=> Number(b.scratch) - Number(a.scratch))
        
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      }).finally(()=>{
        loader.hide()
      })
    }
	},

	mounted() {
    this.loadTemplates()
	},
  created(){
    this.debounceTemplates = _debounce(this.searchTemplates.bind(this), 1000);
  },
	props: {
    type : String,
		subType : String,
    hideSubtypeFilter: Boolean,
    hideGoalsFilter: Boolean,
    showFromScratch: Boolean,
    showUseButton: Boolean,
    showMyTemplates:Boolean,
    hideTypeBadge: Boolean
	},
	emits: ['selectStoreTemplate'],
  watch:{
    subType : {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.auxSubtype = this.subtypes.filter(s=>s.id == newVal).length > 0 ? this.subtypes.filter(s=>s.id == newVal)[0] : null 
          this.loadTemplates();
        }
      }

    },
    type : {
      immediate: true,
      handler(newVal){
        
        if(!newVal){
          this.type = 'personalization'
        }
      }

    }
  }
};
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-4">
          <div class="search-box me-2 mb-0 d-inline-block">
            <div class="position-relative">
                <input
                type="text"
                class="form-control"
                :placeholder="$t('common.search')"
                @input="debounceTemplates($event.target.value)"
                v-model="query"
                />
                <i class="bx bx-search-alt search-icon"></i>
            </div>
            </div>
          </div>
            <div class="col text-sm-end d-flex flex-row-reverse">
              
              <multiselect
                  :options="goals" 
                  v-model="auxGoal"
                  track-by="id" 
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  class="form control w-25"
                  :placeholder="$t('template_store.filter_goal')"
                  v-if="!hideGoalsFilter"
                />

                <multiselect
                  :options="subtypes" 
                  v-model="auxSubtype"
                  track-by="id" 
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  class="form control w-25 me-2"
                  :placeholder="$t('template_store.filter_type')"
                  v-if="!hideSubtypeFilter"
                  :show-labels="false"
                  @input="loadTemplates"
                />

                <button v-if="showMyTemplates" class="btn btn-link" @click="onMyTemplatesClicked">
                  {{$t('personalizations.experience_variant_my_templates')}}
                </button> 
            </div>
    </div>
    <div class="row" v-if="type=='email'">
      <div class="col-lg-6 col-xl-2 mb-3" v-for="template in templates" :key="template._id"  v-on:click="onTemplateClicked(template)" >
        <div class="card interact border" style="overflow: hidden;">
          <img :src="template.img" alt="Card image" class="card-img-top" style="
              width: 100%;       
              height: 250px;     
              object-fit: cover !important; 
              overflow: hidden;  
              object-position: top;">
          <div class="my-2 mx-2">
            <strong class="small">{{template.name}}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-6 col-xl-2 mb-3" v-for="template in templates" :key="template._id" >
        <b-card class="h-100 interact border" no-body :img-src="template.img" img-alt="Card image" img-top  img-height="120" v-on:click="onTemplateClicked(template)">
            <b-card-body class="p-2 pb-0">
                <b-card-title>
                    <h5 class="card-title">{{template.name}}</h5>
                </b-card-title>
                <b-card-text class="small">{{template.description}}
                  </b-card-text>
                    <span class="badge badge-soft-primary me-2" v-if="!hideTypeBadge"> {{$t(`template_store.subtype_${template.subtype}`)}}</span>
                    <span class="badge badge-soft-success" v-for="goal in template.goals" :key="goal"> <i class="bx bx-trending-up" v-if="goal == 'engagement' || goal == 'increase_revenue' || goal == 'increase_revenue'"></i> <i class="bx bx-trending-down" v-if="goal == 'decrease_cart_abandonment'"></i> {{goal}}</span>
              </b-card-body>
              <button class="btn btn-sm text-center btn-primary mx-2 mb-2" v-if="showUseButton">{{$t('template_store.use')}}</button>
            </b-card>
      </div>
    </div>
    <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <Templates ref="TemplatesComponent" :channel="subtype == 'API' || subtype == 'API_SIMPLE'  ? 'API' : 'web'" v-if="openRightDrawer" @selectTemplate="onMyTemplateSelected" :type="subType" ></Templates>
      </Drawer>
  </div>
</template>

<style scoped>
.interact{
  cursor: pointer;
}

.card-img-top{
  object-fit: contain !important;
  background-color: #f8f8fb !important;
}
</style>