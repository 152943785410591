<script>

import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh.js';

import {
	
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		
	},
	data() {
		return {
			submitted:false,
			jsCodeMirror: null,
			jsCode:null,
      config:{
				value : {
					name: '',
					script:"//context is the journey's context where you can store variables or change them\nfunction (context){\n\n\treturn context;\n    \n}"
				}
			}
		};
	},
	methods: {
		getScriptConfig(){
			this.config.value.script = this.jsCodeMirror.getValue();
      return this.config;
    },

    setScriptConfig(config){
      this.config = config || this.config;
			this.jsCodeMirror.setValue(this.config.value.script);
    },
		initializeCodeMirror() {
        
				this.jsCodeMirror = CodeMirror.fromTextArea(this.$refs.jsTextArea, {
					mode: 'text/javascript',
					theme: 'material',
					lineNumbers: true,
					lineWrapping: true,
					value: this.jsCode,
					viewportMargin: Infinity,
					autorefresh: true
				});
				this.jsCodeMirror.refresh();
			},
},

	mounted() {
		this.initializeCodeMirror();
	},
	props: {
		
	},
	emits: [],
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <label>{{$t('journeys.journey_workflow_components_apis_name')}}</label>
				<input class="form-control" v-model="config.value.name"/>
      </div>
    </div>
		<div class="row">
			<div class="col">
				<textarea ref="jsTextArea"></textarea>
			</div>
		</div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>