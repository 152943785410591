import IntegrationsService from '../../services/integrations'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createIntegration ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
        IntegrationsService.createIntegration(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating integration'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getIntegrations ({ commit },params) {

    return new Promise((resolve, reject) => { 
      IntegrationsService.getIntegrations(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteIntegration ({ commit }, id) {
    return new Promise((resolve, reject) => { 
      IntegrationsService.deleteIntegration(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateIntegration ({ commit }, integration) {

    return new Promise((resolve, reject) => { 
      IntegrationsService.updateIntegration(integration).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },


  // eslint-disable-next-line no-unused-vars
  createGoShopsIntegration ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
        IntegrationsService.createGoShopsIntegration(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating integration'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getGoShopsIntegration ({ commit },params) {

    return new Promise((resolve, reject) => { 
      IntegrationsService.getGoShopsIntegration(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteGoShopsIntegration ({ commit }, id) {
    return new Promise((resolve, reject) => { 
      IntegrationsService.deleteGoShopsIntegration(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateGoShopsIntegration ({ commit }, integration) {

    return new Promise((resolve, reject) => { 
      IntegrationsService.updateGoShopsIntegration(integration).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  ssoGoShopsIntegration ({ commit }, id) {
    return new Promise((resolve, reject) => { 
      IntegrationsService.ssoGoShopsIntegration(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}