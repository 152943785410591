<script>

import Multiselect from "vue-multiselect";

import {
	propertyMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect
	},
	data() {
		return {
			submitted:false,
      types:[
        { id: 'interaction',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction')},
        { id: 'session_event',label : this.$t('journeys.journey_workflow_components_trigger_type_sesion_event')},
        { id: 'date',label : this.$t('journeys.journey_workflow_components_trigger_type_dynamic_date')},
        { id: 'custom',label : 'Custom'},
        //{ id: 'enter_exit_audience',label : this.$t('journeys.journey_workflow_components_trigger_type_audience_change')},
        //{ id: 'property_change',label : this.$t('journeys.journey_workflow_components_trigger_type_customer_property_change')},
      ],
      interactionsTypes:[
        { id: 'purchase',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_purchase')},
        { id: 'cart',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_cart')},
        { id: 'like',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_like')},
        { id: 'rate',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_rate')},
        { id: 'view',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_view')},
        { id: 'remove_cart',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_remove_cart')},
      ],
      sessionEventTypes:[
        { id: 'login',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_login')},
        { id: 'logout',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_logout')},
        { id: 'suscribe',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_subscribe')},
      ],
      aux_type: { id: 'interaction',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction')},
      aux_InteractionType: null,
      aux_SessionType: null,
      auxPropertyDate: null,
      config: null,
      properties_crm:[],

		};
	},
	methods: {
    ...propertyMethods,

		onTypeSelected(type){
      this.config.type = type;
      this.config.value = {}
    },

    onInteractionTypeSelected(interaction){
      if(!this.config.value)
        this.config.value = {}
      this.config.value.interaction = interaction;
    },

    onSessionTypeSelected(session){
      this.config.value.session_event = session;
    },
    oPropertySelected(property){
      if(!this.config.value)
        this.config.value = {}
      this.config.value.property = property;
    },
    getTriggerConfig(){
      return this.config;
    },

    setTriggerConfig(config){
      this.config = config;
    },

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        const pr = properties.filter(p=> p.entity == 'customer');
        this.properties_crm = pr.filter(p=>p.type=='date');
        if(this.config.value?.property){
          this.auxPropertyDate = this.config.value?.property;
        }
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
	},

	mounted() {
		this.loadProperties();
	},
	props: {
		
	},
	emits: [],
  watch:{
    config: {
      inmmediate: true,
      handler(newVal){
        if(newVal){

          this.aux_type = this.config.type || this.aux_type;
          this.aux_InteractionType = this.config.value?.interaction || null
          this.onTypeSelected(this.aux_type);
          this.aux_SessionType = this.config.value?.session_event || null
          this.auxPropertyDate = this.config.value?.property || null;
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label class="mb-0">{{$t('journeys.journey_workflow_components_trigger_type')}}*</label>
        <multiselect
          :options="types"
          track-by="id"
          label="label"
          v-model="aux_type"
          selectLabel=""
          deselectLabel=""
          :allowEmpty="false"
          @select="onTypeSelected"
        />
      </div>
    </div>

    <div class="row" v-if="aux_type?.id=='interaction'">
      <div class="col">
        <label class="mb-0">{{$t('journeys.journey_workflow_components_trigger_type_interaction')}}*</label>
        <multiselect
          :options="interactionsTypes"
          track-by="id"
          label="label"
          v-model="aux_InteractionType"
          selectLabel=""
          deselectLabel=""
          :allowEmpty="false"
          :placeholder="$t('journeys.journey_workflow_components_trigger_type_interaction_placeholder')"
          @select="onInteractionTypeSelected"
        />
      </div>
    </div>
    <div class="row" v-if="aux_type?.id=='session_event'">
      <div class="col">
        <label>{{ $t('journeys.journey_workflow_components_trigger_type_sesion_event')}}*</label>
        <multiselect
          :options="sessionEventTypes"
          track-by="id"
          label="label"
          v-model="aux_SessionType"
          selectLabel=""
          deselectLabel=""
          :placeholder="$t('journeys.journey_workflow_components_trigger_type_sesion_event_placeholder')"
          @select="onSessionTypeSelected"
        />
      </div>
    </div>
    <div class="row" v-if="aux_type?.id=='date'">
      <div class="col">
        <label>{{ 'Propiedad de cliente'}}*</label>
        <multiselect
          :options="properties_crm"
          track-by="id"
          label="name"
          selectLabel=""
          deselectLabel=""
          placeholder="Selecciona la propiedad"
          v-model="auxPropertyDate"
          @input="oPropertySelected"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>