<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import InteractionQueryBuilderRule from "../../widgets/queryBuilderInteractionsRule.vue"

import {
  customerMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,

      numberOperatorValues: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
      ],

      booleanValues: [
        {
          id: true,
          label: this.$t("common.yes"),
        },
        {
          id: false,
          label: this.$t("common.no"),
        },
      ],
      
      timeRangesOptions:[
      {
          id: "mins",
          label: this.$t('journeys.journey_workflow_components_wait_time_minutes'),
        },
        {
          id: "hours",
          label: this.$t('journeys.journey_workflow_components_wait_time_hours'),
        },
        {
          id: "weeks",
          label: this.$t('journeys.journey_workflow_components_wait_time_weeks'),
        },
        {
          id: "days",
          label: this.$t('journeys.journey_workflow_components_wait_time_days'),
        },
      ],
      frequencyOperatorsValues: [
      {
          id: "last",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_at_least'),
        },
        {
          id: "before_last",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_at_before_least'),
        },
      ],
      dateOperatorsValues: [
        {
          id: "last",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_at_least'),
        },
        {
          id: "before_last",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_at_before_least'),
        },
        {
          id: "<",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_before_day'),
        },
        {
          id: ">",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_after_day'),
        },
        {
          id: "=",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_equal'),
        },
        
      ],

      customerFields:[
        {
          group: "Customer",
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_affinity"),
              value: "affinity",
              operator: '',
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_affinity_highest"), "id" : "highest"},
                {"label" : "Top 3", "id" : "top_3"},
                {"label" : "Top 5", "id" : "top_5"},
                {"label" : "Top 10", "id" : "top_10"},
              ],
              term: {
                component: "affinity_page_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_audience"),
              value: "audience",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "audiences_component",
              }
            },
            {
              label: "Cluster RFM",
              value: "cluster_rfm",
              operator: 'in',
              term: {
                component: "rfm_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_customer_properties"),
              value: "customer_field",
              operator: '',
              term: {
                component: "customer_property_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_country"),
              value: "country",
              operator: 'in',
              term: {
                component: "country",
              }
            },
          ]
        },
        {
          group: "Comportamiento",
          values:[
            {
              label: this.$t("audiences.audience_conditions_fields_purchases"),
              value: "purchases",
              operator: '',
              operators: [
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num_with_frequency",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_added_to_cart"),
              value: "added_to_cart",
              operator: '',
              operators: [
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num_with_frequency",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_added_to_like"),
              value: "added_to_like",
              operator: '',
              operators: [
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
                {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
                {"label" :  this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num_with_frequency",
              }
            },
          ]
        },
        {
          group: 'Custom Interactions',
          id: 'custom_interactions',
          values: []
        },
        {
          group: this.$t("personalizations.experience_fields_group_time"),
          values: [
          {
            label: this.$t("personalizations.experience_fields_dates"),
            value: "dates",
            operator: 'in',
            operators: [
              {"label" : this.$t("personalizations.experience_fields_before") , "id" : "<"},
              {"label" :this.$t("personalizations.experience_fields_after") , "id" : ">"},
              {"label" : this.$t("personalizations.experience_fields_equal_date") , "id" : "="},
            ],
            term: {
              component: "dates_rage",
            }
          },
            {
              label: this.$t("personalizations.experience_fields_days"),
              value: "days",
              operator: 'in',
              term: {
                component: "multiselect",
                values: [
                  {
                    id:0,
                    label: this.$t("availability.availability_days_monday")
                  },
                  {
                    id:1,
                    label: this.$t("availability.availability_days_tuesday")
                  },
                  {
                    id:2,
                    label: this.$t("availability.availability_days_wednesday")
                  },
                  {
                    id:3,
                    label: this.$t("availability.availability_days_thursday")
                  },
                  {
                    id:4,
                    label: this.$t("availability.availability_days_friday")
                  },
                  {
                    id:5,
                    label: this.$t("availability.availability_days_saturday")
                  },
                  {
                    id:6,
                    label: this.$t("availability.availability_days_sunday")
                  },
                ]
              }
            },
            {
              label:this.$t("personalizations.experience_fields_hours"),
              value: "hours",
              operator: 'in',
              term: {
                component: "multiselect",
                values: [
                  {
                    id:0,
                    label: this.$t("personalizations.experience_fields_hours_morning")
                  },
                  {
                    id:1,
                    label: this.$t("personalizations.experience_fields_hours_afternoon")
                  },
                  {
                    id:2,
                    label: this.$t("personalizations.experience_fields_hours_night")
                  },
                ]
              }
            }
            ]
          }
      ],
			selectedField: null,
      operators:[],
      aux_times: {
        operator: '>'
      },
      aux_node: {
        operator: '',
        field:''
      },
      countries:[],
      aux_bool : null,
      aux_frequency: {},
      aux_frequency_range:{},
      aux_frequency_operator:{}
    };
  },
  components: { Multiselect, InteractionQueryBuilderRule},
  mounted() {},
    methods: {
      ...customerMethods,

        getCustomerFieldByValue(value){
          
          let selectedField = null

          this.customerFields.forEach((a)=>{
              if(a.values && a.values.length > 0){
                a.values.forEach((v)=>{
                  if(v.value == value){
                    selectedField = v;
                  }
                })
              }
          })

          return selectedField
        },

        loadCountries(){
          //const loader = this.$loading.show();
          
          this.getCustomersCountries().then((countries)=>{
            this.countries = countries;
          }).catch((ex)=>{
            // eslint-disable-next-line no-console
            console.log(ex);
          }).finally(()=>{
            //loader.hide();
          })
        },

        onCustomerFiedSelected(type, isUpdate=false){
          if(isUpdate){
            type = this.getCustomerFieldByValue(type); 
            this.aux_node.field = type;  
          }

          this.operators= type.operators || [];

          this.selectedField = type;
          this.node.field = type.value;

          if(type.value == 'country'){
            this.loadCountries();
          }

          if(!isUpdate)
            this.node.operator = type.operator;

          if(this.selectedField.term.component == 'products_component' || this.selectedField.term.component == 'custom_interaction_component'){
            if(!isUpdate){
                this.node.term= {
                type:"and",
                children: [
                  {
                    field: null,
                    operator: null,
                    term: null
                  }
                ]
              },
              this.node.times = {
                operator: '>',
                value: 1
              }
            }
          }else if (this.selectedField.term.component == "input_num_with_frequency"){
            if(!isUpdate){
              this.node.term = '',
              this.node.frequency = {};
              this.aux_frequency= {};
              this.aux_frequency_range = {};
              this.aux_frequency_operator = {};
              this.aux_node.operator = type.operator;
            }
              
          }else{
            if(!isUpdate){
              this.node.term = '',
              this.node.times = type.times || {}
              delete this.node.frequency;
            }
        }
      },
			isNodeValid(){
				return this.node.field && this.node.operator && this.node.term;
			},
			onRemoveCondition(){
				this.$emit('removeCondition',this.index);
			},
      onAudienceSelected(node, isUpdate = false){
        if(!isUpdate){
          node.operator = null;
          node.term = null
        }
      },
      onTimesOperatorSelected(time, isUpdate=false){
        if(!isUpdate){
          this.node.times.operator = time.id;
        }else{
          this.aux_times.operator = this.selectedField?.operators?.filter(o=> o.id== time.operator).length > 0 ? this.selectedField?.operators?.filter(o=> o.id== time.operator) [0] : null ;
        }
      },
      onAffinityTypeSelected(node, isUpdate = false){
        if(!isUpdate){
          node.operator = null;
          node.term = null
        }
      },
      onAffinityOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.operator = operator.id;
        }else{
          this.aux_node.operator = this.selectedField.operatorsFields.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields.filter(o=> o.id== operator) [0] :null ;
        }
      },

      onTimeRangeSelected(time,isUpdate=false ){
        if(!isUpdate){
          this.node.frequency = time.id;
        }
        else{
          this.aux_node.frequency = this.timeRangesOptions.filter(o=> o.id== time).length > 0 ? this.timeRangesOptions.filter(o=> o.id== time) [0] :null ;
        }
      },

      onDateOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.operator = operator.id;
        }
        else{
          this.aux_node.operator = this.dateOperatorsValues.filter(o=> o.id== operator).length > 0 ? this.dateOperatorsValues.filter(o=> o.id== operator) [0] :null ;
        }
      },
      onNumberOperatorSelected(operator,isUpdate=false){
        if(!isUpdate){
          this.node.operator = operator.id;
        }
        else{
          this.aux_node.operator = this.numberOperatorValues.filter(o=> o.id== operator).length > 0 ? this.numberOperatorValues.filter(o=> o.id== operator) [0] :null ;
        }
      },
      onCustomerPropertySelected(node, isUpdate = false){
        if(node){
          if(!isUpdate){
            node.operator = this.operatorFromCustomerPropertyField(node);
            this.aux_node.operator = null;
            node.term = null
          }
        }else{
          node.operator = null;
          this.aux_node.operator = null;
          node.term = null
        }
      },
      operatorFromCustomerPropertyField(field){
        if(field && field.type){
          switch (field.type){
            case 'list':
              return 'in';
            case 'boolean':
              return '=';
            case 'text':
              return 'like';
            case 'number':
              return '>';
            default:
              return '' 
          }
        }
        return '';
      },
      onFrequencyTimeRangeSelected(f, isUpdate= false){
        if(!isUpdate){
          this.node.frequency.range = f?.id;
        }else{
          this.aux_frequency_range = this.timeRangesOptions.filter(t=> t.id == f).length > 0 ? this.timeRangesOptions.filter(t=> t.id == f)[0] : null
        }
      },
      onFrequencyOperatorSelected(operator, isUpdate= false){
        if(!isUpdate){
          this.node.frequency.operator = operator?.id
        }else{
          this.aux_frequency_operator = this.frequencyOperatorsValues.filter(t=> t.id == operator).length > 0 ? this.frequencyOperatorsValues.filter(t=> t.id == operator)[0] : null
        }
      }
    },
  props: {
    node: Object,
    index: Number,
    properties_products: Array,
    properties_crm: Array,
    affinity_Types: Array,
    audiences: Array,
    categories: Array,
    rfm_segments: Array,
    custom_interactions: Array

  },
	emits:["removeCondition"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onCustomerFiedSelected(newVal.field,true);
          
          if(newVal.times){
            this.onTimesOperatorSelected(newVal.times,true);
          }
          
          if(newVal.affinity_type){
            this.onAffinityTypeSelected(newVal.affinity_type,true);
            this.onAffinityOperatorSelected(newVal.operator, true);
          }

          if(newVal.customer_field){
            this.onCustomerPropertySelected(newVal,true);
            if(newVal.customer_field.type == 'date'){
              this.onDateOperatorSelected(newVal.operator, true);
            }else if(newVal.customer_field.type == 'number'){
              this.onNumberOperatorSelected(newVal.operator, true);
            }
          }

          if(newVal.frequency && newVal.frequency.range){
            this.onFrequencyOperatorSelected(newVal.frequency.operator, true);
            this.onNumberOperatorSelected(newVal.operator,true)
            this.onFrequencyTimeRangeSelected(newVal.frequency.range, true)
            
          }else if(newVal.frequency){
            this.onTimeRangeSelected(newVal.frequency, true);
          }
        }
      }
    },
    custom_interactions:{
      immediate:true,
      handler(newVal){
        
        if(newVal && newVal.length > 0){
          const field =this.customerFields.filter(a=>a.id=='custom_interactions')
          newVal.forEach((c)=>{
            field[0].values.push({
              label: c.name,
              value: c.key,
              parameters: c.parameters,
              term: {
                component: "custom_interaction_component",
              }
            })
          });

          if(this.node && this.node.field){
            this.onCustomerFiedSelected(this.node.field,true);
          }
        }
      }
    }
  }
}

 </script>

<template>
    
	<div class="row">
				<div class="col-sm-3 me-0">
						<multiselect
						:options="customerFields"
						group-values="values"
						group-label="group"
						track-by="value"
						label="label"
						v-model="aux_node.field"
						selectLabel=""
						deselectLabel=""
            :placeholder="$t('audiences.audience_conditions_field_placeholder')"
						@select="onCustomerFiedSelected"
						/>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'boolean'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="booleanValues" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              :allowEmpty="false"
              class="form control form-control-sm w-50"/>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'text'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <b-form-input type="text" class="form-control w-50 me-2" min="1" v-model="node.term" placeholder="Inlcuye"  ></b-form-input>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'audiences_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex ps-0">
            <div class="w-25">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allowEmpty="false"
                    class="form control form-control-sm w-100 ps-0"/>
                </div>
              </div>
              <multiselect
                :options="audiences" 
                v-model="node.term"
                track-by="_id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                class="form control form-control-sm w-100"
              />
          </div>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'multiselect'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="selectedField.term.values || []" 
              v-model="node.term"
              selectLabel=""
              track-by="id"
              label="label"
              deselectLabel=""
              :multiple="true"
              class="form control form-control-sm w-50"
            />
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'input_num'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
					<div class="w-50 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="aux_times.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  @input="onTimesOperatorSelected"
                  />
              </div>
            </div>
            <div class="w-25 me-2">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
              </div>
            </div>
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'country'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
            :options="countries"
            v-model="node.term"
            track-by="id"
            label="name"
            selectLabel=""
            deselectLabel=""
            :multiple="true"
            :placeholder="$t('audiences.audience_conditions_field_country_placeholder')"
            class="form control form-control-sm w-50"/>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'affinity_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex ps-0">
            <multiselect
              :options="affinity_Types" 
              v-model="node.affinity_type"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="form control form-control-sm w-25 ps-0"
              @input="onAffinityTypeSelected(node)"
              />
              <div v-if="node.affinity_type && node.affinity_type.type != 'product_category'" class="w-50 me-4">
                <multiselect
                :options="node.affinity_type.values" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control form-control-sm w-100 ps-0"/>
              </div>
              <div v-if="node.affinity_type && node.affinity_type.type == 'product_category'" class="w-50 me-4">
                <multiselect
                :options="categories" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control form-control-sm w-100"/>
              </div>
              <div class="w-25" v-if="node.affinity_type">
                <div>
                  <multiselect
                    :options="selectedField?.operatorsFields || []" 
                    v-model="aux_node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control form-control-sm w-100"
                    @input="onAffinityOperatorSelected"/>
                </div>
              </div>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'rfm_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <div class="pt-2">
                <div class="d-inline-flex align-items-baseline">
                  <p class="small ms-0">{{$t("audiences.audience_conditions_rfm_clusters")}}</p>
                </div>
              </div>
            <multiselect
              :options="rfm_segments"
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="true"
              :placeholder="$t('audiences.audience_conditions_rfm_clusters_placeholder')"
              class="form control form-control-sm w-50"/>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'dates_rage'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="w-50 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  />
              </div>
            </div>
            <div class="w-25 me-2">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="date" class="form-control w-100 me-2" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
        </div>
        
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'customer_property_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="properties_crm" 
              v-model="node.customer_field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="form control form-control-sm w-25"
              @input="onCustomerPropertySelected(node)"
          />
          <div v-if="node.customer_field && node.customer_field.type == 'list'" class="w-50 me-4">
                <multiselect
                :options="node.customer_field.values" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                class="form control form-control-sm w-100"/>
              </div>
          <div v-if="node.customer_field && node.customer_field.type == 'text'" class="w-50 me-4">
            <b-form-input type="text" class="form-control form-control w-100 me-2 mt-1" min="1" v-model="node.term" placeholder="Regex"  ></b-form-input>
          </div>
          <div v-if="node.customer_field && node.customer_field.type == 'boolean'" class="w-50 me-4">
              <multiselect
              :options="booleanValues" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control form-control-sm w-100"/>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'number'" class="w-50 me-4">
              <div class="d-inline-flex">
                  <div>
                    <div class="d-inline-flex align-items-baseline">
                      <multiselect
                          :options="numberOperatorValues" 
                          v-model="aux_node.operator"
                          track-by="id"
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :multiple="false"
                          class=" pt-1 me-3 w-100"
                          @input="onNumberOperatorSelected"/>
                        <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.term" ></b-form-input> <p class="small"></p>
                    </div>
                  </div>
              </div>
          </div>
          <div v-if="node.customer_field && node.customer_field.type == 'date'" class="w-25 d-inline-flex align-items-baseline pt-1 ps-1" >
            <p class="small me-2"> fue </p>
              <multiselect
              :options="dateOperatorsValues" 
              v-model="aux_node.operator"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control w-100"
              @input="onDateOperatorSelected"/>
          </div>

          <div class="pt-1" v-if=" node.customer_field && node.customer_field.type == 'date' && node.operator == 'last' || node.operator == 'before_last'" style="width: 100px;">
            <b-form-input type="number" class="form-control me-2" v-model="node.term" ></b-form-input>
          </div>
          <div v-if=" node.customer_field && node.customer_field.type == 'date' && node.operator == 'last' || node.operator == 'before_last'" class="w-25 pt-1">
            <multiselect
              :options="timeRangesOptions" 
              v-model="aux_node.frequency"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control w-100"
              @input="onTimeRangeSelected"/>
          </div>
          <div class="w-25 pt-1" v-if="node.customer_field && node.customer_field.type == 'date' && node.operator != '' && node.operator != 'last' && node.operator != 'before_last'">
            <b-form-input type="date" class="form-control me-2" v-model="node.term" ></b-form-input>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'input_num_with_frequency'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
					<div class="w-25 pe-0 ps-0">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  @input="onNumberOperatorSelected"
                  />
              </div>
            </div>
            <div class="" style="width: 100px;">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="number" class="form-control" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
            <div  class="w-25 ps-0 pe-0">
              <multiselect
                :options="frequencyOperatorsValues" 
                v-model="aux_frequency_operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control w-100"
                @input="onFrequencyOperatorSelected"
              />
            </div>
            <div style="width: 100px;">
              <b-form-input type="number" class="form-control" min="1" v-model="node.frequency.value" ></b-form-input>
            </div>
            <div class="ps-0" style="width: 150px;">
              <multiselect
                :options="timeRangesOptions" 
                v-model="aux_frequency_range"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control"
                @input="onFrequencyTimeRangeSelected"
                />
            </div>
            
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'custom_interaction_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
					<InteractionQueryBuilderRule :query="node.term" :interaction_parameters="selectedField.parameters || []" :times="node.times" /> 
				</div>
        <div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>