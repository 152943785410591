<script>

import {
	
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
	},
  data() {
		return {
			customersChecked: false,
      urlCustomers: '',
      timeCustomers: 60,
      productsChecked: true,
      urlProducts: '',
      timeProducts: 60,
      interactonsChecked : false,
      urlInteractions: '',
      timeInteractions: 60,
      submitted: false,
      isOkSettings:false,

      parseCustomers: null,
      parseProducts: null,
      parseInteractions: null
		};
	},
	methods: {
    extractMinutes(scheduler) {
      const regex = /\*\/(\d+) \* \* \* \*/;
      const match = scheduler.match(regex);
      if (match && match[1]) {
          return parseInt(match[1]);
      } else {
          return 60; // Retorna null si el formato no coincide
      }
    },
    setFeedConfigSettings(data){
      this.customersChecked = data.customers?.enabled || false;
      this.urlCustomers = data.customers?.url || '';
      this.timeCustomers = this.extractMinutes(data.customers?.schedule || '');
      this.parseCustomers = data.customers?.parse || null;

      this.productsChecked = data.products?.enabled || false;
      this.urlProducts = data.products?.url || '';
      this.timeProducts = this.extractMinutes(data.products?.schedule || '');
      this.parseProducts = data.products?.parse || null;

      this.interactonsChecked = data.interactions?.enabled || false;
      this.urlInteractions = data.interactions?.url || '';
      this.timeInteractions = this.extractMinutes(data.interactions?.schedule || '');
      this.parseInteractions = data.interactions?.parse || null;

    },
		validateFeedConfigSettings(){
      this.submitted = true;
      let isOk = true;

      if(this.customersChecked){
        isOk = this.urlCustomers && this.timeCustomers && this.timeCustomers > 0 && this.urlCustomers!=''
      }

      if(this.productsChecked){
        isOk = this.urlProducts && this.timeProducts && this.timeProducts > 0 && this.urlProducts!='' && isOk
      }

      if(this.interactonsChecked){
        isOk = this.urlInteractions && this.timeInteractions && this.timeInteractions > 0 && this.urlInteractions!='' && isOk
      }

      this.isOkSettings = isOk;
      return isOk;
    },

    getConfig(){
      const data = {
        customers: {
          enabled : this.customersChecked,
          schedule: this.customersChecked ? `*/${this.timeCustomers} * * * *` : '',
          url: this.customersChecked ? this.urlCustomers : '',
        },
        products:{
          enabled : this.productsChecked,
          schedule: this.productsChecked ? `*/${this.timeProducts} * * * *` : '',
          url: this.productsChecked ? this.urlProducts : '',
        },
        interactions:{
          enabled: this.interactonsChecked,
          schedule: this.interactonsChecked ? `*/${this.timeInteractions} * * * *` : '',
          url: this.interactonsChecked ? this.urlInteractions : '',
        }
      }
      
      if(this.parseCustomers){
        data.customers.parse = this.parseCustomers;
      }

      if(this.parseInteractions){
        data.interactions.parse = this.parseInteractions;
      }

      if(this.parseProducts){
        data.products.parse = this.parseProducts;
      }
      
      return data;
    }
  },
	mounted() {
	
	},
  computed : {
    isConfigValid(){
      return this.validateFeedConfigSettings();
    }
  },
	props: {
		type: String,
    integration: Object
	},
	emits: [],
  watch :{
    type:{
      immediate:true,
      handler(newVal){
        if(newVal){
          switch (newVal){
            case 'magento':
              this.customersChecked = true;
              this.urlCustomers = 'customers';
              this.productsChecked = true;
              this.urlProducts = 'products';
              this.interactonsChecked = true;
              this.urlInteractions = 'orders'
              break;
          }
        }
      }
    }
  }
};
</script>

<template>
	<div>
    <div class="row" v-if="!integration || !integration.hidden_sync || !integration.hidden_sync.products">
      <div class="col-sm-12">
        <div class="mb-1">
          <b-form-checkbox
                v-model="productsChecked"
                class="mb-1"
                checked
                plain>{{ $t('integrations.feed_products') }}</b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row border p-2 mx-1 mb-3" v-if="productsChecked">
      <div class="col-sm-12 px-0">
        <b-form-input type="url" placeholder="URL" v-model="urlProducts" v-if="!type"/> 
      </div>
      <div class="d-inline-flex align-items-baseline small mt-2">
        {{ $t('integrations.feed_sync_each') }} <b-form-input v-model="timeProducts" type="number" class="form-control form-control-sm ms-2 me-2" style="width:50px"/> {{ $t('integrations.feed_sync_each_minutos') }}
      </div>
    </div>
    <div class="row" v-if="!integration || !integration.hidden_sync || !integration.hidden_sync.customers">
      <div class="col-sm-12">
        <div class="mb-1">
          <b-form-checkbox
                v-model="customersChecked"
                class="mb-1"
                checked
                plain>{{ $t('integrations.feed_customers') }}</b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row border p-2 mx-1 mb-3" v-if="customersChecked">
      <div class="col-sm-12 px-0">
        <b-form-input type="url" placeholder="URL" v-model="urlCustomers" v-if="!type"/> 
      </div>
      <div class="d-inline-flex align-items-baseline small mt-2">
        {{ $t('integrations.feed_sync_each') }} <b-form-input type="number" class="form-control form-control-sm ms-2 me-2" style="width:50px" v-model="timeCustomers"/> {{ $t('integrations.feed_sync_each_minutos') }}
      </div>
    </div>
    <div class="row" v-if="!integration || !integration.hidden_sync || !integration.hidden_sync.interactions">
      <div class="col-sm-6">
        <div class="mb-1">
          <b-form-checkbox
                v-model="interactonsChecked"
                class="mb-1"
                checked
                plain>{{ $t('integrations.feed_interactions') }}</b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row border p-2 mx-1 mb-3" v-if="interactonsChecked">
      <div class="col-sm-12 px-0">
        <b-form-input type="url" placeholder="URL" v-model="urlInteractions" v-if="!type"/> 
      </div>
      <div class="d-inline-flex align-items-baseline small mt-2">
        {{ $t('integrations.feed_sync_each') }} <b-form-input v-model="timeInteractions" type="number" class="form-control form-control-sm ms-2 me-2" style="width:50px"/> {{ $t('integrations.feed_sync_each_minutos') }}
      </div>
    </div>
    <div v-if="submitted && !isConfigValid" class="invalid-feedback" style="display: block;">
      {{$t('integrations.feed_error')}}
    </div>
  </div>
</template>

<style>
.interact {
  cursor: pointer;
}
</style>