import axios from 'axios'

import router from '@/router'

axios.interceptors.response.use(
  function (response) {
		return response
  },
  function (error) {
		// handle error
		if (error || error.response) {
			return Promise.reject(error)
		}
  }
)

function catchMiddleware(err) {
  if (err && err.response && err.response.status === 441) {
		// Password expired and must change it
  }
  else if (err && err.response && err.response.status === 401) {
		//Unauthorized
		localStorage.removeItem('auth.currentUser');
		localStorage.removeItem('current_project');

		router.push('/login') 
	}

  return new Promise((_, reject) => {
		reject(err)
  })
}

export default {
  get(url, config) {
		return axios({
			method: 'get',
			url,
			...config
		}).catch(catchMiddleware)
  },
  post(url, data, config) {
		return axios({
			method: 'post',
			url,
			data,
			...config
		}).catch(catchMiddleware)
  },
  put(url, data, config) {
		return axios({
			method: 'put',
			url,
			data,
			...config
		}).catch(catchMiddleware)
  },
  delete(url, config) {
		return axios({
			method: 'delete',
			url,
			...config
		}).catch(catchMiddleware)
  }
}
