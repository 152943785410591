import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from 'vue-notification'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import appConfig from "@/app.config";

import FlowyPlugin from "@hipsjs/flowy-vue";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

import NodeBlock from '@/components/journeys/node-block.vue';



import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "../src/design/app.scss";

import store from '@/state/store'

import App from './App.vue'

import i18n from './i18n'


import tinymce from 'vue-tinymce-editor'
import './registerServiceWorker'

Vue.component('tinymce', tinymce)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(Notifications)
Vue.use(FlowyPlugin);
Vue.use(VueLoading,{
  color: '#5e40bf'
})

Vue.component('apexchart', VueApexCharts)

Vue.component('NodeBlock', NodeBlock);

if(window.config.env && (window.config.env=='pd' || window.config.env=='stg')){
  Sentry.init({
    Vue,
    dsn: "https://6794ff3f9fe649d9beabf85e6db7d075@o1314692.ingest.sentry.io/6585373",
    integrations: [
      new BrowserTracing(),
    ],
    tracesSampleRate: 0.25,
    environment: window.config.env,
    release: "go-shops@" + appConfig.version
  });
}


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
