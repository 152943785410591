import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createScenario = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/scenarios`,
    payload,
    config
  )
}

const _getScenarios = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/scenarios?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/scenarios`


  return Request.get(
    url,
    config
  )
}

const _deleteScenario = scenarioId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/scenarios/${scenarioId}`, config)
}

const _getScenario = scenarioId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/scenarios/${scenarioId}`, config)
}

const _updateScenario = (scenario) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/scenarios/${scenario._id}`, scenario, config)
}

export default {
  updateScenario: _updateScenario,
  deleteScenario : _deleteScenario, 
  getScenario : _getScenario, 
  getScenarios : _getScenarios,
  createScenario : _createScenario,
}
