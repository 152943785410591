import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}


const _getTotalItemsCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/items-count?${params}`,
    config
  )
}

const _getTotalInteractionsCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/interactions-count?${params}`,
    config
  )
}

const _getTotalInteractionsByTypesCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/interactions-types-count?${params}`,
    config
  )
}

const _getTotalCustomersCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/customers-count?${params}`,
    config
  )
}

const _getTotalInteractionsByDay = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/interactions-by-day?${params}`,
    config
  )
}

const _getRFMReport = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/rfm?${params}`,
    config
  )
}

const _getRFMReportText = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/rfm-text?${params}`,
    config
  )
}

const _getTotalSessionsCount = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/session-count?${params}`,
    config
  )
}

const _getRevenue = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/revenue?${params}`,
    config
  )
}

const _getRevenueRate = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/revenue-rate?${params}`,
    config
  )
}

const _getRevenueAvg = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/revenue-avg?${params}`,
    config
  )
}

const _getRecoDashboard = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/reco-dashboard?${params}`,
    config
  )
}

const _getRecoPageDashboard = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/reco?${params}`,
    config
  )
}

const _getActiveExperiences = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/active-experiences?${params}`,
    config
  )
}

const _getActiveABTests = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/active-abtests?${params}`,
    config
  )
}

const _getExecutingJourneys = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/executing-journeys?${params}`,
    config
  )
}

const _getPersonalizationDashboard = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-indicators?${params}`,
    config
  )
}

const _getSessionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/sessions?${params}`,
    config
  )
}

const _getImpressionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/impressions?${params}`,
    config
  )
}

const _getConversionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/convertions?${params}`,
    config
  )
}

const _getRevenueTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/revenue?${params}`,
    config
  )
}

const _getRevenueTotalTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/total-revenue-personalized?${params}`,
    config
  )
}

const _getRevenueRateTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/session-purchase-rate?${params}`,
    config
  )
}

const _getAOVTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/purchase-aov-personalized?${params}`,
    config
  )
}

const _getTotalSessionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/billing/month-sessions?${params}`,
    config
  )
}

const _getRecoImpressionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-impressions?${params}`,
    config
  )
}

const _getRecoSessionsTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-sessions?${params}`,
    config
  )
}

const _getRecoCRTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-cr?${params}`,
    config
  )
}

const _getRecoCTRTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-ctr?${params}`,
    config
  )
}

const _getRecoDirectRevenueTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-direct-revenue?${params}`,
    config
  )
}

const _getRecoAssistedRevenueTimeline= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/goals-details/reco-indirect-revenue?${params}`,
    config
  )
}

const _getDownloadRecoCRReport= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/reco-cr-download?${params}`,
    config
  )
}

const _getNPSFeedbackReport= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/feedback/nps-report?${params}`,
    config
  )
}

const _geFeedbackTypeReport= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/feedback/icons-report?${params}`,
    config
  )
}

const _getNPSCommentsFeedbackReport= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/feedback/nps-comments?${params}`,
    config
  )
}

const _getPersonalizationIndicators = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/personalization/indicators?${params}`,
    config
  )
}

const _getRecommendationsIndicators = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/recommendations/indicators?${params}`,
    config
  )
}

const _getRecommendationsCustomers = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/recommendations/customer-count?${params}`,
    config
  )
}

const _getPersonalizationsCustomers = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/personalization/customer-count?${params}`,
    config
  )
}


const _getJourneysIndicators = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/journeys/indicators?${params}`,
    config
  )
}

const _getJourneysReports = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/journeys/reports?${params}`,
    config
  )
}

const _getBroadcastsReports = params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/broadcast/indicators?${params}`,
    config
  )
}

const _getTotalEmails= params => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${window.config.VUE_APP_BACKEND}/api/analytics/billing/emails?${params}`,
    config
  )
}

export default {
  getTotalItemsCount: _getTotalItemsCount,
  getTotalInteractionsCount: _getTotalInteractionsCount,
  getTotalInteractionsByTypesCount: _getTotalInteractionsByTypesCount,
  getTotalCustomersCount: _getTotalCustomersCount,
  getTotalSessionsCount: _getTotalSessionsCount,
  getTotalInteractionsByDay: _getTotalInteractionsByDay,
  getRFMReport:_getRFMReport,
  getRFMReportText:_getRFMReportText,
  getRevenue : _getRevenue,
  getRevenueRate: _getRevenueRate,
  getRevenueAvg: _getRevenueAvg,
  getRecoDashboard: _getRecoDashboard,
  getRecoPageDashboard:_getRecoPageDashboard,
  getActiveABTests: _getActiveABTests,
  getActiveExperiences: _getActiveExperiences,
  getExecutingJourneys: _getExecutingJourneys,
  getPersonalizationDashboard: _getPersonalizationDashboard,
  getSessionsTimeline: _getSessionsTimeline,
  getImpressionsTimeline: _getImpressionsTimeline,
  getRevenueTimeline : _getRevenueTimeline,
  getConversionsTimeline : _getConversionsTimeline,
  getRevenueTotalTimeline: _getRevenueTotalTimeline,
  getRevenueRateTimeline: _getRevenueRateTimeline,
  getAOVTimeline: _getAOVTimeline,
  getTotalSessionsTimeline: _getTotalSessionsTimeline,
  getRecoImpressionsTimeline: _getRecoImpressionsTimeline,
  getRecoSessionsTimeline: _getRecoSessionsTimeline,
  getRecoCRTimeline: _getRecoCRTimeline,
  getRecoCTRTimeline: _getRecoCTRTimeline,
  getRecoAssistedRevenueTimeline: _getRecoAssistedRevenueTimeline,
  getRecoDirectRevenueTimeline: _getRecoDirectRevenueTimeline,
  getDownloadRecoCRReport: _getDownloadRecoCRReport,
  getNPSFeedbackReport: _getNPSFeedbackReport,
  getFeedbackTypeReport: _geFeedbackTypeReport,
  getNPSCommentsFeedbackReport: _getNPSCommentsFeedbackReport,
  getPersonalizationIndicators: _getPersonalizationIndicators,
  getRecommendationsIndicators: _getRecommendationsIndicators,
  getRecommendationsCustomers: _getRecommendationsCustomers,
  getPersonalizationsCustomers: _getPersonalizationsCustomers,
  getJourneysIndicators: _getJourneysIndicators,
  getJourneysReports: _getJourneysReports,
  getBroadcastsReports: _getBroadcastsReports,
  getTotalEmails: _getTotalEmails
}