import StatsService from '../../services/stats'
import SearchStatsService from '../../services/searchStats'
import Journeys from '../../services/journeys'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {},

    // eslint-disable-next-line no-unused-vars
    getLastSyncDate({ commit }) {
    return new Promise((resolve, reject) => { 
        SearchStatsService.getLastSyncDate().then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

    // eslint-disable-next-line no-unused-vars
    getTotalItemsCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalItemsCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalInteractionsCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalInteractionsCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalInteractionsByTypeCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalInteractionsByTypesCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalCustomersCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalCustomersCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalSessionsCount({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalSessionsCount(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    

    // eslint-disable-next-line no-unused-vars
    getTotalInteractionsByDay({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalInteractionsByDay(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

     // eslint-disable-next-line no-unused-vars
     getRFMReport({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRFMReport(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRFMReportText({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRFMReportText(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenue({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenue(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenueRate({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenueRate(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenueAvg({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenueAvg(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getRecoDashboard({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoDashboard(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getRecoPageDashboard({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoPageDashboard(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getActiveABTests({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getActiveABTests(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getActiveExperiences({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getActiveExperiences(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getExecutingJourneys({ commit }, params) {
        return new Promise((resolve, reject) => { 
            Journeys.getJourneys(params).then(response=> {
            resolve(response.data?.totalCount || 0)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getPersonalizationDashboard({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getPersonalizationDashboard(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getSessionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getSessionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getImpressionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getImpressionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getConversionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getConversionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenueTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenueTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenueTotalTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenueTotalTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRevenueRateTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRevenueRateTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getAOVTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getAOVTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getTotalSessionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalSessionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRecoImpressionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoImpressionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRecoSessionsTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoSessionsTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
     // eslint-disable-next-line no-unused-vars
     getRecoCTRTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoCTRTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

     // eslint-disable-next-line no-unused-vars
     getRecoCRTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoCRTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRecoAssistedRevenueTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoAssistedRevenueTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRecoDirectRevenueTimeline({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecoDirectRevenueTimeline(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getDownloadRecoCRReport({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getDownloadRecoCRReport(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getNPSFeedbackReport({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getNPSFeedbackReport(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getFeedbackTypeReport({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getFeedbackTypeReport(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getNPSCommentsFeedbackReport({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getNPSCommentsFeedbackReport(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getPersonalizationIndicators({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getPersonalizationIndicators(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getRecommendationsIndicators({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecommendationsIndicators(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getRecommendationsCustomers({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getRecommendationsCustomers(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getPersonalizationsCustomers({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getPersonalizationsCustomers(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getJourneysIndicators({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getJourneysIndicators(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    // eslint-disable-next-line no-unused-vars
    getJourneysReports({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getJourneysReports(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    // eslint-disable-next-line no-unused-vars
    getBroadcastsReports({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getBroadcastsReports(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    getTotalEmails({ commit }, params) {
        return new Promise((resolve, reject) => { 
            StatsService.getTotalEmails(params).then(response=> {
            resolve(response.data)
        })
        .catch(error=>{ reject(error) })
        });
    },

    
}