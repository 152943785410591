
import CampaignService from '../../services/campaigns'
import CommonService from '../../services/common'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createCampaign ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      CampaignService.createCampaign(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating campaign'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCampaigns ({ commit },params) {

    return new Promise((resolve, reject) => { 
      CampaignService.getCampaigns(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCampaign ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      CampaignService.getCampaign(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteCampaign ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      CampaignService.deleteCampaign(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateCampaign ({ commit }, campaign) {

    return new Promise((resolve, reject) => { 
      CampaignService.updateCampaign(campaign).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  uploadCampaignPicture({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
  })

  },

  // eslint-disable-next-line no-unused-vars
  uploadPicture({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateProductCampaign ({ commit }, params) {

    return new Promise((resolve, reject) => { 
      CampaignService.updateProductCampaign(params._id, params.payload).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getCampaignDefaultSettings({commit}){
    let defaultSettings = {
      videocall:{
        useRate: true,
        useConfirmExit: true,
        useCart:true,
        useStockControl: false,
        useRedirectToDetail:false,
      }
    }

    return defaultSettings;
  }
}