
import VideoService from '../../services/videos'
import CommonService from '../../services/common'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createVideo ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      VideoService.createVideo(payload).then(response => { 
        if (response.data && response.data.data._id) { 
          resolve(response)
        }else{
          reject({message: 'Error creating video'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getVideos ({ commit },params) {

    return new Promise((resolve, reject) => { 
      VideoService.getVideos(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getVideo ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      VideoService.getVideo(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteVideo ({ commit }, id) {

    return new Promise((resolve, reject) => { 
      VideoService.deleteVideo(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateVideo ({ commit }, video) {

    return new Promise((resolve, reject) => { 
      VideoService.updateVideo(video).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  uploadFile({commit},payload) {
    return new Promise((resolve, reject) => {
      CommonService.addBucket(payload).then(response => {
          
          if (response.data) {
              resolve(response.data)
          } else {
              reject({message: ''})
          }
      }).catch(error => { reject(error) })
    })
  },
}