<script>

import Multiselect from "vue-multiselect";
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh.js';

import {
	
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect,
  },
	data() {
		return {
			submitted:false,
      methods: [
        "GET",
        "POST",
        "PUT",
        "DELETE"
      ],
      config: {
        value: {
          method : "GET",
          headers: [{
            key : "Content-Type",
            value: "applications/json"
          }
        ],
          url: "",
          body:"{}",
          response: "response"
        }
      },
      jsonCodeMirror: null,
      jsonCode:''
		};
	},
  
	methods: {
		getActionsAPIConfig(){
      this.config.value.body = this.jsonCodeMirror.getValue();

      return this.config;
    },

    setActionsAPIConfig(config){
      this.config = config || this.config;
      this.jsonCodeMirror.setValue(this.config.value.body);
    },
    onAddHeaderClicked(){
      this.config.value.headers.push({
        key: null,
        value: null
      })
    },
    initializeCodeMirror() {
        
      this.jsonCodeMirror = CodeMirror.fromTextArea(this.$refs.jsonTextArea, {
        mode: 'text/javascript',
        theme: 'material',
        lineNumbers: true,
        lineWrapping: true,
        value: this.jsonCode,
        viewportMargin: Infinity,
        autorefresh: true
      });
      this.jsonCodeMirror.refresh();
    },
    onMethodSelected(value){
      if(value=='POST' || value=="PUT"){
        //this.config.value.body = '{}';
      }else{
        //this.config.value.body = '';
      }
    },
    onRemoveHeaderClicked(index){
      this.config.value.headers.splice(index,1);
    }
},

	mounted() {
		this.initializeCodeMirror();
	},
	props: {
		
	},
	emits: []
};
</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>{{$t('journeys.journey_workflow_components_apis_name')}}</label>
      <input class="form-control" v-model="config.value.name"/>
      </div>
    </div>
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            
          </span>
          <span class="d-none d-sm-inline-block"> Request</span>
        </template>
        <div class="row mb-2">
            <div class="col-3">
              <multiselect
                :options="methods"
                v-model="config.value.method"
                selectLabel=""
                deselectLabel=""
                :allow-empty="false"
                @input="onMethodSelected"
              />
            </div>
            <div class="col-9">
              <input class="form-control" type="url" placeholder="https://company.com" v-model="config.value.url"/>
          </div>
          <div class="row mb-2 mt-3 pe-0">
            <div class="col-9">
              HEADERS
            </div>
            <div class="col-3 text-end pe-0 d-inline-flex interact" v-on:click="onAddHeaderClicked">
              <i class="bx bx-plus font-size-18"></i>Header
            </div>
            <div class="row ps-2 pt-2 mb-3 align-items-baseline" v-for="(h, index) in config.value.headers" :key="index">
              <div class="col-1">
                Key
              </div>
              <div class="col-4 ps-0 pe-2">
                <input class=" form-control form-control-sm" v-model="h.key">
              </div>
              <div class="col-1 px-0">
                Value
              </div>
              <div class="col-6 pe-0 ps-1 me-0 d-inline-flex align-items-baseline">
                <input class="form-control form-control-sm" v-model="h.value">
                <i class="bx bx-trash interact" v-on:click="onRemoveHeaderClicked(index)" ></i>
              </div>
            </div>
          </div>
          <div class="code" :style="{visibility: config.value.method == 'POST' || config.value.method == 'PUT' ? 'visible' : 'hidden' }">
            BODY
            <textarea ref="jsonTextArea"></textarea>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
          </span>
          <span class="d-none d-sm-inline-block"> Response</span>
        </template>
        <label class="mb-0 mt-2">{{$t('journeys.journey_workflow_components_apis_save_in')}}</label>
        <input class="form-control form-control-sm" v-model="config.value.response"/>
      </b-tab>
    </b-tabs>
    
  </div>
</template>

<style scoped>

.CodeMirror{
  height: 200px !important;
}
.interact{
	cursor: pointer;
}
</style>