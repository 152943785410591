import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createAudience = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/audiences`,
    payload,
    config
  )
}

const _getAudiences = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/audiences?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/audiences`


  return Request.get(
    url,
    config
  )
}

const _deleteAudience = audienceId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/audiences/${audienceId}`, config)
}

const _getAudience = audienceId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/audiences/${audienceId}`, config)
}
const _updateAudience = (audience) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/audiences/${audience._id}`, audience, config)
}

const _createContactList = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/contact-lists`,
    payload,
    config
  )
}

const _getContactLists = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/contact-lists?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/contact-lists`


  return Request.get(
    url,
    config
  )
}

const _getContactListTemplate = () => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= `${window.config.VUE_APP_BACKEND}/api/contact-lists/download-template`


  return Request.get(
    url,
    config
  )
}

const _deleteContactList = listId => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  
  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/contact-lists/${listId}`, config)
}

const _updateContactList = (list) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/contact-lists/${list._id}`, list, config)
}

const _getContactList = id => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/contact-lists/${id}`, config)
}

const _getAudienceExplore= (body) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${window.config.VUE_APP_BACKEND}/api/audiences/explore`,body, config)
}

const _getAudienceExploreStatus= (id) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/audiences/explore-status/${id}`, config)
}

export default {
	updateAudience: _updateAudience,
  deleteAudience : _deleteAudience, 
  getAudience : _getAudience, 
  getAudiences : _getAudiences,
  createAudience : _createAudience,

  getContactLists: _getContactLists,
  getContactList: _getContactList,
  createContactList: _createContactList,
  getContactListTemplate: _getContactListTemplate,
  deleteContactList: _deleteContactList,
  updateContactList: _updateContactList,
  getAudienceExploreStatus: _getAudienceExploreStatus,
  getAudienceExplore: _getAudienceExplore
}
