<script>

import Multiselect from "vue-multiselect";

import {
	
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Multiselect
	},
	data() {
		return {
			submitted:false,
      dates: [
        {id: 'mins', label : this.$t('journeys.journey_workflow_components_wait_time_minutes')},
        {id: 'hours', label : this.$t('journeys.journey_workflow_components_wait_time_hours')},
        {id: 'days', label : this.$t('journeys.journey_workflow_components_wait_time_days')},
        {id: 'weeks', label : this.$t('journeys.journey_workflow_components_wait_time_weeks')}
      ],
      config: null,
      auxDate: {id: 'mins', label : this.$t('journeys.journey_workflow_components_wait_time_minutes')},
      auxValue: 5
		};
	},
	methods: {
		getTimeDelayConfig(){
      this.config.value.value = this.auxValue;
      return this.config;
    },

    setTimeDelayConfig(config){
      this.config = config;
    },

    onTimeSelected(time){
      this.config.value.time = time.id;
    }
	},

	mounted() {
		
	},
	props: {
		
	},
	emits: [],
  watch:{
    config: {
      inmmediate: true,
      handler(newVal){
        if(newVal){
          if(!this.config.value) this.config.value = {}
          
          if(this.config.value && this.config.value.time){
            this.auxDate = this.dates.filter(d=> d.id == this.config.value.time);
            this.auxValue = this.config.value.value || this.auxValue;
          }else{
            this.config.value.time = this.auxDate.id;
            this.auxValue = this.config.value.value || this.auxValue;
          }
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="col d-inline-flex align-items-baseline">
        <label>{{$t('journeys.journey_workflow_components_wait_time_wait')}}</label>
        <input class="form-control w-25 ms-2" type="number" v-model="auxValue"/>
        <multiselect
          :options="dates"
          track-by="id"
          label="label"
          v-model="auxDate"
          selectLabel=""
          deselectLabel=""
          :allow-empty="false"
          @input="onTimeSelected"
          class="w-50 ms-2"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>