
import GoalService from '../../services/goals'

export const state = {}

export const mutations = {}


export const getters = {}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {},

  // eslint-disable-next-line no-unused-vars
  createGoal ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      GoalService.createGoal(payload).then(response => { 
        if (response.data && response.data.error == false) { 
          resolve(response.data)
        }else{
          reject({message: 'Error creating goal'})
        }
      })
      .catch(error => { reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  getGoals ({ commit },params) {

    return new Promise((resolve, reject) => { 
      GoalService.getGoals(params).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
  
  // eslint-disable-next-line no-unused-vars
  deleteGoal({ commit }, id) {

    return new Promise((resolve, reject) => { 
      GoalService.deleteGoal(id).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateGoal ({ commit }, goal) {

    return new Promise((resolve, reject) => { 
      GoalService.updateGoal(goal).then(response=> {
        resolve(response.data)
    })
    .catch(error=>{ reject(error) })
    });
  },
}