import Request from './baseRequest'

const getUserToken = () => {
	return JSON.parse(localStorage.getItem('auth.currentUser')).token;
}

const _createInvitation = payload => {

  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${window.config.VUE_APP_BACKEND}/api/invitations`,
    payload,
    config
  )
}

const _getInvitations = params => { 
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url= params? `${window.config.VUE_APP_BACKEND}/api/invitations?${params.q}` : `${window.config.VUE_APP_BACKEND}/api/invitations`


  return Request.get(
    url,
    config
  )
}

const _deleteInvitation = invitationId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(`${window.config.VUE_APP_BACKEND}/api/invitations/${invitationId}`, config)
}

const _getInvitation = invitationId => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${window.config.VUE_APP_BACKEND}/api/invitations/${invitationId}`, config)
}

const _updateInvitation = (invitation) => {
  const token = getUserToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(`${window.config.VUE_APP_BACKEND}/api/invitations/${invitation._id}`, invitation, config)
}

const _uploadInvitations = payload => {

	let token = getUserToken();
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
			authorization: `Bearer ${token}`
		}
	}
	
	let formData = new FormData();
	formData.append('file', payload.file);
    formData.append('campaign', payload.campaign);

	return Request.post(
		`${window.config.VUE_APP_BACKEND}/api/invitations/upload`,
		formData,
		config
	);
}

export default {
  updateInvitation: _updateInvitation,
  deleteInvitation : _deleteInvitation, 
  getInvitation : _getInvitation, 
  getInvitations : _getInvitations,
  createInvitation : _createInvitation,
  uploadInvitations: _uploadInvitations
}
