<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import WaitConditionInteractionProductsRule from "@/components/journeys/configs/wait-condition-interaction-product-rule"
import InteractionQueryBuilderRule from "../../widgets/queryBuilderInteractionsRule.vue"
import {
  customerMethods

} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,
      
        aux_node: {
          operator: '',
          condition: null
        },
        options:[
        {
          label: this.$t('journeys.journey_workflow_components_wait_interaction_view'),
          value: "interaction_view",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t('journeys.journey_workflow_components_wait_interaction_cart'),
          value: "interaction_cart",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t('journeys.journey_workflow_components_wait_interaction_rate'),
          value: "interaction_rate",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t('journeys.journey_workflow_components_wait_interaction_like'),
          value: "interaction_like",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        },
        {
          label: this.$t('journeys.journey_workflow_components_wait_interaction_purchase'),
          value: "interaction_purchase",
          operator: '',
          term: {
            component: "products_component",
            value: 'products_condition',
          }
        }
      ],
      productsConditions: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_interaction_any'), "id" : "any"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_interaction_with_properties'), "id" : "filter"},
      ]
    };
    
  },
  components: { Multiselect, WaitConditionInteractionProductsRule, InteractionQueryBuilderRule},
  mounted() {},
  methods: {
    ...customerMethods,

    getInteractionFieldByValue(value){
      
      let selectedField = null
      
      this.options.forEach((a)=>{
          
        if(a.value == value){
          selectedField = a;
        }
      })
      
      return selectedField
    },
    
      onInteractionFiedSelected(type, isUpdate=false){

        if(isUpdate){
          type = this.getInteractionFieldByValue(type); 
          this.aux_node.field = type; 
        }

        if(!type)
          return;
        
        if(type.value == 'country'){
          this.loadCountries();
        }

        this.operators= type.operators || [];
        this.selectedField = type;
        this.node.field = type.value;

        if(!isUpdate){
          //this.node.operator = type.operator;
          
          this.node.term= {
            type:"and",
            children: [
              {
                field: null,
                operator: null,
                term: null,
              }
            ]
          }
        }
          
    },
    isNodeValid(){
      return this.node.field && this.node.operator && this.node.term;
    },
    onRemoveCondition(){
      this.$emit('removeCondition',this.index);
    },
    
    onOperatorSelected(operator, isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.id;
      }else{
        this.aux_node.operator = this.selectedField.operatorsFields.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields.filter(o=> o.id== operator)[0] : null ;
      }
    },
    loadCountries(){
      const loader = this.$loading.show();
      
      this.getCustomersCountries().then((countries)=>{
        this.countries = countries;
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      }).finally(()=>{
        loader.hide();
      })
    },
    onConditionTypeSelected(type,isUpdate=false){
      if(!isUpdate){
        this.node.operator = type.id;
      }else{
        this.aux_node.condition = this.productsConditions.filter(c=> c.id==type).length > 0 ? this.productsConditions.filter(c=> c.id==type)[0] : null
      }
    }
  },
  props: {
    node: Object,
    index: Number,
    properties_products: Array,
    properties_crm: Array,
    affinity_Types: Array,
    categories: Array,
    rfm_segments: Array,
    custom_interactions: Array
  },
	emits:["removeCondition"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onInteractionFiedSelected(newVal.field,true);

          if(newVal.operator){
            this.onConditionTypeSelected(newVal.operator, true);
          }
        }
      }
    },
    custom_interactions:{
      immediate:true,
      handler(newVal){
        if(newVal&& newVal.length> 0){
          newVal.forEach((i)=>{
            this.options.push(
            {
            label: i.name,
            value: i.key,
            operator: '',
            term: {
              component: "custom_interaction_component",
              parameters: i.parameters || [],
            }
          })
          })
          
          if(this.node && this.node.field)
          this.onInteractionFiedSelected(this.node.field,true);
        }
      }
    }
  }
}

 </script>

<template>
    
	<div class="row">
				<div class="col-sm-3 me-0">
						<multiselect
						:options="options"
						track-by="value"
						label="label"
						v-model="aux_node.field"
						selectLabel=""
						deselectLabel=""
            :placeholder="$t('audiences.audience_conditions_field_placeholder')"
						@select="onInteractionFiedSelected"
						/>
				</div>
        <div class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
          <div class="col-sm-2 mt-2 mb-2">
            <multiselect
                :options="productsConditions"
                v-model="aux_node.condition"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                @select="onConditionTypeSelected"
            />
        </div>
					<WaitConditionInteractionProductsRule :properties_products="properties_products" :categories="categories" :query="node.term" v-if="aux_node?.condition?.id=='filter' && aux_node.field?.term?.component!='custom_interaction_component'"></WaitConditionInteractionProductsRule>
          <InteractionQueryBuilderRule :query="node.term" :interaction_parameters="aux_node.field?.term?.parameters || []" :times="{}" v-if="aux_node?.condition?.id=='filter' && aux_node.field?.term?.component=='custom_interaction_component'" :hideTimes="true" /> 
          
				</div>
				<div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>