
<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone">
    <span>{{$t('common.drag_drop_file')}}</span>
    <span>{{$t('common.or')}}</span>
    <label for="dropzoneFile">{{$t('common.select_file')}}</label>
    <input ref="dropzoneFile" type="file" id="dropzoneFile" class="dropzoneFile btn btn-primary" :accept="accept" @input="onFileSelected" />
  </div>
</template>

<script>
export default {
  name: "dropzone",
  props: {
      accept : String,
      active: Boolean,
      field: Object
    },
    methods: {
      toggleActive(evt){
        if(evt && evt.dataTransfer.files && evt.dataTransfer.files.length > 0){
          const file = evt.dataTransfer.files[0];
          const obj = {file, field: this.field}

          if(this.accept){
            if (file.type.match(this.accept)) {
              this.$emit('onFile',obj)
            }
          }else{
            this.$emit('onFile',obj)
          }
        }
      },
      onFileSelected(){
        var file = this.$refs.dropzoneFile.files[0];
        const obj = {file, field: this.field}

        if(this.accept){
          const acceptedTypes = this.accept.split(',').map(type => type.trim());
          if (acceptedTypes.includes(file.type)) {
            this.$emit('onFile',obj)
          }
        }else{
          this.$emit('onFile',obj)
        }
      }
    },
    emits: ['onFile']
  };
</script>

<style scoped lang="scss">
.dropzone {
  // width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #5e40bf;
  background-color: #fff;
  transition: 0.3s ease all;
  label {
    padding: 8px 12px;
    color: #fff;
    background-color: #5e40bf;
    transition: 0.3s ease all;
  }
  input {
    display: none;
  }
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #5e40bf;
  label {
    background-color: #fff;
    color: #5e40bf;
  }
}
</style>