import UserService from '../../services/users'

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return new Promise((resolve, reject) => {
            const payload ={
                email,
                password
            }
            UserService.login(payload).then((response) => {
                const user = response.data.data
    
                commit('SET_CURRENT_USER', user)
                resolve(user);
            }).catch(error => { reject(error)  })
        });

    
        
    },

    // Logs out the current user.
    // eslint-disable-next-line no-unused-vars
    logOut({commit}) {
        return new Promise((resolve) => {
            UserService.logout().then(() => {
                commit('SET_CURRENT_USER', null)
                localStorage.removeItem('auth.currentUser');
                localStorage.removeItem('current_project');
                resolve();
            }).catch(error => { resolve(error)})
        });
    },

    // eslint-disable-next-line no-unused-vars
    request_reset_password({commit},payload) {
        return new Promise((resolve,reject) => {
            UserService.requestPasswordReset(payload).then(() => {
                resolve();
            }).catch(error => { reject(error)})
        });
    },

    // eslint-disable-next-line no-unused-vars
    password_reset({commit},payload) {
        return new Promise((resolve,reject) => {
            UserService.passwordReset(payload).then((res) => {
                resolve(res);
            }).catch(error => { reject(error)})
        });
    },


    // register the user
    register({ dispatch, getters } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        /*return getFirebaseBackend().registerUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });*/
    },

    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        /*return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });*/
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        /*const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;*/
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
