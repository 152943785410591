<script>
import { Picker } from 'emoji-mart-vue-2'
import DynamicContent from "./dynamic-vars.vue"
import Multiselect from "vue-multiselect";
import Integrations  from "@/components/projects/integrations";
import Drawer from "vue-simple-drawer";
import AITextGenerator  from "@/components/journeys/ai-text-generator";
import _debounce from 'lodash.debounce';
import TestChannel from '@/components/broadcasts/test-channel.vue';

import {
	integrationMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Picker,
    DynamicContent,
    Multiselect,
    Integrations,
    AITextGenerator,
    Drawer,
    TestChannel
  },
	data() {
		return {
			submitted:false,
      showVariableModal: false,
      sms:{
        text:'',
        from: null
      },
      showTextEmojis:false,
      categories:[],
      affinity_Types: [],
      items:[],
      value:'',
      showVarContent:false,
      textContent:'',
      smsConfigs: [],
      from : null,
      showIntegrationsAvailable:false,
      integrations:[],
      openRightDrawer:false,
      openRightTestDrawer: false,
      field:'',
      limit:160,
      preview:'ios'
		};
	},
  computed: {},
  created() {
    this.debouncePreview = _debounce(this.loadPreview.bind(this), 1000);
  },
  mounted() {
    window.addEventListener('click', this.onclickOutsideEmojisContainer)
    this.loadSMSIntegrations(true);

    let biompScript = document.createElement('script')

    biompScript.onload = function() {
        // eslint-disable-next-line no-undef
        bioMp(document.getElementById('preview'), {
        url: 'devices/android.html',
        view: 'front',
        image: 'devices/android.svg',
        width: 230
      });
    }
    
    biompScript.setAttribute('src', 'devices/biomp.js')
    document.head.appendChild(biompScript); 

    this.debouncePreview();
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onclickOutsideEmojisContainer)
  },
	methods: {
    ...integrationMethods,

    loadSMSIntegrations(fromNew = false){
      const params = {
        q: `where[project]=${localStorage.getItem('current_project')}&where[type]=sms`
      }
      this.getIntegrations(params).then((integrations)=>{
        this.smsConfigs = integrations.data;
        this.smsConfigs.forEach((i)=>{
          i.label = `${i.name} | ${i.data.fromNumber}`
        })
        if(fromNew){
          this.from = this.smsConfigs.length > 0 ? this.smsConfigs[0] : null;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("integrations.get_integrations_error"),title:  this.$t("integrations.title") });
      }).finally(()=>{

      })
    },

    onclickOutsideEmojisContainer(evt){
      const textEmojiDiv = document.getElementById("textEmojiDiv");
      let targetEl = evt.target; 
        do {
          if(targetEl == textEmojiDiv) {
            return;
          }
          // Go up the DOM
          targetEl = targetEl.parentNode;
        } while (targetEl);

        if(this.showTextEmojis)
          this.showTextEmojis = false;
    },
    handleTextEmojiClick(detail){
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(detail.native);
      div.appendChild(textNode);

      this.textContent = div.textContent;

      this.showTextEmojis = false;

      this.debouncePreview();
    },
    getSMSConfig(){
      this.sms.text = this.textContent;
      this.sms.from = this.from._id;
      return this.sms; 
    },
    
    setSMSConfig(config){
      this.sms = config || this.sms;
      this.textContent = this.sms.text;
      this.from = this.smsConfigs.filter(c=>c._id == this.sms.from).length > 0 ? this.smsConfigs.filter(c=>c._id == this.sms.from)[0] : this.smsConfigs.length > 0 ? this.smsConfigs[0] : null 
      this.$refs.editableDiv.innerHTML = this.textContent;
    },

    onVariableClicked(textToInsert){
      const editableDiv = this.$refs.editableDiv;
      
      // Get the current cursor position
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      const currentPosition = range.startOffset;
    if (!editableDiv.contains(range.startContainer)){
        return ;
      }
        
      // Insert the {{var}} text at the cursor position
      document.execCommand('insertText', false, textToInsert);

      // Restore the cursor position to the end of the inserted text
      range.setStart(editableDiv.firstChild, currentPosition + textToInsert.length);
      range.setEnd(editableDiv.firstChild, currentPosition + textToInsert.length);
      selection.removeAllRanges();
      selection.addRange(range);
          
    },
    handleTextInput(event) {
      this.textContent = event.target.textContent;

      this.debouncePreview();
    },
    onTextVariableSelected(variable){
      const div = this.$refs.editableDiv;
      const textNode = document.createTextNode(`{{${variable.column_name}}}`);
      div.appendChild(textNode);

      this.textContent = div.textContent;

      this.debouncePreview();
    },
    onIntegrationsClicked(){
      this.$router.push({
          path: "/integrations",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    },
    onAddIntegrationClicked(){
      this.showIntegrationsAvailable = true
    },
    onConfirmNewIntegration(){
        this.showIntegrationsAvailable = false;
        this.loadSMSIntegrations(true);
    },
    validateSMSConfig(){
      this.submitted = true;
      let isOk = false;

      if(this.from && this.textContent && this.textContent !='')
        isOk = true;

      return isOk;
    },
    rightDrawer() {
      this.openRightDrawer = !this.openRightDrawer;
    },
    rightTestDrawer() {
      this.openRightTestDrawer = !this.openRightTestDrawer;
    },
    onSuggestedTextSelected(text){
      this.textContent = text;
      const div = this.$refs.editableDiv;
      div.textContent = text;
      
      this.openRightDrawer = false;

      this.debouncePreview();
    },
    loadPreview(){
      let iFrame = null;
      if(this.preview == 'android'){
        var previewDiv = document.getElementById("preview");
        iFrame = previewDiv.getElementsByTagName("iframe")[0];
      }else{
        var iosPrewview = document.getElementById("iosPrewview");
        iFrame = iosPrewview.getElementsByTagName("iframe")[0];
      }

      
      const src = this.preview == 'android' ? 'devices/android.html' :  'devices/ios.html' ;
      iFrame.src = `${src}?&title=${this.preview == 'ios' ? '' : this.from?.data?.fromName || this.from?.data?.fromNumber || '' }&message=${this.textContent}&type=sms&app_name=${ this.preview == 'ios' ? this.from?.data?.fromName || this.from?.data?.fromNumber || '' : 'Messages'}`
    },
    showTestChannel(){
      this.testMessage = this.getSMSConfig();
      this.openRightTestDrawer = true;
    }
  },

	props: {
		variant: Object
	},
	emits: [],
  
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="mb-3">
            <label for="phone">{{$t('journeys.journey_workflow_components_channel_variants_sms_config_from')}}</label>
            <Multiselect
              :options="smsConfigs"
              v-model="from"
              selectLabel="''"
              deselectLabel=""
              :placeholder="$t('journeys.journey_workflow_components_channel_variants_sms_config_from_placeholder')"
              :multiple="false"
              :allowEmpty="false"
              track-by="_id" 
              label="label"
              :class="{'is-invalid': submitted}"
              open-direction="bottom"
              v-if="smsConfigs.length > 0">
            </Multiselect>
            <div class="card bg-info bg-gradient text-white mb-0">
              <div class="card-body p-2">
                <p class="card-text" v-if="smsConfigs.length > 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_sms_config_from_integration')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onIntegrationsClicked">{{$t('journeys.journey_workflow_components_channel_variants_sms_config_from_integration2')}}</i> </p>
                <p class="card-text" v-if="smsConfigs.length == 0"> <i class="mdi mdi-alert-circle-outline"></i> {{$t('journeys.journey_workflow_components_channel_variants_sms_config_from_empty')}} <i class="text-decoration-underline interact fw-bold" v-on:click="onAddIntegrationClicked">{{$t('journeys.journey_workflow_components_channel_variants_sms_config_from_empty2')}}</i> {{$t('journeys.journey_workflow_components_channel_variants_sms_config_from_empty3')}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label for="phone">{{$t('journeys.journey_workflow_components_channel_variants_sms_config_text')}}</label>
            <div class="d-flex-inline input-group" style="height: 80px;">
              <div class="form-control d-flex-inline" contentEditable="true" ref="editableDiv" id="editableDiv"  @input="handleTextInput">
              </div>
              <button style="height: 40px;" class="btn btn btn-outline-primary btn-md" @mousedown.prevent @mouseup.prevent @click.stop="showTextEmojis = true"> <i class="mdi mdi-emoticon-happy-outline"></i></button>
              <button style="height: 40px;" class="btn btn btn-outline-primary btn-md" @click.stop="openRightDrawer = true; field = 'Message'"> <i class="mdi mdi-wizard-hat"></i></button>
            </div>
            <div class="invalid-feedback" v-if="submitted && (!textContent || textContent == '')" style="display: block !important">
              {{$t('journeys.journey_workflow_components_channel_variants_sms_config_text_required')}}
            </div>
            <div class="emoji-menu" v-if="showTextEmojis" id="textEmojiDiv">
              <picker @select="handleTextEmojiClick" :showPreview="false" :sheetSize="32"/>
            </div>
            <div class="d-flex-inline input-group">
              <DynamicContent @variableSelected="onTextVariableSelected"></DynamicContent>
              <label class="text-length">{{ `${textContent.length}/160` }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex flex-column">
        <label class="card-title">Preview</label>
        <span class="small">{{$t('journeys.journey_workflow_components_channel_variants_app_push_config_preview')}}</span> 
        <div class="btn-group btn-group-sm pl-2 w-70 align-self-center mt-2" role="group" style="width: 50%">
            <button  type="button" :class="preview == 'ios' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="preview='ios'; debouncePreview()"> <i class="bx bxl-apple" /> ios</button>
            <button  type="button" :class="preview == 'android' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="preview='android'; debouncePreview()"><i class="bx bxl-android"/> android</button>
        </div>
        <div id="preview" style="margin: 0 auto; position: relative;" :style="{display: preview=='android' ? 'block' : 'none' }"></div>
        <div id="iosPrewview" :style="{display: preview=='ios' ? 'block' : 'none' }" style="height: 490px;  margin: auto; margin-top: 10px !important;">
          <!--<iframe src="devices/ios.html" style="width: 545px; height: 1181px; transform: scale(0.4); transform-origin: top;" /> -->
          <iframe style="width: 375px; height: 812px; transform: scale(0.6); transform-origin: top;" /> 
        </div>
      </div>  
      <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" :title="$t('integrations.new')" hide-footer>
        <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" :types="['sms']" />
      </b-modal>
      <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="mdi mdi-wizard-hat font-size-16 align-middle me-1"></i>
                  {{$t('broadcasts.generate_text')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <AITextGenerator :channel="'SMS'" @onTextSelected="onSuggestedTextSelected" :field="field" :limit="limit" ></AITextGenerator>
              </div>
          </div>
      </Drawer>
      <Drawer
        @close="rightTestDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
          <div v-if="openRightTestDrawer">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  {{$t('broadcasts.test_message_sms')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body">
                <TestChannel :channel="'sms'" :message="testMessage" @onTestSent="openRightTestDrawer = false"></TestChannel>
              </div>
          </div>
      </Drawer>
    </div>
  </div>
</template>

<style scoped>

.interact{
	cursor: pointer;
}

.emoji-menu{
  position: absolute;
  right: calc(var(--bs-gutter-x) * 0.5);
  z-index: 999;
  overflow: hidden;
}

.email-container{
  width: 300px;
  height: 150px;
  border-style: solid;
  border-radius: 8px;
  border-color: lightgray;
  border-width: 0.5px;
}

.text-length{
  position: absolute;
  right: 12px;
  font-size: 10px;
  margin-top: 2px;
}


</style>